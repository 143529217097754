import React, { useState, useEffect } from "react";
import { DOMEApiHelper, isEmpty, onFormatDate } from "../../../../../utils/DOMEApiHelper";
import { Stack, TextField, IStackStyles, IStackProps, DatePicker, Breadcrumb, Dropdown, PrimaryButton, DefaultButton, IDropdownOption, Label, Callout, Link, DetailsListLayoutMode, CommandBar, ICommandBarItemProps, Checkbox, IconButton, TooltipHost } from "@fluentui/react";
import ToastMessage from "../../../../../shared/ToastMessage/ToastMessage"; 
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize, RichTextEditor } from "@coherence-design-system/controls";
import { useParams } from "react-router-dom";
import type { EditorPlugin } from 'roosterjs';
import { CustomRTEPlugin } from "../../../../../shared/CustomRTEPlugin/CustomRTEPlugin";
import { set } from "date-fns";
import { PrimaryContactPicker } from "../../../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { log } from "console";
import dayjs from "dayjs";
import { SelectObjectSkewEdit20Filled } from "@fluentui/react-icons";

interface prop {
    onSaveIssues: any;
    issueResolutionList: any;
  }

  export const Issues = (props: prop) => {
    const stackTokens = { childrenGap: 50 };
    const stackStyles: Partial<IStackStyles> = { root: { whiteSpace: 'nowrap' } };
    const columnProps: Partial<IStackProps> = {
      tokens: { childrenGap: 15 },
      styles: { root: { width: '100%' } },
    };
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
    const [showIssuePanel, setShowIssuePanel] = useState<boolean>(false);
    const [onSaveDataValid, setOnSaveDataValid] = useState<boolean>(false);
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const [issueResolutionList, setIssueResolutionList] = useState<any>([]);
    const [pORId, setPORId] = useState<any>();
    const [issueRecords, setIssueRecords] = useState([]);
    const [newIssue, setNewIssue] =  useState<any>();
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(true);
    const [isEditIssueClicked, setIsEditIssueClicked] = useState<boolean>(false);
    const [selectIssueData, setselectIssueData] = useState<any>();
    const [IssueDescription, setIssueDescription] = useState<string>("");
    const [ContactAssignedTo, setContactAssignedTo] = useState<any>([]);
    const [ContactAssignedToRequired, setContactAssignedToRequired] = useState<boolean>(false);
    const [assignToErr, setAssignToErr] = React.useState<boolean>(false);
    const [issueDueDate,setIssueDueDate] = useState<any>(dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS"));
  


    
    //when in edit mode get the por id
    const { Id } = useParams();
    useEffect(() => {
    if (Id) {
        LoadIssuesData(Id);
        setPORId(Id);
    }
    
    }, [Id]);

    useEffect(() => {
        //load IssueResolution list from props
        if (props.issueResolutionList.length > 0) {
          setIssueResolutionList(props.issueResolutionList);
        } 
       }, [props.issueResolutionList]);

    //plugin code start 
    const getPlugins = () => {
    let plugins: EditorPlugin[] = [];
    plugins.push(
      new CustomRTEPlugin((content) => {
      })
    );
    return plugins;
  };
  //plugin code end 

  //ContactAssignedTo
  const onSelectContact = (contact: any) => {
    if (!isEmpty(contact) && !isEmpty(contact.objectId)) {
     // console.log("contact",contact);
      let editedContact = [{
        DisplayName: contact.text,
        ObjectId: contact.objectId,
        EmailId: contact.emailId

      }];
      setContactAssignedTo(editedContact);
            
      if (!isEditIssueClicked) {
        newIssue.AssignedToObjectId = contact.objectId;
        newIssue.AssignedToDisplayName = contact.text;
        setNewIssue({ ...newIssue });
      } else {
        editColumnsDefaultValues.AssignedToObjectId = contact.objectId;
        editColumnsDefaultValues.AssignedToDisplayName = contact.text;
        seteditColumnsDefaultValues({ ...editColumnsDefaultValues });
      }
      setIsDisabledSaveButton(false); // Enable save button on contact change
    } else {
      if(!isEditIssueClicked){
        newIssue.AssignedToObjectId = null;
        newIssue.AssignedToDisplayName = null;
        setNewIssue({ ...newIssue });
      }
      else if (isEditIssueClicked) {
      editColumnsDefaultValues.AssignedToObjectId = null;
      editColumnsDefaultValues.AssignedToDisplayName = null;
      seteditColumnsDefaultValues({ ...editColumnsDefaultValues });
      }
      setContactAssignedTo([]);
      setIsDisabledSaveButton(true); // Disable save button on contact change
    }
  };

    //load data
    const LoadIssuesData = (PORID: string) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetIssuesDetailsByPORId?PORId=' + PORID, requestOptions)
      .then(res => {
        //console.log("issue res",res)
        setIsLoaded(false);
        setIssueRecords(res);
        
      }).catch(res => {
        setIsLoaded(false);
      });

    };

      const handleOnPanelXClose = () => {
        setShowIssuePanel(false);
        setOnSaveDataValid(false);
        setContactAssignedToRequired(false);
        // setShowOnSave(false);
      }

      const disableSaveButton = (): boolean => {
        if(isEditIssueClicked && (isEmpty(editColumnsDefaultValues.IssueTitle) || isEmpty(editColumnsDefaultValues.IssueDescription) || 
        isEmpty(editColumnsDefaultValues.AssignedToObjectId) || isEmpty(editColumnsDefaultValues.IssueDueDate) )){
          return true;
        }
        else if (isEditIssueClicked) {
          const isTitleChanged = editColumnsDefaultValues.IssueTitle !== selectIssueData.IssueTitle;
          const isDescriptionChanged = editColumnsDefaultValues.IssueDescription !== selectIssueData.IssueDescription;
          const isResolutionChanged = editColumnsDefaultValues.IssueResolution !== selectIssueData.IssueResolution;
          const isAssignedToChanged = editColumnsDefaultValues.AssignedToObjectId !== selectIssueData.AssignedToObjectId;
          const isDueDateChanged = dayjs(editColumnsDefaultValues.IssueDueDate).format("YYYY-MM-DD") !== dayjs(selectIssueData.IssueDueDate).format("YYYY-MM-DD");
      
          return !(isTitleChanged || isDescriptionChanged || isResolutionChanged || isAssignedToChanged || isDueDateChanged);
        } 
        else if (!isEditIssueClicked){
          return isEmpty(newIssue.Title) || isEmpty(newIssue.Description) || isEmpty(newIssue.AssignedToObjectId);
        }
        else{
          return true;
        }
      };

    const handleIssueSave = () => {
      if(!isEditIssueClicked || (isEditIssueClicked && ContactAssignedTo.length>0 && editColumnsDefaultValues.AssignedToObjectId !== selectIssueData.AssignedToObjectId)){
        //console.log("editedContact",ContactAssignedTo);
        const requestPiiHandlerOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(ContactAssignedTo),
          mode: 'cors'
        };
        DOMEApiHelper('PORDetails/UpsertPIIData', requestPiiHandlerOptions)
        .then(res => {
         // console.log('res fro pii', res);
        
        }).catch(res => {
          setToastMessage(res.toString());
          setToastType(CoherenceNotificationType.ERROR);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        }); 
      } 
        let data: any = [];
        if(!isEditIssueClicked){
          data = {
            IssueTitle: newIssue.Title,
            IssueDescription: newIssue.Description,
            PORId: pORId,
            CreatedBy: sessionStorage.getItem("localAccountId"),
            AssignedToObjectId: newIssue.AssignedToObjectId,
            AssignedToDisplayName: newIssue.AssignedToDisplayName,
            IssueDueDate: isEmpty(newIssue.IssueDueDate) ? new Date() : newIssue.IssueDueDate,
          }
        }
        if(isEditIssueClicked){
          data = {
            IssueId: editColumnsDefaultValues.IssueId,
            IssueTitle: editColumnsDefaultValues.IssueTitle,
            IssueDescription: editColumnsDefaultValues.IssueDescription,
            IssueResolution: editColumnsDefaultValues.IssueResolution,
            PORId: pORId,
            ModifiedBy: sessionStorage.getItem("localAccountId"),
            AssignedToObjectId: editColumnsDefaultValues.AssignedToObjectId,
            AssignedToDisplayName: editColumnsDefaultValues.AssignedToDisplayName,
            IssueDueDate: editColumnsDefaultValues.IssueDueDate,
          }
        }
        //console.log("data",data);
        setToastTitle(!isEditIssueClicked?"Add Issue":"Update issue");
        setToastMessage(!isEditIssueClicked?"Adding Issue is in progress":"Updating Issue is in progress");
        setToastType(CoherenceNotificationType.PENDING);
        setShowToast(true); 
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
          mode: 'cors'
        };
        DOMEApiHelper('PORDetails/SaveIssue', requestOptions)
                .then(res => {
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage(!isEditIssueClicked?"Issue has been added successfully.":"Issue has been updated successfully."); 
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);

                    if (res.Message==="Success") {
                      LoadIssuesData(pORId) 
                    }
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);
                }
            );
        setShowIssuePanel(false);
        setNewIssue([]);
        seteditColumnsDefaultValues([]);
    };


    const generateIssuesButton = (): any => {
        const _items: ICommandBarItemProps[] = [
          {
            key: 'Add',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
             setIsEditIssueClicked(false);
              setShowIssuePanel(true);
              setContactAssignedTo([]);
              setContactAssignedToRequired(true);
              setNewIssue({Title: '', Description: ''});

            }
          },
        
          
        ];
        return _items;
      }

      const getGridColumns = (): CoherenceDataGridColumn<any>[] => {

        var advancedColumns: CoherenceDataGridColumn<any>[] = [
         
          {
            key: 'Actions',
            name: 'Edit',
            type: 'string',
            isResizable: false, 
            minColumnWidth: 30,
            maxWidth: 30,
            data: [],     
            onRender: (item: any) => { 
              function onClickGridAction(item: any) {
                //console.log("item",item);
                let value = item.AssignedToObjectId !== "" ? [{ "text": item.AssignedToDisplayName, "objectId": item.AssignedToObjectId }] : []
                setContactAssignedTo(value);
                setIssueDueDate(item.IssueDueDate);
                setIsEditIssueClicked(true);
                seteditColumnsDefaultValues(item);
                setIssueDescription(item.IssueDescription);
                setContactAssignedToRequired(true);
                setselectIssueData(item);
                setShowIssuePanel(true);
                setIsDisabledSaveButton(true);
                              
              }
              return <IconButton aria-label={`Edit ${item.IssuesType}`} onClick={() => {onClickGridAction(item)}}  style={{cursor:'pointer', paddingBottom: '8px'}} 
              iconProps={{ iconName: 'Edit'}}
          />  
            }    
          },
          {
            key: 'IssueTitle',
            name: 'Issue Title',
            fieldName: 'IssueTitle',
            type: 'string',
            isResizable: true,
            ariaLabel: 'Issue Title',
            minColumnWidth: 140,
            maxWidth: 140,
            data: [],
            onRender: (item) => {
              return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.IssueTitle}</span>;
            },
          },
          {
            key: 'IssueResolution',
            name: 'Resolution',
            fieldName: 'Resolution',
            type: 'string',
            isResizable: true,
            ariaLabel: 'Resolution',
            minColumnWidth: 90,
            maxWidth: 90,
            data: [],
            onRender: (item) => {
              return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.IssueResolution}</span>;
            },
          },
          {
            key: 'IssueDueDate',
            name: 'Issue Due Date',
            fieldName: 'IssueDueDate',
            type: 'date',
            isResizable: true,
            minColumnWidth: 90,
            maxWidth: 90,
            ariaLabel: 'Issue Due Date',
            data: [],
            onRender: (item: any) => {
              return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                {dayjs(item.IssueDueDate).format('MM/DD/YYYY')}</span>
            }
          },
          {
            key: 'AssignedToDisplayName',
            name: 'Assigned To',
            fieldName: 'AssignedToDisplayName',
            type: 'persona',
            isResizable: true,
            minColumnWidth: 190,
            maxWidth: 190,
            ariaLabel: 'Assigned To',
            imageUrl: 'imageUrl',
            personaAlt: (item) => `Profile image for ${item.AssignedToDisplayName}`,
            data: []
          } 
        ]; 
        return advancedColumns;
      };

      //onchange POR Title  
      const onChangeIssueTitle = (event: any) => {
        const newTitle = event.target.value;
        if (!isEditIssueClicked) {
          setNewIssue({ ...newIssue, Title: newTitle });
        } else {
          seteditColumnsDefaultValues({ ...editColumnsDefaultValues, IssueTitle: newTitle });
        }
        setIsDisabledSaveButton(false);
      };

      //RichTextField
      const handleRichTextChange = (value: string | undefined): void => {
        if (isEditIssueClicked) {
          seteditColumnsDefaultValues({ ...editColumnsDefaultValues, IssueDescription: value });
        } else {
          setNewIssue({ ...newIssue, Description: value });
        }
        setIsDisabledSaveButton(false);
      };

      //change resolution
      const onChange = (metadata: string, event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item) {
          editColumnsDefaultValues.IssueResolution = item.text;
          seteditColumnsDefaultValues({ ...editColumnsDefaultValues });
          setIsDisabledSaveButton(false);
        }
      };
      //Change due date
      const handleDateSelection = (date: any) => {
        if (isEditIssueClicked) {
          editColumnsDefaultValues.IssueDueDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
          seteditColumnsDefaultValues({ ...editColumnsDefaultValues });
          setIsDisabledSaveButton(false);
        } else {
          newIssue.IssueDueDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
          setNewIssue({ ...newIssue });
        }
      };
      

      const generateIssuePanel = (): any => {
        let tmpRenderObj: any[] = [];
        if(!isEditIssueClicked){
          tmpRenderObj.push(
            <>
              <TextField
                key="title"
                label="Title"
                placeholder={"Please enter the Title"}
                required
                value={newIssue? newIssue.Title : null}
                onChange={onChangeIssueTitle}
                maxLength={1000}
                autoFocus
              />
              </>);
          
          if (ContactAssignedTo != undefined) {
            tmpRenderObj.push(
              <PrimaryContactPicker label={"Assigned To"} defaultPrimaryContact={ContactAssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={ContactAssignedToRequired}></PrimaryContactPicker>
            );
          }
          tmpRenderObj.push(
            <span> <Label>Issue Due Date<span style={{paddingLeft:4, color:'#b74c41'}}>*</span>  
             <DatePicker
                      ariaLabel="Issue Due Date Picker"
                      value={newIssue.IssueDueDate? new Date(newIssue.IssueDueDate): new Date()}                    
                      minDate={new Date()}      
                      placeholder={"Please select the Date"}
                      onSelectDate={newDate => {  
                        handleDateSelection(newDate); }}
                      formatDate={onFormatDate} 
                    textField={{ errorMessage: "" }}
              ></DatePicker></Label>
            </span>
        );
        tmpRenderObj.push(
          <>
            <div key="description" style={{ height: '300px' ,  gridColumn: 'span 2' }}>
            <Label>Description<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>
              <RichTextEditor
                styles={{root:{marginTop:-10}}}
                characterMax={4000}
                label=""
                value={newIssue? newIssue.Description : null}
                onChange={handleRichTextChange}
                onBlur={function noRefCheck() { }}          
                onFocus={function noRefCheck() { }}
                onInvalid={function noRefCheck() { }}
                onValid={function noRefCheck() { }}
                placeholder="Please enter the Description "
                resizable={true}
                plugins={getPlugins()}
              />
              </div>
        </>
        );
          
        }
        if(isEditIssueClicked){
          tmpRenderObj.push(
            <>
              <TextField
                key="title"
                label="Title"
                placeholder={"Please enter the Title"}
                required
                value={editColumnsDefaultValues.IssueTitle}
                onChange={onChangeIssueTitle}
                maxLength={1000}
                autoFocus
              />
              </>);
          
          tmpRenderObj.push(
            <>
          <div> 
            <Dropdown
              label={'Resolution'}
              placeholder="Select Issue resolution"
              required={true}
              options={issueResolutionList}
              errorMessage={''}
              selectedKey={!isEmpty(editColumnsDefaultValues.IssueResolution)? issueResolutionList.filter((x: any) => x.text === editColumnsDefaultValues.IssueResolution)[0].key : null}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption<any> | undefined,
                index?: number | undefined
              ) => onChange( "Resolution", event, option)
                }
            />  
          </div>
          </>
          ); 

          if (ContactAssignedTo != undefined) {
            tmpRenderObj.push(
              <PrimaryContactPicker label={"Assigned To"} defaultPrimaryContact={ContactAssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={ContactAssignedToRequired}></PrimaryContactPicker>
            );
          }
          tmpRenderObj.push(
            <span> <Label>Issue Due Date<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>  
             <DatePicker
                      ariaLabel="Issue Due Date Picker"
                      value={editColumnsDefaultValues.IssueDueDate? new Date(editColumnsDefaultValues.IssueDueDate): new Date()}                    
                      minDate={new Date()}      
                      placeholder={"Please select the Date"}
                      onSelectDate={newDate => {  
                        handleDateSelection(newDate); }}
                      formatDate={onFormatDate} 
                    textField={{ errorMessage: "" }}
              ></DatePicker>
            </span>
        );

          tmpRenderObj.push(
            <>
              <div key="description" style={{ height: '200px', gridColumn: 'span 2' }}>
              <Label>Description<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>
                <RichTextEditor
                  styles={{root:{marginTop:-10}}}
                  characterMax={4000}
                  label=""
                  value={IssueDescription}
                  onChange={handleRichTextChange}
                  onBlur={function noRefCheck() { }}          
                  onFocus={function noRefCheck() { }}
                  onInvalid={function noRefCheck() { }}
                  onValid={function noRefCheck() { }}
                  placeholder="Please enter the Description "
                  resizable={true}
                  plugins={getPlugins()}
                />
              </div>
              <br/>
          </>
      
          );
        }


        return tmpRenderObj;    
      };

     
    return <div>
    {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <CommandBar
        items={[]}
        farItems={generateIssuesButton()}
        ariaLabel="Add Issue"
        primaryGroupAriaLabel="Add Issue"
        farItemsGroupAriaLabel="Add Issues"
        style={{ display: 'flex', justifyContent: 'start' }}
      />
    </div>
    <div style={{ minHeight: 255, height: 255, maxHeight: 255, overflowX: 'auto' }}>
      {(!isLoaded) ? <CoherenceDataGrid
        listConfig={getGridColumns()}
        data={issueRecords}
        ariaLabelForSelectAllCheckbox={'select all checkbox'}
        isScrollable={true}
        isSortable={false}
        sortByDefault={false}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        ariaLabel={"Issue List"}
      ></CoherenceDataGrid>
        : <CoherenceLoading primaryText={""}></CoherenceLoading>}
    </div>


    <CoherencePanel
      title={isEditIssueClicked? "Edit Issue" : "Add Issue"}
      titleText={isEditIssueClicked? "Edit Issue" : "Add Issue"}
      isOpen={showIssuePanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.medium}
      closeButtonAriaLabel="Close"
      onRenderFooter={() => (
          <PrimaryButton disabled={ disableSaveButton() || isDisabledSaveButton}
          onClick={handleIssueSave} > {!isEditIssueClicked?'Add Issue':'Save Issue'} </PrimaryButton>
      )}
    >
       
      <div  style={{ display: "grid", gridTemplateColumns: "50% 50%",gridGap: "1rem" }} >
        {showIssuePanel && generateIssuePanel()}
      </div>
      
    </CoherencePanel>
  </div>




  };
export default Issues;