import React, { useEffect, useState } from "react";
import { Navigate, Route,Routes } from "react-router-dom";  

import { AuthenticatedTemplate } from "@azure/msal-react";
import { RouteGuard } from "./RouteGuard";
import { appRoles } from "./authConfig";
import { SessionTimeout } from "./utils/SessionTimeout";
import AppNavigation from "./shared/AppNavigation/AppNavigation";
import { AppHeader } from "./shared/AppHeader/AppHeader";
import NonManaged from "./components/NonManaged/NonManaged";
import { getAppStyles } from "./App.styles";
import { useIsMobile } from "./utils/ismobile";
import PORDetails from "./components/Managed/Overview/PORDetails/PORDetails";
import PORItems from "./components/Managed/PORItems/PORItems";
import ScopeDefinition from "./components/Managed/Overview/ScopeDefinition/ScopeDefinition";
import OLSOffer from "./components/OLSOffer/OLSOffer"; 
import { registerIconsOnce } from "./utils/RegisterIcons";
import FinanceDataAudit from "./components/FinanceDataAudit/FinanceDataAudit";
import { GetUserRole } from "./utils/GetUserRole";
import { isEmpty } from "./utils/DOMEApiHelper";
import IntakeManagement from "./components/IntakeManagement/IntakeManagement";
import UnderDevelopment from "./shared/UnderDevelopment/UnderDevelopment";
import AppFooter from "./shared/AppFooter/AppFooter";
import FinanceDataAuditHistory from "./components/FinanceDataAudit/FinanceDataAuditHistory";

// Call registerIconsOnce to ensure icons are registered only once
registerIconsOnce();

export const App: React.FunctionComponent  = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isNavCollapsed, setIsNavCollapsed] = React.useState<boolean>(false); 
  const handleOnNavCollapsed = (isCollapsed: boolean): void => {
    setIsNavCollapsed(isCollapsed);
  };
  const isMobile = useIsMobile();
  const appStyles = getAppStyles(isNavCollapsed);  
  
  useEffect(() => {
    const loadClarityScript = () => {
      if(isEmpty(process.env.REACT_APP_Clarity_ID)){
        return;
      }
      const c = window as any;
      const l = document;
      const a = "clarity";
      const r = "script";
      const i = process.env.REACT_APP_Clarity_ID;
    
      if (!c[a]) {
        c[a] = function () { (c[a].q = c[a].q || []).push(arguments); };
      }
    
      const t: HTMLScriptElement = l.createElement(r);
      t.async = true;
      t.src = `https://www.clarity.ms/tag/${i}`;
    
      const y: HTMLElement | null = l.getElementsByTagName(r)[0];
      if (y) {
        y.parentNode?.insertBefore(t, y);
      } else {
        l.head.appendChild(t); // Fallback if <script> tag not found
      }
    };

    loadClarityScript();
  }, []); // This effect runs once after the initial render


  // if isMobile is true then collapse the left navigation
  useEffect(() => {   
    setIsNavCollapsed(isMobile);  
  }, [isMobile]);
   
  //below method is used to redirect bydefault screen based on role
  const userHasRole = () => {
    const roles = isEmpty(userRole) ? GetUserRole() : userRole;
    if (roles.includes(appRoles.Managed) || roles.includes(appRoles.Admin)) {
      return "/PORItems";
    }
    else if (roles.includes(appRoles.NonManaged)) {
      return "/NonManaged";
    } else if (roles.includes(appRoles.OLSOfferIDGeneration)) {
      return "/OLSOffer";
    } else if (roles.includes(appRoles.AuditData)) {
      return "/FinanceDataAudit";
    } else if (roles.includes(appRoles.Managed)) {
      return "/PORItems";
    }
  
    return "/PORItems"; // Default route if no match is found
  };
  

  return (
    <div className={appStyles.root}>
    
    <AuthenticatedTemplate> 
      <div className={appStyles.stickyHeader}>
        <AppHeader />
      </div> 
      <AppNavigation onNavCollapsed={handleOnNavCollapsed} />
      <main id="main" tabIndex={-1} className={appStyles.mainContainer}>
        <Routes>  
          <Route path="/" element={<Navigate   to={userHasRole()} />} />

           <Route path="/NonManaged"
                  element={<RouteGuard component={<NonManaged />} roles={[appRoles.NonManaged, appRoles.Accessibility,appRoles.Admin]}/>}/>
          <Route path="/PORItems"
                  element={<RouteGuard component={<PORItems />} roles={[appRoles.Managed, appRoles.Accessibility,appRoles.Admin]}/>}/>        
          <Route path="/CreatePOR" key="Create Por"
                  element={<RouteGuard component={<PORDetails key="add" />} roles={[appRoles.Managed, appRoles.Accessibility,appRoles.Admin]}/>}/>
          <Route path="/PORDetails/:Id" key="Por Details"
                  element={<RouteGuard component={<PORDetails key="edit"/>} roles={[appRoles.Managed, appRoles.Accessibility,appRoles.Admin]}/>}/>      
          <Route path="/ScopeDefinition/:Id"
                  element={<RouteGuard component={<ScopeDefinition />} roles={[appRoles.Managed, appRoles.Accessibility,appRoles.Admin]}/>}/>
          <Route path="/SessionTimeout"
                  element={<SessionTimeout />}/>
          <Route path="/OLSOffer" key="OLS offerID Creation"
                  element={<RouteGuard component={<OLSOffer key="add" />} roles={[appRoles.OLSOfferIDGeneration,appRoles.Accessibility,appRoles.Admin]}/>}/>  
           <Route path="/FinanceDataAudit" key="Finance Data Audit"
                  element={<RouteGuard component={<FinanceDataAudit key="add" />} roles={[appRoles.AuditData,appRoles.Accessibility,appRoles.Admin]}/>}/>   
            <Route path="/FinanceDataAuditHistory" key="Finance Data Audit History"
                  element={<RouteGuard component={<FinanceDataAuditHistory key="add" />} roles={[appRoles.AuditData,appRoles.Accessibility,appRoles.Admin]}/>}/>         
          <Route path="/IntakeManagement" key="Intake Management"
                  element={<RouteGuard component={<IntakeManagement key="add" />} roles={[appRoles.Managed, appRoles.Accessibility,appRoles.Admin]}/>}/>       
          <Route path="/UnderDevelopment" key="UnderDevelopment"
                  element={<RouteGuard component={<UnderDevelopment key="UnderDevelopment" />} roles={[]}/>}/>  
         </Routes>
      </main>
         <div className={appStyles.footerStyle}>
         <AppFooter></AppFooter> 
         </div>
    </AuthenticatedTemplate>
    
    </div>

  );
}

export default App;
