
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { Checkbox, CommandBar, DefaultButton, DetailsListLayoutMode, Dropdown, FontIcon, ICommandBarItemProps, IDropdownOption, Label, Link, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../shared/Accordion/Accordion";
import CustomPagination from "../../shared/CutomPagination/CustomPagination";
import { NotificationPivotOptions } from "../../shared/AppHeader/AppHeader.utils";
import CustomGridControls from "../../shared/CustomGridControls/CustomGridControls";
import ToastMessage from "../../shared/ToastMessage/ToastMessage";
import { DOMEApiHelper, convertToCsv, isEmpty } from "../../utils/DOMEApiHelper";
import { GetUserRole } from "../../utils/GetUserRole";
import NonManagedDiscussions from "../NonManaged/NonManagedDiscussions/NonManagedDiscussions";

const OLSOffer: React.FunctionComponent = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaveCallInProgress, setIsSaveCallInProgress] = useState(false);
  const [isDataLakeLoaded, setIsDataLakeLoaded] = useState(false);
  const [LoadText, setLoadText] = useState<string>("");
  const [olsOfferItemsData, setOlsOfferItemsData] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
  //toaster code Start
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [olsOfferListFull, setOlsOfferListFull] = useState<any>([]);
  //toaster code end
  const [olsOfferDetails, setOlsOfferDetails] = useState<CreateOlsOfferModel>(
    { PublisherId: 0, OfferIdentifierId: 0, OfferIdentifierName: "", PublisherName: "", OfferSegmentId: 0, EntitlementTypeId: 0, PFAMCodes: "", OfferWorkingName: "", InActive: false });

  const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
  const [publisherList, setPublisherList] = useState<any>([]);
  const [olsOfferPatternList, setOlsOfferPatternList] = useState<any>([]);
  const [olsOfferIdentifierList, setOlsOfferIdentifierList] = useState<any>([]);
  const [PFAMList, setPFAMList] = useState<any>([]);
  const [OLSOfferPFAMMappingList, setOLSOfferPFAMMappingList] = useState<any>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [isPFAMRequired, setIsPFAMRequired] = useState<boolean>(false);
  const [responseOLSOfferId, setResponseOLSOfferId] = useState<any>([]);
  function uniquearray(arr: any) {
    return arr.filter((obj: any, index: any, self: any) => index === self.findIndex((o: any) => o["key"] === obj["key"]));
  }
  const onChangePFAMCodes = (event: any) => {
    setOlsOfferDetails({ ...olsOfferDetails, PFAMCodes: event.target.value.toUpperCase().trim() });
    isPFAMDataValid(event.target.value.toUpperCase().trim());
  }
  const onChangeOfferWorkingName = (event: any) => {
    setOlsOfferDetails({ ...olsOfferDetails, OfferWorkingName: event.target.value });
    onSaveDataValid();
  }
  const stackTokens = {
    childrenGap: 28
  };
  const [isChecked, setIsChecked] = React.useState(false);
  const [onChangeCheckBox, setOnChangeCheckBox] = React.useState(false);
  const onchangeCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setIsChecked(!!checked);

    if (checked) {
      setOnChangeCheckBox(true);
      olsOfferDetails.InActive = true;
    }
    else {
      setOnChangeCheckBox(false);
      olsOfferDetails.InActive = false;
    }
    setOlsOfferDetails(olsOfferDetails);
  };

  const getPFAMList = (offerWorkingId: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('OLSOffer/GetOLSOfferPFAMByOfferWorkingId?OfferWorkingId=' + offerWorkingId, requestOptions)
      .then(res => {
        setIsLoaded(false);
        setOLSOfferPFAMMappingList([
          ...res.map((item: any) => ({
            Id: item.Id,
            OfferWorkingId: item.OfferWorkingId,
            PFAMCode: item.PFAMCode,
            IsActive: item.IsActive,
            itemSelected: false  // To make check box false when it is loaded
          }))
        ]);
      }).catch(res => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching OLS offer PFAM Mapping data");
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  }

  const handleSelectionCheckbox = (event: any, item: any) => {
    if (event.target.checked) {
      item.itemSelected = true;
    } else {
      item.itemSelected = false;
    }

    OLSOfferPFAMMappingList.forEach((element: any) => {
      if (element.Id === item.Id) {
        element.itemSelected = item.itemSelected
      }
    });
    setOLSOfferPFAMMappingList([...OLSOfferPFAMMappingList]);
  };

  interface CreateOlsOfferModel {

    PublisherId?: number;
    PublisherName?: string;
    OfferIdentifierId: any;
    OfferSegmentId?: number;
    EntitlementTypeId?: number;
    PFAMCodes?: string;
    OfferWorkingName?: string;
    OfferIdentifierName: string,
    InActive?: boolean;
  }

  const onClose = () => {
    setIsEditMode(false);
    seteditColumnsDefaultValues([]);
    setOlsOfferIdentifierList([]);
    setOlsOfferDetails({ PublisherId: 0, OfferIdentifierId: 0, OfferIdentifierName: "", OfferSegmentId: 0, EntitlementTypeId: 0, PFAMCodes: "", OfferWorkingName: "" });
    setshowEditGridPanel(false);
    setPFAMCodeValueErrorMessage(false);
    setShowPFAMCodeErrorMessage("");
    setSaveButtonDisabled(false);
    setOnChangeCheckBox(false);
    setIsChecked(false);
    setOLSOfferPFAMMappingList([]);
    if (!isEmpty(responseOLSOfferId)) {
      LoadData("Loading data");
    }

    setResponseOLSOfferId([]);
    setDisussionData([]);

  }

  const onSave = () => {
    if (!isEmpty(olsOfferDetails.PFAMCodes) && !isPFAMDataValid(olsOfferDetails.PFAMCodes)) {
      return false;
    }
    if (isEditMode) {
      //API call to save the data    

      // Adding in List if User want to disable the Existing PFAM Code
      let existedPfamCodeList = OLSOfferPFAMMappingList.filter((x: any) => x.itemSelected === true).map((item: any) => {
        return {
          PFAMCode: item.PFAMCode,
          IsActive: false // To Make InActive records
        }
      });

      // Added in the List , for the Newly Added PFAM Codes
      let newPfamCodeList: any = [];
      if (!isEmpty(olsOfferDetails.PFAMCodes)) {
        //split olsOfferDetails.PFAMCodes by comma and add it  to pfamCodeList array
        newPfamCodeList = (olsOfferDetails.PFAMCodes ?? '').split(',').map((item: any) => {
          return {
            PFAMCode: item.trim(),
            IsActive: true // To Make Active records
          }
        });
      }

      //combine both existedPfamCodeList and pfamCodeListTemp to one List and call API
      let pfamCodeList = existedPfamCodeList.concat(newPfamCodeList);

      let editedData = {
        OfferWorkingId: editColumnsDefaultValues.OfferWorkingId.toString(),
        //  PFAMCodes: pfamscodes,
        IsActive: olsOfferDetails.InActive == false ? true : false,
        ModifiedBy: sessionStorage.getItem("localAccountId"),
        PFAMCodeList: pfamCodeList
      };

      //if any changes in PFAM codes or IsActive then only call the API
      if (!isEmpty(olsOfferDetails.PFAMCodes) || olsOfferDetails.InActive != editColumnsDefaultValues.InActive || pfamCodeList.length > 0) {
        updateOLSOffer(editedData, "Update");

      }
      if (!isDisabledDiscussion() && isEditMode) {
        onSaveDiscussion(true); // for EDIT Section
      }
    }
    else {
      //API call to save the data   

      // In Create Page, for the Newly Created PFAM Code 
      let pfamList = (olsOfferDetails.PFAMCodes ?? '').split(',').map((item: any) => {
        return {
          PFAMCode: item.trim(),
          IsActive: true // To Make Active records
        }
      });
      let createddata = {
        PublisherId: olsOfferDetails.PublisherId,
        OfferIdentifierId: olsOfferDetails.OfferIdentifierId,
        OfferSegmentId: olsOfferDetails.OfferSegmentId,
        EntitlementTypeId: olsOfferDetails.EntitlementTypeId,
        OfferWorkingName: olsOfferDetails.OfferWorkingName,
        CreatedBy: sessionStorage.getItem("localAccountId"),
        PFAMCodeList: pfamList
      };
      updateOLSOffer(createddata, "Add");
    }

  }
  const updateOLSOffer = (editedData: any, reqFrom: any) => {
    setIsSaveCallInProgress(true);
    const requestUpdateOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editedData),
      mode: 'cors'
    };
  
    setToastTitle(reqFrom === "Add" ? "Add OLS Offer " : "Update OLS Offer");
    setToastMessage(reqFrom === "Add" ? "Adding OLS Offer is in progress" : "Updating OLS Offer is in progress");
    setToastType(CoherenceNotificationType.PENDING);
    setShowToast(true);
    DOMEApiHelper('OLSOffer/SaveOLSOfferDetails', requestUpdateOptions)
      .then(res => {
        setIsSaveCallInProgress(false);
        setResponseOLSOfferId(res[0].OLSOfferId);
        setToastType(CoherenceNotificationType.SUCCESS);
        if (reqFrom != "Add") {
          setToastMessage("OLS Offer has been updated successfully.");
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } else {
          if (!isDisabledDiscussion() && !isEmpty(res[0].OfferWorkingId)) {
            disussionData.DiscussionId = res[0].OfferWorkingId;
            onSaveDiscussion(true, disussionData); // API Call for discussions 

          }
          setShowToast(false);
        }
        if (isEditMode) {
          setshowEditGridPanel(false);
          onClose();//to reset the data
          LoadData("Loading data");
        }
        else {
          setshowEditGridPanel(true);
          setSaveButtonDisabled(true);
        }
      }).catch(res => {
        setIsSaveCallInProgress(false);
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  }
  const [showPFAMCodeErrorMessage, setShowPFAMCodeErrorMessage] = useState<any>();
  const [pFAMCodeValueErrorMessage, setPFAMCodeValueErrorMessage] = useState(false);

  const checkForDuplicatePFAMS = (onlyPFAM: any, fullPFAMString: any) => {
    // Use a Set to check for duplicates
    const uniqueValues = new Set(fullPFAMString);
    // If the length of the array and the Set are different, there are duplicates
    if (fullPFAMString.length !== uniqueValues.size) {
      return true;
    } else {
      return false;
    }
  };

  const educationPFAMValidation = (existingOLSOffers: any) => {
    let valid = false;
    let isExistingFaculty = existingOLSOffers.OfferIdentifierName.indexOf("Faculty Blackforest") !== -1 ? true : false;
    let isExistingStudent = existingOLSOffers.OfferIdentifierName.indexOf("Student Blackforest") !== -1 ? true : false;

    let isExistingNonFaculty = existingOLSOffers.OfferIdentifierName.indexOf("Faculty Non") !== -1 ? true : false;

    let isInputFaculty = olsOfferDetails.OfferIdentifierName.indexOf("Faculty Blackforest") !== -1 ? true : false;
    let isInputStudent = olsOfferDetails.OfferIdentifierName.indexOf("Student Blackforest") !== -1 ? true : false;

    let isExistingNonStudent = existingOLSOffers.OfferIdentifierName.indexOf("Student Non") !== -1 ? true : false;
    let isInputNonFaculty = olsOfferDetails.OfferIdentifierName.indexOf("Faculty Non") !== -1 ? true : false;
    let isInputNonStudent = olsOfferDetails.OfferIdentifierName.indexOf("Student Non") !== -1 ? true : false;

    const isSamePublisher = olsOfferDetails.PublisherId === existingOLSOffers.PublisherId;
    if (
      isSamePublisher &&
      ((isExistingFaculty && isInputStudent) ||
        (isExistingStudent && isInputFaculty) ||
        (isExistingNonFaculty && isInputNonStudent) ||
        (isExistingNonStudent && isInputNonFaculty))
    ) {
      valid = true;
    }
    return valid;
  }

  const isPFAMDataValid = (PFAMSInput: any) => {
    let isValid = true;
    setPFAMCodeValueErrorMessage(false);

    if (!isEmpty(olsOfferDetails) && !isEmpty(PFAMSInput)) {
      const splitAffectedDataValue = (PFAMSInput ?? "").split(',').map((item: any) => item.trim());
      // PFAM Validation
      let regex = /^[0-9a-zA-Z]+$/;
      let inValidPfams: string;
      let inValidPfamsCode: string;
      splitAffectedDataValue.forEach((dataValue: any) => {
        // check if input matches regex
        if (isEmpty(dataValue) || !regex.test(dataValue) || (dataValue.length != 3 && dataValue.length != 5)) {
          if (isEmpty(inValidPfamsCode)) {
            inValidPfamsCode = dataValue;
          }
          else {
            inValidPfamsCode = inValidPfamsCode + "," + dataValue;
          }
          setPFAMCodeValueErrorMessage(true);
          setShowPFAMCodeErrorMessage("PFAM Code " + inValidPfamsCode + " should be either 3 or 5 characters.");
          isValid = false;
        }

        //check pfam already exists the Same OLS Offer Id 
        if (checkForDuplicatePFAMS(dataValue, splitAffectedDataValue)) {
          if (isEmpty(inValidPfamsCode)) {
            inValidPfamsCode = dataValue;
          }
          else {
            inValidPfamsCode = inValidPfamsCode + "," + dataValue;
          }
          setPFAMCodeValueErrorMessage(true);
          setShowPFAMCodeErrorMessage("Duplicate PFAM Code(s) entered.");
          isValid = false;
        }

        if (isValid) {
          let PFAMValid = PFAMList.filter((x: any) => x === dataValue);
          //check if pfam is valid or not
          if (isEmpty(PFAMValid)) {
            if (isEmpty(inValidPfams)) {
              inValidPfams = dataValue;
            }
            else {
              inValidPfams = inValidPfams + "," + dataValue;
            }
            setPFAMCodeValueErrorMessage(true);
            setShowPFAMCodeErrorMessage("PFAM Code " + inValidPfams + " entered is not available in EDL, Offer Id generation is supported once PFAM is published.");
            isValid = false;
          }
          //if pfam is valid
          else {
            let getExistingOLSOffers = olsOfferListFull.filter((x: any) => x.PFAMCodes.includes(dataValue));

            //if pfam has only one offer id generated    
            if (getExistingOLSOffers.length == 1) {
              //education pfams student and faculty validation
              if (educationPFAMValidation(getExistingOLSOffers[0])) {

              }
              else if (olsOfferDetails.OfferIdentifierName.indexOf("C4") !== -1) {//if pfam has morethan one offer id generated for Gallatin/Gallacake   

              } else {
                setPFAMCodeValueErrorMessage(true);
                setShowPFAMCodeErrorMessage(`PFAM Code ${dataValue} is already associated with an existing Offer Id(s): ${getExistingOLSOffers.map((item: any) => item?.OLSOfferId).join(', ')}.`);
                isValid = false;
              }
            }
            //education pfams student and faculty validation if pfam has one faculty or student and one galacake then we have to allow user to add other faculty or student
            else if (getExistingOLSOffers.length > 1 && (olsOfferDetails.OfferIdentifierName.indexOf("Student") != -1 || olsOfferDetails.OfferIdentifierName.indexOf("Faculty") != -1)) {
              const getExistingEductionPFAMOfferDetails = getExistingOLSOffers.find((x: any) => x.OfferIdentifierName.includes("Student") || x.OfferIdentifierName.includes("Faculty"));
              if (isEmpty(getExistingEductionPFAMOfferDetails) || !educationPFAMValidation(getExistingEductionPFAMOfferDetails)) {
                setPFAMCodeValueErrorMessage(true);
                setShowPFAMCodeErrorMessage(`PFAM Code ${dataValue} is already associated with an existing Offer Id(s): ${getExistingOLSOffers.map((item: any) => item?.OLSOfferId).join(', ')}.`);
                isValid = false;
              }
            }
            else if (getExistingOLSOffers.length > 1 && olsOfferDetails.OfferIdentifierName.indexOf("C4") !== -1) {//if pfam has morethan one offer id generated for Gallatin/Gallacake 

            }
            else if (getExistingOLSOffers.length > 1) {
              setPFAMCodeValueErrorMessage(true);
              setShowPFAMCodeErrorMessage(`PFAM Code ${dataValue} is already associated with an existing Offer Id(s): ${getExistingOLSOffers.map((item: any) => item?.OLSOfferId).join(', ')}.`);
              isValid = false;
            }
          }
        }
      });
      return isValid;
    }
    else if (olsOfferDetails.InActive) {
      return isValid;
    }
    return isValid;
  }
  const onSaveDataValid = () => {  
    let isDataInValid = false;
    if (!isEditMode && (isEmpty(olsOfferDetails.PublisherId) || isEmpty(olsOfferDetails.OfferIdentifierId) || isEmpty(olsOfferDetails.OfferSegmentId) || isEmpty(olsOfferDetails.PFAMCodes) || isEmpty(olsOfferDetails.OfferWorkingName))) {
      isDataInValid = true;

    }
    if (isEditMode && isDisabledDiscussion() && (isEmpty(olsOfferDetails.PFAMCodes) || (olsOfferDetails.PFAMCodes === editColumnsDefaultValues.PFAMCodes) || isEmpty(editColumnsDefaultValues.OfferWorkingId)) && !onChangeCheckBox) {
      isDataInValid = true;
    }
    if (!isEmpty(responseOLSOfferId)) {
      isDataInValid = true;
    }   
    //check the change forExisting PFAM Code
    if (isEditMode) {
      if (!isEmpty(OLSOfferPFAMMappingList) && OLSOfferPFAMMappingList.some((x: any) => x.itemSelected === true)) {
        isDataInValid = false;
      }

      if(isEmpty(olsOfferDetails.PFAMCodes) && !isEmpty(OLSOfferPFAMMappingList)){
        //if all items in OLSOfferPFAMMappingList are itemSelected=true then Disable Save Button isDataInValid=true 
        if(OLSOfferPFAMMappingList.every((x: any) => x.itemSelected === true)){
          setIsPFAMRequired(true);
          isDataInValid = true;

        }
        else{
          setIsPFAMRequired(false);
        }
      }
    }

    //If Disable button for whole record is checked then Enable Save button
    if(isEditMode && onChangeCheckBox){
      isDataInValid = false;
    }
    if (pFAMCodeValueErrorMessage) {
      isDataInValid = true;
    }
    return isDataInValid;
  }


  const onChangePublisher = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    let getOLSOfferIdentifierList = olsOfferPatternList.filter((x: any) => x.PublisherId === item?.key);
    let getOLSOfferIdentifierListFiltered = (getOLSOfferIdentifierList).map((item: any) => {
      return {
        key: item.OfferIdentifierId,
        text: item.OfferIdentifierName
      }
    })
    let uniquePatternList = uniquearray(getOLSOfferIdentifierListFiltered)
    setOlsOfferIdentifierList([...uniquePatternList]);

    olsOfferDetails.PublisherId = item.key;
    olsOfferDetails.PublisherName = item.text;
    olsOfferDetails.OfferIdentifierId = 0;
    olsOfferDetails.OfferSegmentId = 0;
    olsOfferDetails.EntitlementTypeId = 0;
    setOlsOfferDetails(olsOfferDetails);
    onSaveDataValid();
  };
  const onChangeOfferIdentifier = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {

    let getList = olsOfferPatternList.filter((x: any) => x.PublisherId === olsOfferDetails.PublisherId && x.OfferIdentifierId === item?.key);
    setOlsOfferDetails({ ...olsOfferDetails, OfferIdentifierId: item?.key, OfferIdentifierName: item?.text ?? "", OfferSegmentId: getList[0].OfferSegmentId, EntitlementTypeId: getList[0].EntitlementTypeId });
  }

  useEffect(() => {
    isPFAMDataValid(olsOfferDetails.PFAMCodes);
    onSaveDataValid();
  }, [olsOfferDetails]);

  const getPFAMColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'PFAMCode',
        name: 'PFAM Code',
        fieldName: 'PFAMCode',
        type: 'string',
        isResizable: true,
        minColumnWidth: userRole.includes('Admin')   ? 230 : 450,
        maxWidth: userRole.includes('Admin') ?230 : 450,
        ariaLabel: 'PFAMCode',        
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.PFAMCode}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.PFAMCode}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('PFAMCode'),
        iconClassName: "customSortIcon"
      },
    ];

    if (isEditMode && userRole.includes('Admin')) {
      advancedColumns.push({
        key: 'Disable',
        name: 'Disable',
        fieldName: 'Disable',
        type: 'string',
        isResizable: true,
        minColumnWidth: 230,
        maxWidth: 230,
        ariaLabel: 'Disable',
        data: [],
        onRender: (item: any) => {
          return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} checked={item.isSelected} />;
        }
      });
    }
    
    return advancedColumns;
  }

  const generatePatternDetails = (): any => {
    let tmpRenderObj: any[] = [];
    if (!isEditMode && isEmpty(responseOLSOfferId)) {
      tmpRenderObj.push(
        <TextField
          label="Offer Working Name"
          placeholder={"Enter Offer Working Name"}
          required={true}
          errorMessage={""}
          value={olsOfferDetails.OfferWorkingName}
          onChange={onChangeOfferWorkingName}
          maxLength={1500}
        />
      );
    }
    else {
      tmpRenderObj.push(
        <><Label>Offer Working Name
          <Label className="readonyLabel" disabled>{isEmpty(responseOLSOfferId) ? editColumnsDefaultValues.OfferWorkingName : olsOfferDetails.OfferWorkingName}</Label>
        </Label>
        </>
      );
    }

    if (!isEditMode && isEmpty(responseOLSOfferId)) {
      tmpRenderObj.push(
        <Dropdown
          label={'Publisher Name'}
          options={publisherList ?? []}
          placeholder={"Please select the PublisherName"}
          required={true}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption<any> | undefined,
            index?: number | undefined
          ) => {
            onChangePublisher(event, option, index);

          }
          }
          defaultSelectedKey={olsOfferDetails.PublisherId}
          defaultValue={olsOfferDetails.PublisherId}
        />
      );
    }
    else {
      tmpRenderObj.push(
        <>
          <Label>PublisherName
            <Label className="readonyLabel" disabled>{isEmpty(responseOLSOfferId) ? editColumnsDefaultValues.PublisherName : olsOfferDetails.PublisherName}</Label>
          </Label>
        </>
      );
    }

    if (isEditMode && isEmpty(editColumnsDefaultValues.OfferIdentifierName)) {

      tmpRenderObj.push(
        <>
          <Label>OLS Offer Identifier
            <Label className="readonyLabel" style={{ height: '30px' }} disabled>{editColumnsDefaultValues.OfferIdentifierName}</Label>
          </Label>
        </>
      );

    }

    else if (isEditMode && !isEmpty(editColumnsDefaultValues.OfferIdentifierName)) {
      tmpRenderObj.push(
        <><Label>OLS Offer Identifier
          <Label className="readonyLabel" disabled>{editColumnsDefaultValues.OfferIdentifierName}</Label>
        </Label>
        </>
      );

    }
    else if (!isEditMode && !isEmpty(olsOfferIdentifierList) && !saveButtonDisabled) {
      tmpRenderObj.push(
        <Dropdown
          label={'OLS Offer Identifier'}
          options={olsOfferIdentifierList ?? []}

          required={true}
          onChange={(ev, selected) => { onChangeOfferIdentifier(ev, selected); }}
          placeholder={"Please select the OLS Offer Identifier"}
          defaultSelectedKey={olsOfferDetails.OfferIdentifierId}
          defaultValue={olsOfferDetails.OfferIdentifierId}

        />
      );
    }
    else if (!isEditMode && saveButtonDisabled) {
      tmpRenderObj.push(
        <><Label>OLS Offer Identifier
          <Label className="readonyLabel" style={{ height: '30px' }} disabled>{olsOfferDetails.OfferIdentifierName}</Label>
        </Label>
        </>
      );
    }

    if (isEditMode) {

      tmpRenderObj.push(
        <><Label>OLS Offer Id
          <Label className="readonyLabel" disabled>{editColumnsDefaultValues.OLSOfferId}</Label>
        </Label>
        </>
      );
    }

    if (isEmpty(responseOLSOfferId)) {
      tmpRenderObj.push(
        <TextField styles={isEditMode ? { root: { marginTop: '-3px' } } : { root: { marginTop: '0px' } }}
          label={isEditMode ? "New PFAM Code(s)" : "PFAM Code(s)"}
          required ={isEditMode ? isPFAMRequired :true}
          placeholder={"Enter PFAM Code(s)"}
          errorMessage={pFAMCodeValueErrorMessage ? showPFAMCodeErrorMessage : undefined}
          value={olsOfferDetails.PFAMCodes}
          onChange={onChangePFAMCodes}
          maxLength={1000}
          autoFocus={true}
        />

      );
    }
    else {
      tmpRenderObj.push(
        <><Label>PFAM Code(s)
          <Label className="readonyLabel" disabled>{olsOfferDetails.PFAMCodes}</Label>
        </Label></>
      );
    }

    if (isEditMode && userRole.includes('Admin')) {
      tmpRenderObj.push(
        <div style={{ padding: '7px' }}>
          <br></br>
          <Stack tokens={stackTokens}>

            <Checkbox
              label={"Disable"}
              styles={{ text: { fontWeight: '500', fontFamily: 'Segoe UI' } }}
              checked={isChecked}

              onChange={onchangeCheckbox} />
          </Stack>
        </div>
      );
    }

    if (!isEditMode && !isEmpty(responseOLSOfferId)) {
      tmpRenderObj.push(
        <div style={{ paddingTop: 10 }}>
          <MessageBar messageBarType={MessageBarType.success}>
            OLS Offer ID :  {responseOLSOfferId} has been generated successfully.
          </MessageBar>
        </div>
      );
    }

    return tmpRenderObj;
  }

  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'Actions',
        name: 'Edit',
        type: 'actions',
        menuActions: [],
        isResizable: false,
        minColumnWidth: 50,
        maxWidth: 50,
        data: ["Managed"],
        visibleActions: [
          {
            onClick: (item) => {
              getPFAMList(item.OfferWorkingId); //API Call , To fetch the Existing PFAM Code List based on OfferWorkingId
              seteditColumnsDefaultValues(item);
              setIsEditMode(true);
              setOlsOfferDetails({ PublisherId: item.PublisherId, OfferIdentifierId: item.OfferIdentifierId, OfferIdentifierName: item.OfferIdentifierName, OfferSegmentId: item.OfferSegmentId, EntitlementTypeId: item.EntitlementTypeId, PFAMCodes: "", OfferWorkingName: item.OfferWorkingName, InActive: item.InActive });
              setshowEditGridPanel(true);
            },
            key: 'edit',
            text: 'Action',
            ariaLabel: 'action',
            iconProps: { iconName: 'Edit', style: { color: "#0078d4" } }
          }
        ]
      },
      {
        key: 'OfferWorkingName',
        name: 'Offer Working Name',
        fieldName: 'OfferWorkingName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 260,
        maxWidth: 260,
        ariaLabel: 'OfferWorkingName',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.OfferWorkingName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.OfferWorkingName}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('OfferWorkingName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'OLSOfferId',
        name: 'OLS Offer Id',
        fieldName: 'OLSOfferId',
        type: 'string',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'OLSOfferId',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.OLSOfferId}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.OLSOfferId}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('OLSOfferId'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PFAMCodes',
        name: 'PFAM Codes',
        fieldName: 'PFAMCodes',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'PFAMCodes',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.PFAMCodes}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.PFAMCodes}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('PFAMCodes'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PublisherName',
        name: 'Publisher Name',
        fieldName: 'PublisherName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'PublisherName',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.PublisherName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.PublisherName}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('PublisherName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'OfferIdentifierName',
        name: 'Offer Identifier Name',
        fieldName: 'OfferIdentifierName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'Title',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.OfferIdentifierName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.OfferIdentifierName}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('OfferIdentifierName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'EntitlementTypeName',
        name: 'Entitlement Type Name',
        fieldName: 'EntitlementTypeName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'EntitlementTypeName',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.EntitlementTypeName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.EntitlementTypeName}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('EntitlementTypeName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'CreatedBy',
        name: 'Created By',
        fieldName: 'CreatedBy',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 260,
        maxWidth: 260,
        ariaLabel: 'CreatedBy',
        imageUrl: 'imageUrl',
        personaAlt: (item) => `Profile image for ${item.CreatedBy}`,
        data: [],
        iconName: appendSortIcon('CreatedBy'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ModifiedBy',
        name: 'Modified By',
        fieldName: 'ModifiedBy',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 260,
        maxWidth: 260,
        ariaLabel: 'ModifiedBy',
        imageUrl: 'imageUrl',
        personaAlt: (item) => `Profile image for ${item.ModifiedBy}`,
        data: [],
        iconName: appendSortIcon('ModifiedBy'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'CreatedDate',
        name: 'Created Date',
        fieldName: 'CreatedDate',
        type: 'date',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Created Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
            {isEmpty(item.CreatedDate) ? '' : dayjs(item.CreatedDate).format('MM/DD/YYYY')}</span>
        },
        iconName: appendSortIcon('CreatedDate'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ModifiedDate',
        name: 'Modified Date',
        fieldName: 'ModifiedDate',
        type: 'date',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Modified Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
            {isEmpty(item.ModifiedDate) ? '' : dayjs(item.ModifiedDate).format('MM/DD/YYYY')}</span>

        },
        iconName: appendSortIcon('ModifiedDate'),
        iconClassName: "customSortIcon"
      }

    ];
    return advancedColumns;
  };
  const requestOptionsGETALL = {
    method: 'GET'
  };
  const FetchValidPFAMList = () => {
    setIsDataLakeLoaded(true);
    DOMEApiHelper('OLSOffer/GetAllPFAMsFromLakeDatabase', requestOptionsGETALL)
      .then(res => {

        if (res !== undefined && res.length > 0) {
          setPFAMList(res);
        }
        setIsDataLakeLoaded(false);
        setLoadText("");
      }).catch(error => {
        setIsDataLakeLoaded(false);
        //check if error is related to sql pool warning then rerun this method
        if (error.toString().indexOf("warming") !== -1) {
          FetchValidPFAMList();
        }
        else {
          setToastTitle("Error while fetching Valid PFAM List");
          setToastMessage(error.toString());
          setToastType(CoherenceNotificationType.ERROR);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        }

      });
  }
  const LoadData = (text: string) => {
    setIsLoaded(true);
    setLoadText(text);
    setOlsOfferItemsFilteredData([]);
    setActiveTab(NotificationPivotOptions.ALLITEMS);
    DOMEApiHelper('OLSOffer/GetAllOfferIdItemsDataAsync', requestOptionsGETALL)
      .then(res => {

        if (res !== undefined && res.length > 0 && res[0]["OLSOfferItemsList"].length > 0) {

          let patternList = res[0]["OLSOfferPatternList"];

          setOlsOfferListFull(JSON.parse(JSON.stringify(res[0]["OLSOfferItemsList"])));

          let OLSOfferItemsList = res[0]["OLSOfferItemsList"].filter((obj: any) => !isEmpty(obj.OfferWorkingName));
          if (OLSOfferItemsList.length > 0) {
            OLSOfferItemsList = OLSOfferItemsList.map((obj: any) => ({ ...obj, Id: obj.OfferWorkingId, InActive: false }));
          }

          setOlsOfferItemsData(OLSOfferItemsList);
          setOlsOfferItemsBeforeFilterData(OLSOfferItemsList);
          //sort bydefault 
          if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
            sortDefault(OLSOfferItemsList)
          }
          else {
            resetPagination();
          }
        }
        setOlsOfferPatternList(res[0]["OLSOfferPatternList"]);

        let getPublisherList = res[0]["OLSOfferPatternList"].map((item: any) => ({
          key: item.PublisherId,
          text: item.PublisherName,
        }));
        let uniquePatternList = uniquearray(getPublisherList);
        setPublisherList([...uniquePatternList]);
        setIsLoaded(false);
        setLoadText("");
      }).catch(error => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching OLS offer data");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
  }
  useEffect(() => {
    setIsLoaded(true);
    FetchValidPFAMList();
    LoadData("Loading data");
    // eslint-disable-next-line 
  }, [setOlsOfferItemsData]);
  //Pagination code start   
  const [olsOfferItemsPaginatedData, setOlsOfferItemsPaginatedData] = useState<any>([]);
  const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
  const updatePaginationData = (data: any) => {
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setOlsOfferItemsPaginatedData(data);
    }
  }
  const getDataCountByActionType = (inputType: any) => {
    if (inputType === NotificationPivotOptions.ALLITEMS) {
      return isFilterApplied ? olsOfferItemsFilteredData.length : olsOfferItemsBeforeFilterData.length;
    }
  }
  const resetPagination = () => {
    setRefreshPagination(refreshPagination ? false : true);
  }
  //Pagination code end 
  //Pivot related code start
  const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ALLITEMS);
  const handlePivotChange = (item: any) => {
    if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
      setOlsOfferItemsData(isFilterApplied ? olsOfferItemsFilteredData : olsOfferItemsBeforeFilterData);
      if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
        sortDefault(isFilterApplied ? olsOfferItemsFilteredData : olsOfferItemsBeforeFilterData)  //sort bydefault     
      else resetPagination();
    }
    setActiveTab(item.props.itemKey);
  };
  //Pivot related code end

  //Filter code start  
  const [olsOfferItemsBeforeFilterData, setOlsOfferItemsBeforeFilterData] = useState<any>([]);
  const [olsOfferItemsFilteredData, setOlsOfferItemsFilteredData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const onApplyFilter = (filteredArray: any) => {
    setIsFilterApplied(true);
    setOlsOfferItemsFilteredData(JSON.parse(JSON.stringify(filteredArray)));
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setOlsOfferItemsData(filteredArray);
    }
    resetPagination();
  };
  const onClearFilter = () => {
    setIsFilterApplied(false);
    setOlsOfferItemsFilteredData([]);
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setOlsOfferItemsData(olsOfferItemsBeforeFilterData);
    }
    resetPagination();
  }
  const createFilterColumns = () => {
    return [
      { "fieldName": "OLSOfferId", "label": "OLS Offer Id", "type": "searchTextBox", "options": [], "additionalData": { "minChar": 5 } },
      { "fieldName": "OfferIdentifierName", "label": "Offer Identifier Name", "type": "searchDropdown", "options": [] },
      { "fieldName": "PublisherName", "label": "Publisher Name", "type": "searchDropdown", "options": [] },
      { "fieldName": "EntitlementTypeName", "label": "Entitlement Type Name", "type": "searchDropdown", "options": [] },
      { "fieldName": "PFAMCodes", "label": "PFAM Codes", "type": "searchTextBox", "options": [], "additionalData": { "minChar": 2 } },
      { "fieldName": "CreatedBy", "label": "Created By", "type": "searchDropdown", "options": [] },
      { "fieldName": "ModifiedBy", "label": "Modified By", "type": "searchDropdown", "options": [] },
      { "fieldName": "CreatedDate", "label": "Created Date", "type": "date", "fromCreatedDate": null, "toCreatedDate": null },
      { "fieldName": "ModifiedDate", "label": "Modified Date", "type": "date", "fromModifiedDate": null, "toModifiedDate": null },

    ];
  }
  //Filter code end



  //Sort code start
  const [sortColumnDetails, setSortColumnDetails] = useState<any>();
  const createSortColumns = () => {
    return [
      { "fieldName": "OfferWorkingName", "label": "Offer Working Name" },
      { "fieldName": "OLSOfferId", "label": "OLS Offer ID" },
      { "fieldName": "OfferIdentifierName", "label": "Offer Identifier Name" },
      { "fieldName": "PublisherName", "label": "Publisher Name" },
      { "fieldName": "EntitlementTypeName", "label": "Entitlement Type Name" },
      { "fieldName": "PFAMCodes", "label": "PFAM Codes" },
      { "fieldName": "CreatedBy", "label": "Created By" },
      { "fieldName": "ModifiedBy", "label": "Modified By" },
      { "fieldName": "CreatedDate", "label": "Created Date" },
      { "fieldName": "ModifiedDate", "label": "Modified Date" }
    ]
  };
  const sortDefault = (data: any) => {
    if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
      onSorted(sortColumnDetails["columnName"], sortColumnDetails["sortType"], data);
    }
  }
  const appendSortIcon = (columnName: string): any => {
    if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] === columnName) {
      return sortColumnDetails["sortType"] === "asc" ? "Ascending" : "Descending";
    }
  }
  const onSorted = (columnName: string, sortType: string, olsofferItems?: any): void => {
    setSortColumnDetails({ "columnName": columnName, "sortType": sortType });
    let sortedData = (olsofferItems !== undefined ? olsofferItems : olsOfferItemsData).sort((a: any, b: any) => {
      if (a[columnName] > b[columnName]) {
        return sortType === 'asc' ? 1 : -1;
      }
      if (a[columnName] < b[columnName]) {
        return sortType === 'asc' ? -1 : 1;
      }
      return 0;
    });
    setOlsOfferItemsFilteredData(JSON.parse(JSON.stringify(sortedData)));
    setOlsOfferItemsData(JSON.parse(JSON.stringify(sortedData)));
    resetPagination();
  }
  //Sort code end
  const onClickCommandBarsButtonsEvents = (source: any) => {
    if (source == 'Create New') {
      setshowEditGridPanel(true);
    } else if (source === 'Export') {
      exportToCSV(olsOfferItemsData);

    }
  }

  const exportToCSV = (data: any): any => {
    let exportData: any = [];
    data.forEach((obj: any) => {
      exportData.push({
        Id: obj.Id, OfferWorkingName: obj.OfferWorkingName, OLSOfferID: obj.OLSOfferId, PFAMCodes: obj.PFAMCodes, PublisherName: obj.PublisherName,
        OfferIdentifierName: obj.OfferIdentifierName, EntitlementTypeName: obj.EntitlementTypeName, CreatedBy: obj.CreatedBy, ModifiedBy: obj.ModifiedBy, CreatedDate: obj.CreatedDate,
        ModifiedDate: obj.ModifiedDate
      });
    });
    convertToCsv(exportData, "OLS Offer ID Data");
  }

  const generateButtons = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Create New',
        text: 'Create New',
        iconProps: { iconName: 'Add' },
        disabled: userRole.includes("Managed") ? false : true
      },
      {
        key: 'Export',
        text: 'Export',
        ariaLabel: 'Export',
        disabled: false,
        iconProps: { iconName: 'Download' },
        onClick: () => {

        }
      }];
    return _items;
  }
  //discussion code start
  const [disussionData, setDisussionData] = useState<any>();
  const [discussionRecords, setDiscussionRecords] = useState<any>([]);

  const isDisabledDiscussion = () => {
    return isEmpty(disussionData) || isEmpty(disussionData.Discussion) ? true : false;
  }

  const onValidDiscussionData = (disussionData: any, discussion: any) => {

    setDisussionData(disussionData);
  }

  const onSaveDiscussion = (showToast: any, inputDiscussionData?: any) => {
    setIsSaveCallInProgress(true);
    let jsoninput = inputDiscussionData != undefined ? inputDiscussionData : disussionData;

    let discussionDataList : any =[];  
    discussionDataList.push(jsoninput);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(discussionDataList),
      mode: "cors",
    };
    if (showToast) {

      setIsLoaded(true);
      setToastTitle("OLS Offer Details");
      setToastMessage("Record is being updated.");
      setToastType(CoherenceNotificationType.PENDING);
      setShowToast(true);
    }
    DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
      .then(res => {
        setIsSaveCallInProgress(false);
        if (showToast) {
          setIsLoaded(false);
          setIsFilterApplied(false);//reset filter
          setToastType(CoherenceNotificationType.SUCCESS);
          setToastMessage("Record is updated successfully.");
          setTimeout(() => {
            setShowToast(false);
          }, 5000);

          if (isEmpty(olsOfferDetails.PFAMCodes) && olsOfferDetails.InActive === editColumnsDefaultValues.InActive) {
            setshowEditGridPanel(false);
            onClose();//to reset the data
            LoadData("Loading data");

          }
        }
      }).catch(res => { 
        setIsSaveCallInProgress(false);
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  }
  //discussion code end
  const getDiscussionId = (discussionId?: any) => {


    if (isEditMode) {
      return editColumnsDefaultValues != undefined ? editColumnsDefaultValues.Id : 0
    }
    else {
      return discussionId != undefined ? discussionId : 0;
    }
  }
  return (
    <>   
   <div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }}
> 
      <Accordion title="OLS Offers" isExpandDefault={true}>

        <CustomGridControls  defaultFilterSessionKey="OLSOfferDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()} filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={olsOfferItemsBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />

        {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
        <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
          <PivotItem
            headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
            itemKey={NotificationPivotOptions.ALLITEMS}
            ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
          >
            <div style={{ minHeight: 510, height: 510, maxHeight: 510 }}>
              {(!isLoaded && !isDataLakeLoaded) ? <CoherenceDataGrid
                listConfig={getColumns()}
                data={olsOfferItemsPaginatedData}
                isScrollable={true}
                isSortable={false}
                sortByDefault={false}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                ariaLabel={"OLS Offer Id Generation All Items List"}
              ></CoherenceDataGrid>
                : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
            </div>
          </PivotItem>
        </Pivot>
        <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={olsOfferItemsData} pageSize={10}></CustomPagination>
      </Accordion>


      <CoherencePanel
        titleText={"OLS Offer Details"}
        isOpen={showEditGridPanel}
        onDismiss={onClose}
        panelSize={CoherencePanelSize.medium}
        closeButtonAriaLabel="Close"
        onRenderFooter={() => (
          <Stack horizontal >
            <PrimaryButton styles={isEditMode ? { root: { width: '50px' } } : { root: { width: '150px' } }} text={isEditMode ? "Save" : "Generate OfferId"} disabled={onSaveDataValid() || isSaveCallInProgress} onClick={onSave} />
            <DefaultButton styles={{ root: { width: '50px' } }} text={isEmpty(responseOLSOfferId) ? 'Cancel' : 'Close'} onClick={onClose} />
          </Stack>
        )}
      >
        <>
          <div style={{ display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem" }} >
            {showEditGridPanel && generatePatternDetails()}
            <br></br>
          </div>
          {isEditMode ?
            <Accordion title="Existing PFAM Code(s)" isExpandDefault={true}>
              <div style={{ minHeight: 230, height: 230, maxHeight: 230 }}>

                {isEditMode && !isEmpty(OLSOfferPFAMMappingList) ? <CoherenceDataGrid
                  listConfig={getPFAMColumns()}
                  data={OLSOfferPFAMMappingList}
                  isScrollable={true}
                  isSortable={false}
                  sortByDefault={false}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  ariaLabel={"Existing PFAM Code(s) List"}
                ></CoherenceDataGrid>
                  : undefined
                }
              </div>
            </Accordion>
            : null}
          {showEditGridPanel &&
            <NonManagedDiscussions discussionId={getDiscussionId()} discussionType="OLSOfferId" onValidDiscussionData={onValidDiscussionData} isExpand={isEditMode ? false : true}  ></NonManagedDiscussions>
          }
        </>
      </CoherencePanel>
</div>
    </>

  );
};
export default OLSOffer; 
