import { CoherenceNav, INavLink } from "@coherence-design-system/controls";
import React, { useState }  from "react"; 
import { useNavigate, useParams } from "react-router-dom";
import {GetUserRole} from "../../utils/GetUserRole"   
interface AppNavigationProps {
  onNavCollapsed: (isCollapsed: boolean) => void; 
}

const _isSelected = (location: Location, path: string): boolean => {   
  return location.href.toLowerCase().includes(path.toLowerCase());
};

const AppNavigation: React.FunctionComponent<AppNavigationProps> = (props:  AppNavigationProps) => {
  let navigate = useNavigate(); 
  const [userRole, setUserRole] = useState(GetUserRole());
  
  const getLinks = (): INavLink[] => {
    
    var links: INavLink[] = [ ]; 
      links=[
        {
        name: "POR Items",
        key: "porItems_navigation",
        ariaLabel: "POR Items",
        icon: "ProductList",
        onClick: () => {
          navigate("/PORItems");
        },
        isSelected: _isSelected(window.location, "/PORItems")
        },
        {
        name: "Create POR",
        key: "CreatePOR_navigation",
        ariaLabel: "Create POR",
        icon: "ProductCatalog",
        onClick: () => {
          navigate("/CreatePOR");
        },
        isSelected: _isSelected(window.location, "/CreatePOR")
        },      
        {
          name: "Intake Management",
          key: "IntakeManagement_navigation",
          ariaLabel: "Intake Management", 
          icon: "CubeShape",
          onClick: () => {
            navigate("/IntakeManagement");
          },
          isSelected: _isSelected(window.location, "/IntakeManagement")
         }
      ]; 
    return links;
  };
  
  const getOverviewLinks = (): INavLink[] => {
    var links: INavLink[] = [   
      {
        name: "POR Details",
        key: "PORDetails_navigation",
        ariaLabel: "AddIn",
        icon: "ProductCatalog",
        onClick: () => {          
          const idStartIndex = window.location.href.lastIndexOf('/');
          const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
          navigate(`/PORDetails/${id}`);
        },
        isSelected: _isSelected(window.location, "/PORDetails/")
      },
      {
        name: "Scope Definition",
        key: "ScopeDefinition_navigation",
        ariaLabel: "AddIn",
        icon: "GridViewMedium",
        onClick: () => {
          const idStartIndex = window.location.href.lastIndexOf('/');
          const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
          navigate(`/ScopeDefinition/${id}`);
        },
        isSelected: _isSelected(window.location, "/ScopeDefinition/")
      }  
    ]; 
    return links;
  };

  const getNonManagedLinks = (): INavLink[] => {
    
    var links: INavLink[] = [   
      {
        name: "Non-Managed",
        key: "nonmanaged_navigation",
        ariaLabel: "Non-Managed",
        icon: "GridViewMedium",
        onClick: () => {
          navigate("/NonManaged");
        },
        isSelected: _isSelected(window.location, "/NonManaged") 
      },
      {
        name: "OLS Offer ID Generation",
        key: "OLSOfferIDGeneration_navigation",
        ariaLabel: "OLS Offer ID Generation",  
        icon: "CloudFlow",
        onClick: () => {
          navigate("/OLSOffer");
        },
        isSelected: _isSelected(window.location, "/OLSOffer")
      }, 
      {
        name: "Finance Data Audit",
        key: "FinanceDataAudit_navigation",
        ariaLabel: "Finance Data Audit",  
        icon: "TextDocument",
        onClick: () => {
          navigate("/FinanceDataAudit");
        },
        isSelected: _isSelected(window.location, "/FinanceDataAudit")
      }
      ]; 

      if (process.env.REACT_APP_Evn?.toLowerCase() === "dev") {
        links.push({
        name: "Finance Data History",
        key: "FinanceDataAuditHistory_navigation",
        ariaLabel: "Finance Data Audit",
        icon: "TextDocument",
        onClick: () => {
            navigate("/FinanceDataAuditHistory");
        },
        isSelected: _isSelected(window.location, "/FinanceDataAuditHistory"),
        });
        }
    return links;
  };
  const getExternalWebsiteLinks = (): INavLink[] => {
    
    var links: INavLink[] = [    
      {
        name: "Release Calendar",
        key: "ReleaseCalendar_navigation",
        ariaLabel: "Release Calendar",  
        icon: "Calendar",
        onClick: () => {
          window.open(process.env.REACT_APP_ReleaseCalendarWebsite_URL, "_blank");
        } 
      },
      {
        name: "Release Management",
        key: "ReleaseManagement_navigation",
        ariaLabel: "Release Management",  
        icon: "ReleaseGateCheck",
        onClick: () => {
          window.open(process.env.REACT_APP_PECORMWebsite_URL, "_blank");
        } 
      }
      ]; 
    return links;
  };

  const groupLinks = (): any => {
    var groupLinks=[];  
      groupLinks.push({
        key: "Left navigation POR menu",
        links: getLinks()
      });   
    if(window.location.pathname.includes("PORDetails") || window.location.pathname.includes("ScopeDefinition")){
      groupLinks.push({ 
        key: "Left navigation Overview menu",
        name:"Overview",
        links: getOverviewLinks()
      });   
    }
    groupLinks.push({        
      key: "Left navigation Non Managed menu", 
      links: getNonManagedLinks()
    }); 
    groupLinks.push({        
      key: "Left navigation External menu", 
      links: getExternalWebsiteLinks()
    }); 

    return groupLinks;
  };

  return (
    <CoherenceNav
    appName={'DOME'}
      groups={ groupLinks()}
      onNavCollapsed={props.onNavCollapsed}
      toggleNavAriaLabel={'DOME navigation'}
      toggleNavTooltip={{ expand: 'Expand Navigation', collapse: 'Collapse Navigation' }}
    />
  );
}; 

export const withRouter = (Component:any) =>{
  const Wrapper = (props:any) =>{
    const history = useNavigate();
    console.log('envt',process.env.REACT_APP_Evn);
    return <Component history={history} {...props}/>
  } 
  return Wrapper;
}
export default withRouter(AppNavigation);
