import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { CoherenceHeader } from "@coherence-design-system/controls";
import React from "react";
import { settingsStyles } from "./AppHeader.styles";

 export const AppHeader: React.FunctionComponent = () => {

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [UserName, setName] = useState("");
  const [Email, setEmail] = useState("");

  useEffect(() => {
      if (account) {
        if(account.name) {
          setName(account.name);
          sessionStorage.setItem("localAccountName",account.name);
          sessionStorage.setItem("localAccountId",account.localAccountId);
          sessionStorage.setItem("localAccountEmailId",account.username);
        }
        if(account.username) {
          setEmail(account.username);
        }
      } else {
          setName("");
          setEmail("");
      }
  }, [account]);

 

  // Settings panel states
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] =
  React.useState<boolean>(false);  

  return (
    <div> 
    <CoherenceHeader  
    appNameSettings={{
      label: "DOME",
      ariaLabel: "DOME",
    }}
    farRightSettings={{ 
      feedbackSettings: {
        buttonSettings: {
          title: "Feedback",
          ariaLabel: "Feedback",
        },
        panelSettings: {
          ocvButtonIsFocused: false,
          onClick: ():any => { 
            return window.open("https://forms.office.com/r/VbPE5HZFuu",'Feedback Form');
          },
        },
      },
      profileSettings: {
        buttonSettings: {
          title: "Profile",
          ariaLabel: "Profile",
        },
        panelSettings: {
          titleText: "Profile",
          fullName: UserName ,
          emailAddress: Email,
          imageUrl: "",
          viewAccountLink: "https://myaccount.microsoft.com",
          closeButtonAriaLabel:"Close",
          logOutLink: "#",
          hideSignOut:true,
          hideSignOutDivider:true,
          body: getProfilePanelBody(),
        },
      }
    }}
    dismissOpenedPanel={!isSettingsPanelOpen}
    onDismissOpenedPanel={() => setIsSettingsPanelOpen(false)} headerLabel={"DOME"}    /> 
     </div>
  );
};
 

const getProfilePanelBody = () => {
  return (
    <div className={settingsStyles.root}> 
    </div>
  );
}; 