
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Accordion from "../../../../shared/Accordion/Accordion";
import { CoherenceLoading, CoherenceNotificationType } from "@coherence-design-system/controls";
 import { Stack, TextField, IStackStyles, IStackProps, DatePicker, Breadcrumb, Dropdown, PrimaryButton, DefaultButton, IDropdownOption,Label, Callout, Link, Checkbox, } from "@fluentui/react";
import Links from "./Links/Links";
import Contacts from "./Contacts/Contacts";
import Milestones from "./Milestones/Milestones";
import References from "./References/References";
import Discussions from "./Discussions/Discussions";
import { CustomRTEPlugin } from "../../../../shared/CustomRTEPlugin/CustomRTEPlugin";
import type { EditorPlugin } from 'roosterjs';
import Attachments from "./Attachments/Attachments";
import { PrimaryContactPicker } from "../../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { DOMEApiHelper, isEmpty, onFormatDate } from "../../../../utils/DOMEApiHelper";
import dayjs from "dayjs";
import ToastMessage from "../../../../shared/ToastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";
import { PORDetailsStyles,UnAuthorizedStyles} from "./PORDetails.styles";
import { Icon } from '@fluentui/react/lib/Icon';
import { RMApiHelper } from "../../../../utils/RMApiHelper";
import { GetUserRole } from "../../../../utils/GetUserRole";
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";
import { Card, CardStandardHeader } from "@coherence-design-system/card";
import Issues from "./Issues/Issues";
export const PORDetails: React.FunctionComponent = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isLoaded, setIsLoaded] = useState(false);
  const [attachmentDescriptionList, setAttachmentDescriptionList] = useState<any>([]);
  const [MilestonesTypeList, setMilestonesTypeList] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation();
  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { whiteSpace: 'nowrap' } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
  };
  const [blobFiles, setBlobFiles] = useState<any>();
  const {unauthorizedHeader} = UnAuthorizedStyles;
  const [fileDetails, setFileDetails] = useState<any>([]);
  const defaultUserName = sessionStorage.getItem("localAccountName");
  const defaultUserID = sessionStorage.getItem("localAccountId");
  const defaultUserEmailId = sessionStorage.getItem("localAccountEmailId");
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [porData, setPORData] = useState<any>([]);
  const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
  const [isPatternRequired, setIsPatternRequired] = React.useState<boolean>(false);
  const [showCategoryErrorMessage, setCategoryShowErrorMessage] = useState(false);
  const [showSubCategoryErrorMessage, setSubcategoryShowErrorMessage] = useState(false);
  const [showPatternErrorMessage, setPatternShowErrorMessage] = useState(false);
  const [showCapabilityErrorMessage, setCapabilityShowErrorMessage] = useState(false);
  const [showOnSave, setShowOnSave] = useState(false);
  const [serviceLineList, setServiceLineList] = useState<any>([]);
  const [porDetails, setPORDetails] = useState<CreatePORModel>(
    { Title: "", StateId: 0, Capability: 0, ServiceLine: 0,CatalogCategory:"", Category: 0, SubCategory: 0, Pattern: 0, Description: "", TargetCompletionDate: new Date(),IsConfidential : false, PrimaryContact: [{ "text": defaultUserName, "objectId": defaultUserID ,"emailId":defaultUserEmailId }] });
  const [originalPORDetails, setOriginalPORDetails] = useState<CreatePORModel>(
    { Title: "", StateId: 0, Capability: 0, ServiceLine: 0, Category: 0, SubCategory: 0, Pattern: 0, Description: "", TargetCompletionDate: new Date(),IsConfidential : false, PrimaryContact: [{ "text": defaultUserName, "objectId": defaultUserID , "emailId":defaultUserEmailId }] });   
  const [updatePORID,setUpdatePORID] = useState<any>();
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [primaryContactRequired, setAssignedToRequired] = React.useState<boolean>(true);
  const[milestoneDetails,setMilestoneDetails] = useState<any>([]);
  const [contactTypeList, setContactTypeList] = useState<any>([]);
  const [contactDetails, setContactDetails] = useState<any>([]);
  const [referenceDetailList, setReferenceDetailList] = useState<any>([]);
  const [referencesDetail, setreferencesDetail] = useState<any>([]);
  const [issueDetail, setIssuesDetail] = useState<any>([]);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const [upcomingReleases, setUpcomingRelease] = useState<any>([]);
    const [isPatternAvailable, setIsPatternAvailable] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [PORDescription, setPORDescription] = useState<string>("");
    const [MetaDataList, setMetaDataList] = useState<any>([]);
    const MyIcon = () => <Icon iconName="Info" />;
    const [selectServiceLineErrorMsg, setSelectServiceLineErrorMsg] = useState<boolean>(isEditMode? false : true);
    const [valueReset, setValueReset] = useState<boolean>(false);

    const onCalloutClick = () => {
      if(isCalloutVisible === true)
        setIsCalloutVisible(false);
      else 
      setIsCalloutVisible(true);
    };


  //onchange POR Title  
  const onChangeTitle = (event: any) => {
    setPORDetails({ ...porDetails, Title: event.target.value });
    setIsDisabledSaveButton(false); // To enable Save button  
  };
  //RichTextField
  const handleRichTextChange = (value: string | undefined): void => {
    setPORDetails({ ...porDetails, Description: value });  
      if(value!==undefined && isEditMode && originalPORDetails['Description']!=value){
        setIsDisabledSaveButton(false); // To enable Save button    
      }
      else if(value!=undefined && !isEditMode){
        setIsDisabledSaveButton(false); // To enable Save button    
      } 
  }
  
  const [isChecked, setIsChecked] = React.useState(false);
  const [onChangeCheckBox, setOnChangeCheckBox] = React.useState(false);
  const onchangeCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setIsChecked(!!checked);
    setIsDisabledSaveButton(false); // To enable Save button  
    if (checked) {
      setOnChangeCheckBox(true);
      porDetails.IsConfidential = true;
    }
    else {
      setOnChangeCheckBox(false);
      porDetails.IsConfidential = false;
    }
    setPORDetails(porDetails);
  };

   //PrimaryContact
   const onSelectPrimaryContact = (contact: any) => {
    setIsDisabledSaveButton(false);
   if(!isEmpty(contact)  && !isEmpty(contact.objectId) ){
    setPORDetails({ ...porDetails, PrimaryContact: [contact]});
   }
    else{
      setPORDetails({ ...porDetails, PrimaryContact: []});
    }
  
   setIsDisabledSaveButton(false); // To enable Save button  
    
}

  //BlobFiles
  const onSaveAttachments = (attachment: any) => { 
    setBlobFiles(attachment);
    setFileDetails(attachment);
    if(valueReset === true){
      setValueReset(false);
    }
  }
  //Attachment details 
  let attachmentData = fileDetails.map((item: any, index: number) => {
    return {
      Name: item.name,
      Description: item.description,
      FileBlobName : item.fileBlobName,
      FileVersion :item.fileVersion
    }
  });

  //Milestone Data
  const onSaveMilestone = (milestone: any) => { 
    setMilestoneDetails(milestone);
    if(valueReset === true){
      setValueReset(false);
    }
  }

  let milestoneData = milestoneDetails.map((item: any, index: number) => {
    return {
      MilestoneType: item.MilestoneType,
      MilestoneDate: new Date (item.MilestoneDate)
    }
  });

  // Contacts
  const onSaveContacts =(contact: any) => {
    setContactDetails(contact); 
    if(valueReset === true){
      setValueReset(false);
    }
  }
  let contactData = contactDetails.map((item: any, index: number) => {
    return {
      ContactType: item.ContactTypeId,
      ContactGUID: item.ContactGUID
    }
  });

    //Reference Details
    const onSaveReferenceDetails = (reference: any) => {
      setreferencesDetail(reference);  
      if(valueReset === true){
        setValueReset(false);
      }                              
    }

    let referenceData = referencesDetail.map((item: any, index: number) => {
      return {
        ReferenceType: item.referenceTypeId,
        ReferenceValue: item.referencesValue              
      }
    });

    //Issues
    const onSaveIssueDetails = (issues: any) => {
      setIssuesDetail(issues);  
      if(valueReset === true){
        setValueReset(false);
      }                              
    };

    let issueData = issueDetail.map((item: any, index: number) => {
      return {
        IssueType: item.issuesTypeId,
        IssueValue: item.issuesValue              
      }
    });

  let navigate = useNavigate();
  interface CreatePORModel {
    Title: string;
    StateId?: number;
    TargetCompletionDate: Date;
    PrimaryContact: any;
    Description?: string;
    Category?: number;
    ServiceLine: number;
    ServiceLineTitle?: string;
    SubCategory: any;
    Pattern?: number;
    Capability?: number;
    Attachments?: string | null;
    References?: string | null;
    CreatedBy?: string | null;
    IsConfidential?:boolean ;
    CatalogCategory ?: string;
  }
  //pattern related code start 
  const [catalogCategoryList, setCatalogCategoryList] = useState<any>([]);
  const [porState, setPORState] = useState<any>([]);
  const [patternsCombinationList, setPatternsCombinationList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [patternsList, setPatternList] = useState<any>([]);
  const [capabilityList, setCapabilityList] = useState<any>([]);
  function uniquearray(arr: any) {
    return arr.filter((obj: any, index: any, self: any) => index === self.findIndex((o: any) => o["key"] === obj["key"]));
  }
  const requestOptionsGETALL = {
    method: 'GET'
  };
  const LoadCatlogCategoryDetails = () => {
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetAllPORDetailsDataAsync', requestOptionsGETALL)
      .then(res => {
        setIsLoaded(false);
        if (!isEmpty(res)) {
          setMetaDataList([...res[0]["MetaData"].map((item: any) => ({
            key: item.Id,
            text: item.Title,
            MetadataType: item.MetadataType,
            ServiceLine: item.ServiceLine
            }))
          ]); 
          setServiceLineList([
            ...res[0]["ServiceLineList"].map((item: any) => ({
              key: item.Id,
              text: item.Title,
            }))
          ]);
                         
        }
      }).catch(error => {
        setIsLoaded(false);
        setToastTitle("Error while fetching POR Details data  ");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
  }
 

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
}
  //Upcoming releases from RM API
  const GetUpcomingReleases =()=>
  {
    
    RMApiHelper('ReleaseCalendar/GetUpcomingReleases', requestOptions)
    .then((response: any) => {      
      let releases = response.map((item: any) => {
        return {
          ReleaseId : item.ReleaseId,
          StartDate: item.StartDate.substring(0,10),
          EndDate: item.EndDate.substring(0,10)
        }
      })
      setUpcomingRelease(releases);
      
     }).catch((error:any) => {
      
    });

  }

  const LoadPatterns = (id: any) => {
    
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    DOMEApiHelper('PORDetails/GetPatternDetail?Id=' + id, requestOptions)
      .then(res => {
       
        if(res.length===0){

          return false;
        }
        setPatternsCombinationList(res);
        let subCategoryList = (res).map((item: any) => {
          return {
            key: item.SubCategoryId,
            text: item.SubCategoryTitle
          }
        })
        let uniqueSubCategoryList = uniquearray(subCategoryList);
        setSubCategoryList([...uniqueSubCategoryList]);
        //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown 
      if ((uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) || uniqueSubCategoryList.length === 1) {          
          porDetails.SubCategory = uniqueSubCategoryList[0].key;
          setPORDetails(porDetails);
          let getPatternsBySubCategory = res.filter((x: any) => x.SubCategoryId === uniqueSubCategoryList[0].key);
          let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
            return {
              key: item.PatternId,
              text: item.PatternTitle
            }
          })
          let uniquePatternsList= uniquearray(patternsListFiltered);
          setPatternList(uniquePatternsList);
          porDetails.Pattern = 0;
          porDetails.Capability = 0;
          setPORDetails(porDetails);
            //select pattern if only one pattern is there
            if(uniquePatternsList.length==1){
              let getCapabilitysByPattern = res.filter((x: any) => x.PatternId === uniquePatternsList[0].key);
              let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
                return {
                  key: item.CapabilityId,
                  text: item.CapabilityTitle
                }
              })
              let uniqueCapabilityList = uniquearray(capabilityListFiltered)
              setCapabilityList([...uniqueCapabilityList]);
              porDetails.Pattern = uniquePatternsList[0].key; 
              setPORDetails(porDetails);  
             //select capability if only one capability is there
              if(uniqueCapabilityList.length===1){
                porDetails.Capability=uniqueCapabilityList[0].key;
                setPORDetails(porDetails);
              }    
              else{
                porDetails.Capability=0;       
                }
              setPORDetails(porDetails);  
            }
        }
        else {
          porDetails.SubCategory = 0
          setPORDetails(porDetails);
          
        }
      }).catch(error => {
        // setIsLoaded(false); 
      });
  };
  const LoadPatternsbyServiceLine = (id: any) => {
    
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };

    DOMEApiHelper('PORDetails/GetPatternDetail?Id=' + id, requestOptions)
      .then(res => {
       
        if(res.length===0){

          return false;
        }

        setPatternsCombinationList(res);
        let catalogCategoryList = (res).map((item: any) => {
          return {
            key: item.CatalogCategoryId,
            text: item.CatelogCategoryTitle,
            IsPatternRequired: item.IsPatternRequired
          }
        })
        let uniqueCatalogCategoryList = uniquearray(catalogCategoryList);
        setCatalogCategoryList([...uniqueCatalogCategoryList]);

        //if category has only one option 
        if (uniqueCatalogCategoryList.length === 1) {  
          porDetails.Category = uniqueCatalogCategoryList[0].key;
          setPORDetails(porDetails);
          let getSubCategoryByCategory = res.filter((x: any) => x.CatalogCategoryId === uniqueCatalogCategoryList[0].key);
          let subCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
            return {
              key: item.SubCategoryId,
              text: item.SubCategoryTitle
            }
          })
          let uniqueSubCategoryList= uniquearray(subCategoryListFiltered);
          setSubCategoryList(uniqueSubCategoryList);
          porDetails.SubCategory = 0;
          porDetails.Pattern = 0;
          porDetails.Capability = 0;
          setPORDetails(porDetails);
          //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown 
          if ((uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) || uniqueSubCategoryList.length === 1) {          
            porDetails.SubCategory = uniqueSubCategoryList[0].key;
            setPORDetails(porDetails);
            let getPatternsBySubCategory = res.filter((x: any) =>  ( x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === uniqueSubCategoryList[0].key));
            let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
              return {
                key: item.PatternId,
                text: item.PatternTitle
              }
            })
            let uniquePatternsList= uniquearray(patternsListFiltered);
            setPatternList(uniquePatternsList);
            porDetails.Pattern = 0;
            porDetails.Capability = 0;
            setPORDetails(porDetails);
              //select pattern if only one pattern is there
              if(uniquePatternsList.length==1){
                let getCapabilitysByPattern = res.filter((x: any) => ( x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === porDetails.SubCategory && x.PatternId === uniquePatternsList[0].key));
                let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
                  return {
                    key: item.CapabilityId,
                    text: item.CapabilityTitle
                  }
                })
                let uniqueCapabilityList = uniquearray(capabilityListFiltered)
                setCapabilityList([...uniqueCapabilityList]);
                porDetails.Pattern = uniquePatternsList[0].key; 
                setPORDetails(porDetails);  
              //select capability if only one capability is there
                if(uniqueCapabilityList.length===1){
                  porDetails.Capability=uniqueCapabilityList[0].key;
                  setPORDetails(porDetails);
                }    
                else{
                  porDetails.Capability=0;       
                  }
                setPORDetails(porDetails);  
              }
          }
          else {
            porDetails.SubCategory = 0
            setPORDetails(porDetails);
            
          }
        }
        else {
          porDetails.Category = 0
          setPORDetails(porDetails);
          
        }

      }).catch(error => {
        // setIsLoaded(false); 
      });

  };

  const onChangeServiceLine = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {    
    setSelectServiceLineErrorMsg(false);
    if (porDetails.ServiceLine ==0 || porDetails.ServiceLine == item.key) {
    porDetails.ServiceLine = item.key;
    porDetails.ServiceLineTitle = item.text;
    porDetails.Category = 0;
    porDetails.Pattern = 0;
    porDetails.Capability = 0;
    setPORDetails(porDetails);
    }
    else if (porDetails.ServiceLine !== item.key ) {
      let result = false;
      if(contactData.length > 0 || attachmentData.length > 0 || milestoneData.length > 0 || referenceData.length > 0)
        { 
       result = window.confirm('Changing Service Line will remove saved attachments, milestones, contacts, references and pattern. Are you sure you want to change service line?');
        }
        
      if (result) {
        //reset all the attachments, contacts, milestones, references and pattern
        setValueReset(true);
      }
      porDetails.ServiceLine = item.key;
      porDetails.ServiceLineTitle = item.text;
      porDetails.Category = 0;
      porDetails.SubCategory = 0;
      porDetails.Pattern = 0;
      porDetails.Capability = 0;
      setPORDetails(porDetails);
    }
    //set contact type list according for the service line selected
    let contactlist =  [...MetaDataList.filter((x: any) => x.ServiceLine == item.text && x.MetadataType == "ContactType")];
    setContactTypeList(contactlist);
    //set attachment type list according for the service line selected
    let attachmentlist =  [...MetaDataList.filter((x: any) => x.ServiceLine == item.text && x.MetadataType == "AttachmentDescription"),
        {
          key: "other",
          text: "Other",
        }]
  
    setAttachmentDescriptionList(attachmentlist);
    //set milestone type list according for the service line selected
    let milestonelist =  [...MetaDataList.filter((x: any) => x.ServiceLine == item.text && x.MetadataType == "MilestoneType")];
    setMilestonesTypeList(milestonelist);
    //set reference type list according for the service line selected
    let referencelist =  [...MetaDataList.filter((x: any) => x.ServiceLine == item.text && x.MetadataType == "ReferenceType")];
    setReferenceDetailList(referencelist);

    setIsDisabledSaveButton(false); // To enable Save button
    setCatalogCategoryList([])
    setSubCategoryList([]);
    setPatternList([]);
    setCapabilityList([]);
    LoadPatternsbyServiceLine(item?.key);
    
  };

  const onChangePORState = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    setPORDetails({ ...porDetails, StateId: item.key });
    setIsDisabledSaveButton(false); // To enable Save button  
  };
  const handleDateSelection = (date: any) => {
    setPORDetails({ ...porDetails, TargetCompletionDate: new Date(date) });
    setIsDisabledSaveButton(false); 
  }
  const onChangeCategory = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    porDetails.Category = item.key;
    porDetails.Pattern = 0;
    porDetails.Capability = 0;
    setPORDetails(porDetails);
    setSubCategoryList([]);
    setPatternList([]);
    setCapabilityList([]);
    setCategoryShowErrorMessage(false);
    setIsDisabledSaveButton(false); 
    

    let getSubCategoryByCategory = patternsCombinationList.filter((x: any) => x.CatalogCategoryId === item.key);
    let SubCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
      return {
        key: item.SubCategoryId,
        text: item.SubCategoryTitle
      }
    })
    let uniqueSubCategoryList = uniquearray(SubCategoryListFiltered);
    uniqueSubCategoryList.unshift({ key: 0, text: " - Select SubCategory -" });
    setSubCategoryList([...uniqueSubCategoryList]);
    porDetails.SubCategory = 0;
    porDetails.Pattern = 0;
    porDetails.Capability = 0;

    setPORDetails(porDetails);


  };

  // Subcategory 
  const onChangeSubCategory = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {  
    porDetails.Pattern = 0;
    porDetails.Capability = 0;
    setPORDetails(porDetails);
    setIsDisabledSaveButton(false); 

    if (parseInt(item?.key?.toString() || '0') >0) {
    let getPatternsBySubCategory = patternsCombinationList.filter((x: any) => ( x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === item?.key));
    let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
      return {
        key: item.PatternId,
        text: item.PatternTitle
      }
    })
    let uniquePatternList = uniquearray(patternsListFiltered)
    setPatternList([...uniquePatternList]);

    
    //if(uniquePatternList.length===1 ?  porDetails.Pattern =uniquePatternList[0].key :  porDetails.Pattern = 0 )
    if(uniquePatternList.length===1 ){
      porDetails.Pattern =uniquePatternList[0].key;
      porDetails.SubCategory = item?.key
      setPORDetails(porDetails);
      onChangePattern(event,uniquePatternList[0]);
    }
    else{
    porDetails.Capability = 0;  
    setPORDetails(porDetails); 
    setCapabilityList([]);
    }
  }
  else{
    porDetails.Pattern = 0;
    porDetails.Capability = 0;  
    setPORDetails(porDetails);
    setPatternList([]);
    setCapabilityList([]);
  }

    
    porDetails.SubCategory = item?.key;
    setPORDetails(porDetails);
    setSubcategoryShowErrorMessage(false);
  };
  // Pattern
  const onChangePattern = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    setIsDisabledSaveButton(false); 
    porDetails.Capability = 0;  
    setPORDetails(porDetails); 
    let getCapabilitysByPattern = patternsCombinationList.filter((x: any) => ( x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === porDetails.SubCategory && x.PatternId === item?.key));
    let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
      return {
        key: item.CapabilityId,
        text: item.CapabilityTitle
      }
    })
    let uniqueCapabilityList = uniquearray(capabilityListFiltered);
   setCapabilityList([...uniqueCapabilityList]);
      
    //if(uniqueCapabilityList.length ===1 ?  porDetails.Capability=uniqueCapabilityList[0].key :  porDetails.Capability = 0 )
    if(uniqueCapabilityList.length===1){
      porDetails.Capability =uniqueCapabilityList[0].key;
      setPORDetails(porDetails);
      onChangeCapability(event,uniqueCapabilityList[0]);
    }  
    porDetails.Pattern = item.key;
    setPORDetails(porDetails);
    setPatternShowErrorMessage(false);
    
  };
  // Capability
  const onChangeCapability = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    setIsDisabledSaveButton(false); 
    setPORDetails({ ...porDetails, Capability: item.key });
    setCapabilityShowErrorMessage(false);    
  };

  const setAttachmentDescriptionOneEdit = () => {
    let attachmentDescriptionList = MetaDataList.filter((x: any) => x.ServiceLineId == porDetails.ServiceLine && x.MetadataType == "AttachmentDescription");
    setAttachmentDescriptionList(attachmentDescriptionList);
    return attachmentDescriptionList;
  }


  const isDataValid = (data: any) => {

    // Create POR validation
    if (!isEditMode) {
      if (isEmpty(data.Title) || isEmpty(porDetails.PrimaryContact) || isEmpty(porDetails.Description) || isEmpty(porDetails.TargetCompletionDate) || isEmpty(data.Category)) {
        return false;
      }
      if (!isEmpty(porDetails.SubCategory) && porDetails.SubCategory !== 0) {
        if (isEmpty(porDetails.Pattern) || isEmpty(porDetails.Capability)) {
          return false;
        }
      }
    }
     // Edit POR Validation
     if( (isEditMode)  && (isEmpty(data.Title) || isEmpty(porDetails.PrimaryContact) || porDetails.Description===undefined || isEmpty(porDetails.Description)  || isEmpty(porDetails.Description.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim()) ||  isEmpty(porDetails.TargetCompletionDate) || isEmpty(data.StateId) )) { 
      return false;
    } 
     return true;
  };
  const handleIsPatternRequired = (data: any) => {
    const isCategoryValid = isPatternRequired && (data.Category === 0 || data.Category === undefined);
    setCategoryShowErrorMessage(isCategoryValid);
    const isSubcategoryValid = isPatternRequired && (data.SubCategory === 0 || data.SubCategory === undefined) && !isEmpty(subCategoryList);
    setSubcategoryShowErrorMessage(isSubcategoryValid);
    const isPatternValid = isPatternRequired && (data.Pattern === 0 || data.SubCategory === undefined) && !isEmpty(patternsList);
    setPatternShowErrorMessage(isPatternValid);
    const isCapabilityValid = isPatternRequired && (data.Capability === 0 || data.SubCategory === undefined) && !isEmpty(capabilityList);
    setCapabilityShowErrorMessage(isCapabilityValid);
  }
  const onCancel = () => {
    window.location.reload();
  }

  interface UpdatePorDetailsModel {
    PORId: number;
    Title?: string | null;
    StateId?: number | null;
    TargetCompletionDate?: string | null;
    PrimaryContact?: string | null;
    Description?: string | null;
    ModifiedBy?: string | null;
    IsConfidential?:boolean;
    }

  //pattern related code end


  const onSave = async () => {
    setOnSaveDataValid(true);
    handleIsPatternRequired(porDetails); // create por
    if (!isDataValid(porDetails)) {
      return false;
    }
    var createdByID = sessionStorage.getItem("localAccountId");
    let vsDate = dayjs(porDetails.TargetCompletionDate).startOf("day").toDate();
    const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
    if(!isEditMode ||(isEditMode && porDetails.PrimaryContact.length>0 && porDetails.PrimaryContact[0].objectId !== originalPORDetails.PrimaryContact[0].objectId)){
      let PIIDataHandler = (porDetails.PrimaryContact !== undefined && porDetails.PrimaryContact.length > 0) ?
        [ {
          DisplayName: porDetails.PrimaryContact[0].DisplayName,
          ObjectId: porDetails.PrimaryContact[0].objectId,
          EmailId: porDetails.PrimaryContact[0].emailId
        } ]: "";
        const requestPiiHandlerOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(PIIDataHandler),
          mode: 'cors'
        };
        DOMEApiHelper('PORDetails/UpsertPIIData', requestPiiHandlerOptions)
        .then(res => {
         // console.log('res fro pii', res);
        
        }).catch(res => {
          setToastMessage(res.toString());
          setToastType(CoherenceNotificationType.ERROR);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        });   
    }

    let data = {
      Title: porDetails.Title,
      TargetCompletionDate: formattedDate,
      PrimaryContact: (porDetails.PrimaryContact !== undefined && porDetails.PrimaryContact.length > 0) ? porDetails.PrimaryContact[0].objectId : "",
      Description: porDetails.Description,
      serviceLineData: porDetails.ServiceLineTitle,
      serviceLineId: porDetails.ServiceLine,
      //state: porDetails.ServiceLineTitle == "VL" ? "Candidate" : (porDetails.ServiceLineTitle == "Product Ops" ? "New" : null),
      Category: porDetails.Category,
      SubCategory: porDetails.SubCategory,
      Pattern: porDetails.Pattern,
      Capability: porDetails.Capability,
      Attachments: attachmentData,
      Contacts: contactData,
      CreatedBy: createdByID,
      Milestones: milestoneData,
      References: referenceData,
      IsConfidential: porDetails.IsConfidential
    };
  
      if(!isEditMode){
      const formData = new FormData();
        if(!isEmpty(blobFiles) && blobFiles.length>0){          
        blobFiles.forEach((fileRecord: { file: string | Blob; }) => {
          formData.append('files', fileRecord.file);
        }); 
      }
      formData.append('createPORJSON', JSON.stringify(data));
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData,
          mode: 'cors'
        };
        setToastTitle("Create POR  ");
        setToastMessage("Creating POR");
        setToastType(CoherenceNotificationType.PENDING);
        setShowToast(true);
        DOMEApiHelper('PORDetails/CreatePORItem', requestOptions)
          .then(res => {
            setToastType(CoherenceNotificationType.SUCCESS);
            setToastMessage("POR has been created successfully.");
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
            setIsDisabledSaveButton(true); 
            if (res > 0) {
              navigate(`/PORDetails/${res}`);
            }
          }).catch(res => {
            setToastMessage(res.toString());
            setToastType(CoherenceNotificationType.ERROR);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });   
    }
    else{
      // Update POR Details code    
      let editedData = {
        PORId:parseInt(updatePORID),
        Title: porDetails.Title,
        StateId:porDetails.StateId,
        TargetCompletionDate: formattedDate,
        PrimaryContact: (porDetails.PrimaryContact !== undefined && porDetails.PrimaryContact.length > 0) ? porDetails.PrimaryContact[0].objectId : "",
        Description: porDetails.Description,
        ModifiedBy: createdByID,
        IsConfidential: porDetails.IsConfidential
        };
        
       const updateModifiedBy = (data: any) => {
          data['ModifiedBy'] = createdByID;
          data['PORId'] = editedData['PORId']
          return data;
        }
        let inputList: any = [];
      
        let data: UpdatePorDetailsModel = {
          PORId : -1
        };
 
       let vsDateoriginal = dayjs(originalPORDetails.TargetCompletionDate).startOf("day").toDate();
       let  originalformatteddate = dayjs(vsDateoriginal).format("YYYY-MM-DDTHH:mm:ss.SSS");
       
        if (originalPORDetails['Title'] !== editedData['Title'] ){
          data['Title'] = editedData['Title'];
          data = updateModifiedBy(data);
        }
        if (originalPORDetails['StateId'] !== editedData['StateId'] ){
          data['StateId'] = editedData['StateId'];
          data = updateModifiedBy(data);
        }
        if (originalformatteddate !== editedData['TargetCompletionDate'] ){
          data['TargetCompletionDate'] = editedData['TargetCompletionDate'];  
          data = updateModifiedBy(data);
        }
        if (originalPORDetails['PrimaryContact'][0].objectId !== editedData['PrimaryContact'] ){
          data['PrimaryContact'] = editedData['PrimaryContact'];
          data = updateModifiedBy(data);
        }
        if (originalPORDetails['Description'] !== editedData['Description'] ){
          data['Description'] = editedData['Description'];
          data = updateModifiedBy(data);
        }
        if (originalPORDetails['IsConfidential'] !== editedData['IsConfidential'] ){
          data['IsConfidential'] = editedData['IsConfidential'];
          data = updateModifiedBy(data);
        }
        inputList.push( JSON.parse(JSON.stringify(data)))

        // When  No field is changed
        if(inputList[0].PORId==-1){
          setShowOnSave(true);
          setIsDisabledSaveButton(false); // to Disable when no changes
          return false;
        }

        setShowOnSave(false);
       const requestUpdateOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputList),
        mode: 'cors'
    };
    setToastTitle("Update POR");
    setToastMessage("Updating POR is in progress");
    setToastType(CoherenceNotificationType.PENDING);
    setShowToast(true);
    DOMEApiHelper('PORDetails/UpdatePORDetails', requestUpdateOptions)
      .then(res => {
        setToastType(CoherenceNotificationType.SUCCESS);
        setToastMessage("POR has been updated successfully.");
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
        
        setIsDisabledSaveButton(true); 
        if (res.Message==="Success" ) {
          navigate(`/PORDetails/${updatePORID}`);
          LoadCatlogCategoryDetails();
        }
      }).catch(res => {
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
   
    }
   
  };

  // disable save button 
    
const disableSaveButton = (): boolean => { 

      if(isEmpty(porDetails.Title) ||  isEmpty(porDetails.Description) || isEmpty(porDetails.TargetCompletionDate) || isEmpty(porDetails.ServiceLine) || isEmpty(porDetails.PrimaryContact) || (!isEmpty(porDetails.PrimaryContact) && isEmpty(porDetails.PrimaryContact[0].objectId) )){
        return true;
      }
      
      if(!isEditMode && isEmpty(porDetails.Category) && isEmpty(porDetails.SubCategory) ){
        return true;
      }
      else if(!isEditMode && !isEmpty(porDetails.Category) && !isEmpty(porDetails.SubCategory)  && porDetails.SubCategory!==0){
        if( (isEmpty(porDetails.Pattern) || isEmpty(porDetails.Capability))){
          return true;
        }
      }
      if (isEditMode && originalPORDetails['Title'] === porDetails.Title && originalPORDetails['StateId'] === porDetails.StateId && originalPORDetails['Description'] === porDetails.Description && originalPORDetails['TargetCompletionDate'] === porDetails.TargetCompletionDate && 
        (!porDetails.PrimaryContact || (porDetails.PrimaryContact[0] && originalPORDetails['PrimaryContact'][0].objectId === porDetails.PrimaryContact[0].objectId)) && 
        originalPORDetails['IsConfidential'] === porDetails.IsConfidential) {    
        return true; 
    }
      else{
        return false; 
      }  
  } 


useEffect(() => {
    if ( !isEmpty(originalPORDetails) && !isEmpty(originalPORDetails.PrimaryContact)) {
      // If the POR is confidential, then check if the user is authorized to view the POR
      if(originalPORDetails.IsConfidential  && contactDetails.length > 0 ){
        const authorizedUser = contactDetails.some((contact : any) => contact.ContactGUID === defaultUserID?.toString());
        if (!authorizedUser) {
          setIsAuthorized(false); //To dispaly Access Denied message
        }
      }
    }
  }, [originalPORDetails,contactDetails]);
  //upto on save

  const LoadScopeSummaryData =(PORId : number) => {
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    DOMEApiHelper('PORDetails/GetSummaryScopeDetailByID?Id=' + PORId, requestOptions)
      .then(res => {  
        // If no pattern is added, then show the error message
        if(res.length>0){
          setIsPatternAvailable(true);
        }else{
          setIsPatternAvailable(false);
        }
      }).catch(res => {
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
        
      });
  };

  const LoadPORData = (PORID: string) => {
    setSelectServiceLineErrorMsg(false);
    porDetails.PrimaryContact = undefined;
    setPORDetails(porDetails);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetPORDetailsByID?id=' + PORID, requestOptions)
      .then(res => {
        setIsLoaded(false);
        if (!isEmpty(res)) {
          //Por state list 
          setPORState([
            ...res[0]["PORStatesList"].map((item: any) => ({
              key: item.Id,
              text: item.Title
            }))
          ])

          //POR data
          setPORData(res);
          const updatedPOR = {
            ...res[0],
            IsConfidential: isEmpty(res[0].IsConfidential) ? false : res[0].IsConfidential,
            TargetCompletionDate: new Date(res[0].TargetCompletionDate),
            attachmentData: "",
            PrimaryContact: isEmpty(res[0].PrimaryContactUserName) ? [] : [{ "text": res[0].PrimaryContactUserName, "objectId": res[0].PrimaryContact }], 
            CreatedBy: "",
            //ServiceLine: res[0].ServiceLine.split(';')[0],
          }; 
          setPORDescription(updatedPOR.Description);
          setPORDetails(updatedPOR);
          setOriginalPORDetails(updatedPOR);  
          setIsPageLoaded(true);         
        }
      }).catch(res => {
        setIsLoaded(false);
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  };

  
  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    LoadCatlogCategoryDetails();
    if (routerName === 'CreatePOR') {
      setIsEditMode(false); 
      setIsPageLoaded(true);
    } else if (routerName === 'PORDetails') {
      setIsEditMode(true);
    }
    GetUpcomingReleases();
  }, []);
  //when in edit mode get the por id 
  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    if (routerName === 'PORDetails') {
      const idStartIndex = window.location.href.lastIndexOf('/');
      const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
      if (id) {
        setIsEditMode(true);
        LoadPORData(id);
        LoadScopeSummaryData(parseInt(id));
        setUpdatePORID(id);
      } 
    }
  }, [location]);
  //plugin code start 
  const getPlugins = () => {
    let plugins: EditorPlugin[] = [];
    plugins.push(
      new CustomRTEPlugin((content) => {
      })
    );
    return plugins;
  };
  //plugin code end 
  const generatePORDetailControls = (): any => {
    let tmpRenderObj: any[] = [];
 
    const idStartIndex = window.location.href.lastIndexOf('/');
    const porId = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null;

    if (isEditMode === true) {
      tmpRenderObj.push(
        <div key="porId">
          <Label>POR ID</Label>
          <Label style={{ marginTop: 0 }} className="readonyLabel" disabled>{porId}</Label>
        </div>
      );
    }
  
    tmpRenderObj.push(
      <TextField
        key="title"
        label="Title"
        placeholder={"Please enter the Title"}
        required
        value={porDetails.Title}
        errorMessage={onSaveDataValid && porDetails.Title.trim() === '' ? 'Please enter the Title ' : undefined}
        onChange={onChangeTitle}
        maxLength={1000}
        autoFocus
      />
    );
    if (isEditMode) {
      tmpRenderObj.push(
        <Dropdown
          key="state"
          label={'State'}
          options={porState}
          placeholder={"Please select the POR State"}
          selectedKey={porDetails.StateId}
          required={isEditMode}
          errorMessage={onSaveDataValid && porDetails.StateId === undefined ? 'Please select the State ' : undefined}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption<any> | undefined,
            index?: number | undefined
          ) => { onChangePORState(event, option, index); 
                 
          }
          }
        />
      )
    }
  
    tmpRenderObj.push(
      <span key="completionDate">
        <Label>{"Target Completion Date"} <span id="releaseDates" onClick={onCalloutClick}><MyIcon /></span></Label>
        <DatePicker
          ariaLabel="Target Completion Date Picker"
          value={porDetails.TargetCompletionDate}
          minDate={new Date()}
          placeholder={"Please select the Target Completion Date"}
          formatDate={onFormatDate}
          onSelectDate={newDate => { handleDateSelection(newDate); }}
          textField={{ errorMessage: "" }}
          isRequired
        />
        {isCalloutVisible && (
          <Callout
            role="dialog"
            target={`#${"releaseDates"}`}
            onDismiss={onCalloutClick}
            style={{ padding: "20px" }}
            setInitialFocus
          >
            <div>
              {"Leverage below upcoming release timelines: "}
              <ul>
                {upcomingReleases.map((item: any, index: number) =>
                  <li key={`release-${index}`}>{dayjs(item.StartDate).format("MM/DD/YYYY")}</li>
                )}
              </ul>
              {"Please refer "}
              <Link target="_blank" onClick={(event) => {
                window.open(process.env.REACT_APP_ReleaseCalendar_URL);
              }} >
                Release Calendar
              </Link>
              {" for more information."}
            </div>
          </Callout>
        )}
      </span>
    );
  
    if (porDetails.PrimaryContact != undefined) {
      tmpRenderObj.push(
        <PrimaryContactPicker
          key="primaryContact"
          label={"Primary Contact"}
          onSaveDataValid={onSaveDataValid}
          defaultPrimaryContact={porDetails.PrimaryContact}
          onSelectPrimaryContact={onSelectPrimaryContact}
          required={primaryContactRequired}
          EnableGroupSearch={true}
        />
      );
    }
    if(porDetails.Description != undefined){
      tmpRenderObj.push(
        <div key="description" style={{ height: '300px' }}>
      <Label>Description<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>
      <RichTextEditor
        styles={{root:{marginTop:-10}}}
        characterMax={4000}
        label=""
        value={PORDescription}
        onChange={handleRichTextChange}
        onBlur={function noRefCheck() { }}          
        onFocus={function noRefCheck() { }}
        onInvalid={function noRefCheck() { }}
        onValid={function noRefCheck() { }}
        placeholder="Please enter the Description "
        resizable={true}
        plugins={getPlugins()}
      />
      {onSaveDataValid && (porDetails.Description === undefined || porDetails.Description === '') ? 
        <span style={{ color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5 }}>Please enter the Description.</span> 
        : undefined}
        </div>
      );      
    }
    tmpRenderObj.push(
      <div key="confidential" style={{marginTop:"40px"}}>
        <Stack tokens={stackTokens}>
          <Checkbox
            label={"Confidential"}
            styles={{ text: { fontWeight: '500', fontFamily: 'Segoe UI' } }}
            checked={!isEmpty(porDetails) ? porDetails.IsConfidential : false}
            onChange={onchangeCheckbox}
          />
        </Stack>
      </div>
    );
      
    
    {isEditMode == true ?
      tmpRenderObj.push(
        <><Label required>Service Line</Label><Label style={{marginTop:0}}  className="readonyLabel" disabled>{porDetails.ServiceLine}</Label></>
      ):
    tmpRenderObj.push(
      <Dropdown
        label={'Service Line'}
        options={serviceLineList}
        placeholder={"Please select the service line"}
        required={true}
        errorMessage={onSaveDataValid && porDetails.ServiceLine === undefined ? 'Please enter the Service Line' : undefined}
        selectedKey={isEditMode? serviceLineList.filter((x: any) => x.text === porDetails.ServiceLine)[0].key : porDetails.ServiceLine}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          option?: IDropdownOption<any> | undefined,
          index?: number | undefined
        ) => {
          onChangeServiceLine(event, option, index);
          
        }
        }
      />
    )}
    if (isEditMode) {
      if (!isEmpty(porDetails) && !isEmpty(porDetails.CatalogCategory)) {
        tmpRenderObj.push(
		
          <>
            <Label required>Category</Label>
            <Label style={{ marginTop: 0 }} className="readonyLabel" disabled>
              {porDetails.CatalogCategory}
            </Label>
          </>
        );
      } 
    } else {
      tmpRenderObj.push (
        <Dropdown
        key="category"
        label={'Category'}
        options={catalogCategoryList}
        placeholder={"Please select the Category"}
        required = {isEmpty(catalogCategoryList) ? false : true}
        errorMessage={onSaveDataValid && showCategoryErrorMessage ? 'Please enter the Category' : undefined}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          option?: IDropdownOption<any> | undefined,
          index?: number | undefined
        ) => {
          onChangeCategory(event, option, index);
         
        }
        }
        defaultSelectedKey={porDetails.Category}
        defaultValue={porDetails.Category}
      />
    );
    }
  
    
    if (!isEditMode) {
     
      if (!isEmpty(subCategoryList) && !(subCategoryList.length === 1 && subCategoryList.filter((x: any) => x.text === "n/a").length > 0)) {
        tmpRenderObj.push(
          <Dropdown
            key="subCategory"
            label={'Sub Category'}
            options={subCategoryList ?? []}
            defaultSelectedKey={porDetails["SubCategory"]}
            required={false}
            errorMessage={onSaveDataValid && showSubCategoryErrorMessage ? 'Please enter the Sub Category' : undefined}
            onChange={(ev, selected) => { onChangeSubCategory(ev, selected); }}
            placeholder={"Please select the Sub Category"}
          />
        );
      }
  
      if (!isEmpty(patternsList)) {
        tmpRenderObj.push(
          <Dropdown
            key="pattern"
            label={'Pattern'}
            options={patternsList}
            placeholder={"Please select the Pattern"}
            required={ !isEmpty(patternsList) && porDetails.SubCategory>0 ?   true : false}
            errorMessage={onSaveDataValid && showPatternErrorMessage ? 'Please enter the Pattern' : undefined}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption<any> | undefined,
              index?: number | undefined
            ) => { onChangePattern(event, option, index); }
            }
            defaultSelectedKey={porDetails.Pattern}
            defaultValue={porDetails.Pattern}
          />
        );
     }
  
      if (!isEmpty(capabilityList)) {
        tmpRenderObj.push(
          <Dropdown
            key="capability"
            label={'Capability'}
            options={capabilityList}
            placeholder={"Please select the Capability"}
            required={!isEmpty(capabilityList) && porDetails.SubCategory>0 ?  true : false}
            errorMessage={onSaveDataValid && showCapabilityErrorMessage ? 'Please enter the Capability' : undefined}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption<any> | undefined,
              index?: number | undefined
            ) => { onChangeCapability(event, option, index); }
            }
            defaultSelectedKey={porDetails.Capability}
            defaultValue={porDetails.Capability}
          />
        );
      }
    }
  
    return tmpRenderObj;
  }
  

  const [AttachmentsIsExpandDefault, setAttachmentsIsExpandDefault] =useState<any>(true);
  const [ContactsIsExpandDefault, setContactsIsExpandDefault] = useState<any>();
  const [MilestonesIsExpandDefault, setMilestonesIsExpandDefault] = useState<any>();
  const [ReferencesIsExpandDefault, setReferencesIsExpandDefault] = useState<any>();
  const [DiscussionsIsExpandDefault, setDiscussionsIsExpandDefault] = useState<any>();
  const [IssuesIsExpandDefault, setIssuesIsExpandDefault] = useState<any>();
  const onAccordianChange = (status: any,title:any) => { 
    if (title=="Attachments"  && status==false) {      
      setContactsIsExpandDefault('');
      setMilestonesIsExpandDefault('');
      setReferencesIsExpandDefault('');
      setDiscussionsIsExpandDefault('');
      setIssuesIsExpandDefault('');
    setTimeout(() => {
      setContactsIsExpandDefault(false);
      setMilestonesIsExpandDefault(false);
      setReferencesIsExpandDefault(false);
      setDiscussionsIsExpandDefault(false); 
      setIssuesIsExpandDefault(false); 
    }, 100);

    } else if (title=="Contacts"  && status==false) {
      setAttachmentsIsExpandDefault('');
      setMilestonesIsExpandDefault('');
      setReferencesIsExpandDefault('');
      setDiscussionsIsExpandDefault('');
      setIssuesIsExpandDefault('');
      setTimeout(() => {
        setAttachmentsIsExpandDefault(false);
        setMilestonesIsExpandDefault(false);
        setReferencesIsExpandDefault(false);
        setDiscussionsIsExpandDefault(false);
        setIssuesIsExpandDefault(false);
      }, 100);
    } else if (title=="Milestones"  && status==false) {
      setAttachmentsIsExpandDefault('');
      setContactsIsExpandDefault('');
      setReferencesIsExpandDefault('');
      setDiscussionsIsExpandDefault('');
      setIssuesIsExpandDefault('');
      setTimeout(() => {
        setAttachmentsIsExpandDefault(false);
        setContactsIsExpandDefault(false);
        setReferencesIsExpandDefault(false);
        setDiscussionsIsExpandDefault(false);
        setIssuesIsExpandDefault(false);
      }, 100);
    } else if (title=="References"  && status==false) {
      setAttachmentsIsExpandDefault('');
      setContactsIsExpandDefault('');
      setMilestonesIsExpandDefault('');
      setDiscussionsIsExpandDefault('');
      setIssuesIsExpandDefault('');
      setTimeout(() => {
        setAttachmentsIsExpandDefault(false);
        setContactsIsExpandDefault(false);
        setMilestonesIsExpandDefault(false);
        setDiscussionsIsExpandDefault(false);
        setIssuesIsExpandDefault(false);
      }, 100);
    } else if(title=="Discussions"  && status==false) {
      setAttachmentsIsExpandDefault('');
      setContactsIsExpandDefault('');
      setMilestonesIsExpandDefault('');
      setReferencesIsExpandDefault('');
      setIssuesIsExpandDefault('');
      setTimeout(() => {
        setAttachmentsIsExpandDefault(false);
        setContactsIsExpandDefault(false);
        setMilestonesIsExpandDefault(false);
        setReferencesIsExpandDefault(false);
        setIssuesIsExpandDefault(false);
      }, 100);
    } else if(title=="Issues"  && status==false) {
      setAttachmentsIsExpandDefault('');
      setContactsIsExpandDefault('');
      setMilestonesIsExpandDefault('');
      setReferencesIsExpandDefault('');
      setDiscussionsIsExpandDefault('');
      setTimeout(() => {
        setAttachmentsIsExpandDefault(false);
        setContactsIsExpandDefault(false);
        setMilestonesIsExpandDefault(false);
        setReferencesIsExpandDefault(false);
        setDiscussionsIsExpandDefault(false);
      }, 100);
    } 
   }

   return <div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }}
> 
		 
{ !isPageLoaded && 
 <div style={{ 
  height: '100vh',    // Set the height to 100% of the viewport height
  maxHeight: '100vh', // Set the maximum height to 100% of the viewport height
  minWidth: '100vw',  // Set the minimum width to 100% of the viewport width
  width: '100vw',     // Set the width to 100% of the viewport width
  maxWidth: '100vw'   // Set the maximum width to 100% of the viewport width
}}>
<CoherenceLoading primaryText={" "}></CoherenceLoading>
</div>
}
{ !isAuthorized && isPageLoaded && !isEmpty(porDetails) &&!isEmpty(porDetails.PrimaryContact) &&																							  					  


<div style={{
    minHeight: '100vh' //this added to adjust footer to screen height
  }}>
  <h2 className={unauthorizedHeader}>Access Denied</h2>
  <p>This is a confidential POR and only Contacts listed on the POR are authorized to access. 
    If you believe you should be able to access the POR, Please reach out to  <strong>{originalPORDetails.PrimaryContact[0].text.toString()}</strong> to get access. </p>
</div>
}

 {isAuthorized  && <div style={{display: isPageLoaded ?    'block' :'none' }}>
     <Breadcrumb  className={PORDetailsStyles.breadCrumbPosition}
      ariaLabel="Breadcrumb bar with 2 items"
      items={[
        {
          href: '/PORItems',
          key: 'PORItems',
          text: 'Plan of Record',
          style: {
            color: '#0072c9'
          }
        },
        {
          isCurrentItem: true,
          key: isEditMode ? 'Edit POR Details' : 'Create POR',
          text: isEditMode ? 'Edit POR Details' : 'Create POR'
        }
      ]}
      overflowAriaLabel="More links"
    />
    {showToast && isPageLoaded && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
    <div className="card-container">
      <div
        style={{
          height: 'auto'
        }}
      >
        <Card header={<CardStandardHeader cardTitle="Details" />}>
          {isPageLoaded && !isLoaded  ? <Stack  horizontal tokens={stackTokens} styles={stackStyles}>
            <Stack {...columnProps}>
              {generatePORDetailControls()}
              {showOnSave ? <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop:5}}>Please do some changes and save.</span>:undefined}
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <PrimaryButton text="Save" disabled= { disableSaveButton() || isDisabledSaveButton} onClick={onSave} />
                <DefaultButton text="Cancel" onClick={onCancel} />
              </Stack>
            </Stack>
          </Stack>
            : <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
              <CoherenceLoading primaryText={""}></CoherenceLoading>
            </div>}
        </Card>
      </div>
      <div 
        style={{
          height: '252px',
        }}
      >
        <Accordion title="Attachments" isExpandDefault={AttachmentsIsExpandDefault} onAccordianChange={onAccordianChange}>
          <Attachments onSaveAttachments={onSaveAttachments} ServiceLineError={selectServiceLineErrorMsg} attachmentDescriptionList={isEditMode? [...MetaDataList.filter((x: any) => x.ServiceLine == porDetails.ServiceLine && x.MetadataType == "AttachmentDescription"),
        {
          key: "other",
          text: "Other",
        }] : attachmentDescriptionList} Reset = {valueReset}></Attachments>
        </Accordion>
       
        <Accordion title="Contacts" isExpandDefault={ContactsIsExpandDefault}  onAccordianChange={onAccordianChange}>
          <Contacts  onSaveContacts={onSaveContacts} ServiceLineError={selectServiceLineErrorMsg} contactTypeList ={isEditMode? MetaDataList.filter((x: any) => x.ServiceLine == porDetails.ServiceLine && x.MetadataType == "ContactType") :contactTypeList} Reset = {valueReset}></Contacts>
        </Accordion>

        <Accordion title="Milestones" isExpandDefault={MilestonesIsExpandDefault}  onAccordianChange={onAccordianChange}>
          <Milestones onSaveMilestone={onSaveMilestone} ServiceLineError={selectServiceLineErrorMsg} MilestonesTypeList={isEditMode? MetaDataList.filter((x: any) => x.ServiceLine == porDetails.ServiceLine && x.MetadataType == "MilestoneType") : MilestonesTypeList} Reset = {valueReset}></Milestones>
        </Accordion>

        <Accordion title="References" isExpandDefault={ReferencesIsExpandDefault}  onAccordianChange={onAccordianChange}>
          <References onSaveReferenceDetails={onSaveReferenceDetails} ServiceLineError={selectServiceLineErrorMsg} referenceDetailList={isEditMode? MetaDataList.filter((x: any) => x.ServiceLine == porDetails.ServiceLine && x.MetadataType == "ReferenceType"): referenceDetailList} Reset = {valueReset}></References>
        </Accordion>

        {isEditMode ? <Accordion title="Issues" isExpandDefault={IssuesIsExpandDefault}  onAccordianChange={onAccordianChange}>
          <Issues onSaveIssues={onSaveIssueDetails} issueResolutionList={MetaDataList.filter((x:any) => x.ServiceLine == porDetails.ServiceLine && x.MetadataType == "IssueResolution")} ></Issues>
          </Accordion> : <span></span>}
       
       {isEditMode ? <Accordion title="Discussions" isExpandDefault={DiscussionsIsExpandDefault}  onAccordianChange={onAccordianChange}>
          <Discussions></Discussions>
        </Accordion> : <span></span>}     											
				  
      </div>		  
    </div>
  </div>
}
  </div>
};
export default PORDetails;
