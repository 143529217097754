import React, { useState, useEffect } from "react";
import { DOMEApiHelper, isEmpty, onFormatDate } from "../../../../../utils/DOMEApiHelper";
import { Stack, TextField, IStackStyles, IStackProps, DatePicker, Breadcrumb, Dropdown, PrimaryButton, DefaultButton, IDropdownOption, Label, Callout, Link, DetailsListLayoutMode, CommandBar, ICommandBarItemProps, Checkbox, IconButton } from "@fluentui/react";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize} from "@coherence-design-system/controls";
import { useLocation } from "react-router";
import { GetUserRole } from "../../../../../utils/GetUserRole";
import { PrimaryContactPicker } from "../../../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import ToastMessage from "../../../../../shared/ToastMessage/ToastMessage"; 
import { Card, CardStandardHeader } from "@coherence-design-system/card";
interface prop {
  onSaveContacts: any;
  contactTypeList: any;
  ServiceLineError:boolean;
  Reset: boolean;
}

export const Contacts = (props: prop) => {
  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { whiteSpace: 'nowrap' } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation();
  const [userRole, setUserRole] = useState(GetUserRole());
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [contactTypeList, setContactTypeList] = useState<any>([]);
  const defaultUserName = sessionStorage.getItem("localAccountName");
  const defaultUserID = sessionStorage.getItem("localAccountId");
  const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
  const [primaryContactRequired, setPrimaryContactRequired] = React.useState<boolean>(true);
  const [selectedContactType, setSelectedContactType] = React.useState<IDropdownOption>();
  const [contactRecords, setContactRecords] = useState<{ Id: string | null; ContactTypeId: string; itemSelected: boolean; ContactType: string; ContactGUID: string | null; ContactUserName: string | null }[]>([]);
  const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
  const [showAddGridPanel, setShowAddGridPanel] = useState<boolean>(false);
  const [isEditItem, setIsEditItem] = useState<boolean>(false);
  const [showEditGridPanel, setShowEditGridPanel] = useState<boolean>(false);
  const [pORId, setPORId] = useState<any>();
  const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
  const [assignToErr, setAssignToErr] = React.useState<boolean>(false);
  const [ContactAssignedTo, setContactAssignedTo] = useState<any>([]);
  const [originalContactAssignedTo, setOriginalContactAssignedTo] = useState<any>([]);
  const [ContactAssignedToRequired, setContactAssignedToRequired] = React.useState<boolean>(false);
  const [showOnSave, setShowOnSave] = React.useState<boolean>(false);
  const [isContactsTypeInActive, setIsContactsTypeInActive] =useState<any>(false); 

  const onChangeContactType = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: any): void => {
    setSelectedContactType(item);
  };

  const updateContact = (editedData: any,reqFrom:any) => {
    const requestUpdateOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editedData),
      mode: 'cors'
    }; 
    setToastTitle(reqFrom==="Add"?"Add Contact":"Update Contact");
    setToastMessage(reqFrom==="Add"?"Adding Contact is in progress":"Updating Contact is in progress");
    setToastType(CoherenceNotificationType.PENDING);
    setShowToast(true);
    DOMEApiHelper('PORDetails/UpdateContactDetails', requestUpdateOptions)
      .then(res => {
        setToastType(CoherenceNotificationType.SUCCESS);
        setToastMessage(reqFrom==="Add"?"Contact has been added successfully.":"Contact has been updated successfully."); 
        setTimeout(() => {
          setShowToast(false);
        }, 3000);

        if (res.Message === "Success") {
          LoadContactData(pORId);
        }
      }).catch(res => {
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  }

  const handleEditContact = () => {
    setOnSaveDataValid(true);
    if (ContactAssignedTo[0].objectId === originalContactAssignedTo[0].objectId) {

      setShowOnSave(true);
      return false;
    }
    else {
      if (isEditMode) {
        // setOnSaveDataValid(true);

        if (!isDataValid()) {
          return false;
        }

        let editedData = {
          PORId: parseInt(pORId),
          Id: editColumnsDefaultValues.Id,
          ContactType: editColumnsDefaultValues.ContactTypeId,
          ContactGUID: ContactAssignedTo[0].objectId,
          ModifiedBy: sessionStorage.getItem("localAccountId"),
        };

        updateContact(editedData,"Update"); // API Call         
        setShowEditGridPanel(false);
      }
      else {
        // in create POR Edit button
        //update AssingedTo to contactRecords
        contactRecords.forEach((x: any) => {
          if (x.ContactTypeId === editColumnsDefaultValues.ContactTypeId) {
            x.ContactGUID = ContactAssignedTo[0].objectId;
            x.ContactUserName = ContactAssignedTo[0].text;
          }
        })
        setContactRecords([...contactRecords]);
        setShowEditGridPanel(false);

      }

    }

    setShowAddGridPanel(false);
    setOnSaveDataValid(false);
    setContactAssignedToRequired(false);
    setAssignToErr(false);
    setContactAssignedTo([]);
    setSelectedContactType(undefined);
    setOriginalContactAssignedTo([]);
    setShowOnSave(false);

  }
  const [editRowColumns, seteditRowColumns] = useState<any>(
    [
      { "isEditable": true, "type": "ReadOnly", "label": "Contact Type", "key": "ContactType" },
      { "isEditable": true, "type": "Contact", "label": "Contact Name", "key": "ContactName" },
    ]
  );
  const disableSaveButton = (): boolean => { 
    let isInValid=false;
    if (isEmpty(ContactAssignedTo) || isEmpty(selectedContactType) || isEmpty(ContactAssignedTo[0]?.objectId)) {
      isInValid = true;
    }
    if(isEmpty(ContactAssignedTo) || isEmpty(selectedContactType)){
      isInValid=true;
    } 
    else if(showEditGridPanel && ContactAssignedTo.length>0 && ContactAssignedTo[0].objectId == originalContactAssignedTo[0].objectId){
      isInValid=true;   
    }
    else if(!showEditGridPanel && (isEmpty(ContactAssignedTo) || (isEmpty(selectedContactType)))){
      isInValid=true;
     }
     //if ContactAssignedTo and SelectedContentType are already in the contactRecords then isinvalid is true
    else if(contactRecords.some((item: any) => item.ContactGUID === ContactAssignedTo[0].objectId && item.ContactTypeId == selectedContactType?.key)){
     isInValid=true; 
    } 
    return isInValid;
} 

  const generateEditControls = (): any => {
    let tmpRenderObj: any[] = [];
    // eslint-disable-next-line
    for (let editRowColumn of editRowColumns) {
      switch (editRowColumn.type) {
        case 'ReadOnly':
          tmpRenderObj.push(
            <><Label key={editColumnsDefaultValues[editRowColumn.key]}>{editRowColumn.label}</Label> <Label className="readonyLabel" disabled>{editColumnsDefaultValues[editRowColumn.key]}</Label></>                      
          );
          break;
        case 'Contact':
          tmpRenderObj.push(
            <span>
              <PrimaryContactPicker label={"Contact Name"} defaultPrimaryContact={ContactAssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={ContactAssignedToRequired}></PrimaryContactPicker>
            </span>
          );
          break;
        default:
      }
    }
  
    
    tmpRenderObj.push(
      <>
        <br></br>
        <PrimaryButton disabled={disableSaveButton() || isContactsTypeInActive}
          onClick={handleEditContact}>Save Contact</PrimaryButton>
      </>
    );

    return tmpRenderObj;
  }


  const isDataValid = () => {
    // for Adding a contact
    if ((!isEditItem) && (isEmpty(ContactAssignedTo) || isEmpty(selectedContactType))) {
      return false;
    }
    // edit contact
    if ((isEditItem) && isEmpty(ContactAssignedTo)) {
      return false;
    } 
    return true;
  };
  const handleAddContact = () => {
    setOnSaveDataValid(true);
    if (!isDataValid()) {
      return false;
    }  

    const newContactRecord = selectedContactType ? { Id: null, ContactTypeId: selectedContactType.key.toString(), itemSelected: false, ContactType: selectedContactType.text, ContactGUID: ContactAssignedTo[0].objectId, ContactUserName: ContactAssignedTo[0].text } : null;

    if (newContactRecord) {
      setContactRecords([...contactRecords, newContactRecord]);
    }

    if (isEditMode && pORId > 0) {
      let editedData = {
        PORId: parseInt(pORId),
        ContactType: newContactRecord ? parseInt(newContactRecord.ContactTypeId) : null,
        ContactGUID: newContactRecord ? newContactRecord.ContactGUID : null,
        CreatedBy: sessionStorage.getItem("localAccountId"),
      };
      updateContact(editedData,"Add"); // Api Call
      setShowEditGridPanel(false);
    }else{
       props.onSaveContacts([...contactRecords, newContactRecord]);
    }
    setShowAddGridPanel(false);
    setOnSaveDataValid(false);
    setSelectedContactType(undefined);
  };


  const handleOnPanelXClose = () => {
    setShowAddGridPanel(false);
    setShowEditGridPanel(false);
    setOnSaveDataValid(false);
    setPrimaryContactRequired(true);
    setContactAssignedToRequired(false);
    setAssignToErr(false);
    setContactAssignedTo([]);
    setSelectedContactType(undefined);
    setShowOnSave(false);
    setOriginalContactAssignedTo([]);
  }

  //  All check box
  const handleSelectAllCheckbox = (event: any) => {
    setIsSelectedAll(event.target.checked);
    contactRecords.forEach((x: any) => {
      if (x.ContactType !== "DOME Primary Contact"){
      x.itemSelected = event.target.checked;
      }
    })
    setContactRecords([...contactRecords]);
    const updateBulkUpdateData = event.target.checked ? contactRecords.filter((x: any) => x.itemSelected === true) : [];
    setBulkEditSelectedData([...updateBulkUpdateData])
  };

  // Single Selection Box
  const handleSelectionCheckbox = (event: any, item: any) => {
    if (event.target.checked) {
      item.itemSelected = true;
      setBulkEditSelectedData([...bulkEditSelectedData, item]);
    } else {
      if (isSelectedAll)
        setIsSelectedAll(false);
      item.itemSelected = false;
      setBulkEditSelectedData(
        bulkEditSelectedData.filter((selecteditem: any) => selecteditem.ContactTypeId !== item.ContactTypeId && selecteditem.ContactGUID !=item.ContactTypeId)
      );
    }
    contactRecords.forEach((element: any) => {
      if (element.ContactTypeId === item.ContactTypeId && element.ContactGUID ===element.ContactTypeId) {
        element.itemSelected = item.itemSelected
      }
    });
    setContactRecords([...contactRecords]);
  };

  //ContactAssignedTo
  const onSelectContact = (contact: any) => {
    if (!isEmpty(contact) && !isEmpty(contact.objectId)) {
      setAssignToErr(false);
      let editedcontact = [{
        text: contact.text,
        objectId: contact.objectId
      }];
      setContactAssignedTo(editedcontact);
    } else {
      setAssignToErr(true);
      setContactAssignedTo([]);
    }
    
   
  }


  const generateContactButton = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Add',
        text: 'Add',
        iconProps: { iconName: 'Add' },
        onClick: () => {
          setIsContactsTypeInActive(false);
          setIsEditItem(false);
          setShowAddGridPanel(true);

          setAssignToErr(false);
          setContactAssignedToRequired(true);
          let value = [{ "text": defaultUserName, "objectId": defaultUserID }];
          setContactAssignedTo(value); 
        }
      },
      {
        key: 'ActionsDelete',
        name: 'Delete',
        type: 'actions',
        iconProps: {
          iconName: 'Delete',
          style: { color: "red" }
        },
        isResizable: false,
        menuActions: [],
        minColumnWidth: 55,
        maxWidth: 55,
        data: [],
        onClick: () => {

          const result = window.confirm('Are you sure you want to delete the selected contact(s)?');

          if (result) {
            // Edit POR Delete
            if (pORId > 0 && isEditMode) {
              let todeleterecords = bulkEditSelectedData.filter((item: any) => item.itemSelected === true).map((item: any) => item.Id);

              const requestUpdateOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(todeleterecords),
                mode: 'cors'
              };
              setToastTitle("Delete contacs(s)");
              setToastMessage("Deleting the selected contact(s)");
              setToastType(CoherenceNotificationType.PENDING);
              setShowToast(true);
              DOMEApiHelper('PORDetails/DeleteContactDetails', requestUpdateOptions)
                .then(res => {
                  setIsSelectedAll(false);
                  setToastType(CoherenceNotificationType.SUCCESS);
                  setToastMessage("Contact(s) have been successfully deleted.");
                  setTimeout(() => {
                    setShowToast(false);
                  }, 3000);

                  if (res.Message === "Success") {
                    LoadContactData(pORId);

                    //pushing the mutilpe deleted records to the contactTypeList
                    bulkEditSelectedData.filter((item: any) => item.itemSelected === true).map((item: any) => ({ key: item.ContactTypeId, text: item.ContactType })).forEach((x: any) => {
                      if (!contactTypeList.some((item: any) => item.key === x.key)) {
                        contactTypeList.push(x);
                      }
                    })

                    setIsSelectedAll(false);

                  }

                  setBulkEditSelectedData([]);
                }).catch(res => {
                  setToastMessage(res.toString());
                  setToastType(CoherenceNotificationType.ERROR);
                  setTimeout(() => {
                    setShowToast(false);
                  }, 3000);
                });
            }
            else {

              // Create POR Delete

              let notremoveDeletedContacts = contactRecords.filter((item: any) => item.itemSelected === false);
              setContactRecords(notremoveDeletedContacts);

              //pushing the mutilpe deleted records to the contactTypeList
              bulkEditSelectedData.filter((item: any) => item.itemSelected === true).map((item: any) => ({ key: item.ContactTypeId, text: item.ContactType })).forEach((x: any) => {
                if (!contactTypeList.some((item: any) => item.key === x.key)) {
                  contactTypeList.push(x);
                }
              })

              setIsSelectedAll(false);
              setBulkEditSelectedData([]);

            }
          } // Alert box 

        },
        
        disabled: (bulkEditSelectedData.some((item: any) => item.ContactType === "DOME Primary Contact") || bulkEditSelectedData.length <= 0) 
      }
    ];
    return _items;
  }
 
  const getGridColumns = (): CoherenceDataGridColumn<any>[] => {

    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'selectAll',
        name: 'Select All',
        fieldName: 'SelectAll',
        type: 'string',
        isResizable: false,
        minColumnWidth: 20,
        maxWidth: 20,
        ariaLabel: 'Select All',
        data: [],
        onRender: (item: any) => {
          return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} checked={item.itemSelected} disabled={item.ContactType === "DOME Primary Contact"} />;
        },
        onRenderHeader: (item: any) => {

          return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event)} checked={isSelectedAll} />;
        },
      },
      {
        key: 'Actions',
        name: 'Edit',
        type: 'string',
        isResizable: false, 
        minColumnWidth: 40,
        maxWidth: 40,
        data: [],     
        onRender: (item: any) => { 
          function onClickGridAction(item: any) {
            let isRefActive=props.contactTypeList.filter((x: any) => x.key === parseInt(item.ContactTypeId));
            if(isRefActive.length==0){
              setIsContactsTypeInActive(true);  
            }
            else{
              setIsContactsTypeInActive(false);  
            }

            seteditColumnsDefaultValues(item);
            setIsEditItem(true);
            setAssignToErr(false);
            setContactAssignedToRequired(true);
            let value = item.ContactAssignedTo !== "" ? [{ "text": item.ContactUserName, "objectId": item.ContactGUID }] : []
            setContactAssignedTo(value);
            setSelectedContactType({ key: item.ContactTypeId, text: item.ContactType });
            setOriginalContactAssignedTo(value);
            setShowEditGridPanel(true);  
          }
          return <IconButton aria-label={`Edit ${item.ContactType}`} onClick={() => {onClickGridAction(item)}}  disabled={item.ContactType === "DOME Primary Contact"?true:false}  style={{cursor:item.ContactType === "DOME Primary Contact"?'not-allowed':'pointer', paddingBottom: '8px'}} 
          iconProps={{ iconName: 'Edit'}}
      />  
        }    
      },
      {
        key: 'ContactType',
        name: 'Contact Type',
        fieldName: 'ContactType',
        type: 'string',
        isResizable: true,
        ariaLabel: 'Contact Type',
        minColumnWidth: 150,
        maxWidth: 150,
        data: [],
        onRender: (item) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.ContactType}</span>;
        },
      },
      {
        key: 'ContactName',
        name: 'Contact Name',
        fieldName: 'ContactName',
        type: 'string',
        isResizable: true,
        ariaLabel: 'Contact Name ',
        minColumnWidth: 180,
        maxWidth: 180,
        data: [],
        onRender: (item) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.ContactUserName}</span>;
        },
      }
    ]; 
    return advancedColumns;
  };
  const generateAddControls = (): any => {
    let tmpRenderObj: any[] = [];
    tmpRenderObj.push(
      <>
        <Dropdown
          label={'Contact Type'}
          options={contactTypeList}
          placeholder={"Please select Contact Type"}
          required={true}
          selectedKey={selectedContactType ? selectedContactType.key : undefined}
          errorMessage={''}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption<any> | undefined,
            index?: number | undefined
          ) => {
            onChangeContactType(event, option, index);

          }
          }
        /> 
      </>

    )

    if (ContactAssignedTo != undefined) {
      tmpRenderObj.push(
        <PrimaryContactPicker label={"Contact Name"} defaultPrimaryContact={ContactAssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={ContactAssignedToRequired}></PrimaryContactPicker>
      );
    }
    tmpRenderObj.push(
      <>
        <br></br> 
        <PrimaryButton disabled={disableSaveButton()}
          onClick={handleAddContact}>Add Contact</PrimaryButton>

      </>
    );
    return tmpRenderObj;

  }

  useEffect(() => { 
    const routerName = location.pathname.split('/')[1];
    if (routerName === 'CreatePOR') {
      setIsEditMode(false);
    } else if (routerName === 'PORDetails') {
      const idStartIndex = window.location.href.lastIndexOf('/');
      const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
      if (id) {
        LoadContactData(id);
        setIsEditMode(true);
        setPORId(parseInt(id));

      }
    }
  }, [location]);

  useEffect(() => {
   //load contactTypeList from props
   if (props.contactTypeList.length > 0) {
      //remove Title="PECO Primary Contact" from contactTypeList records
      let contactTypeListTemp = props.contactTypeList.filter((item: any) => item.text !== "DOME Primary Contact");
      setContactTypeList(contactTypeListTemp);
    }
  }, [props.contactTypeList]);

  useEffect(() => {
    // To fetch records to Pordetails page  for Confidnetial validation check
    props.onSaveContacts(contactRecords);
  }, [contactRecords]);

  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    if (routerName==='CreatePOR' && props.Reset) {
      setContactRecords([]);
    }
  }, [props.Reset]);

  const LoadContactData = (PORID: string) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetContactDetailsByPORId?PORId=' + PORID, requestOptions)
      .then(res => {
        setIsLoaded(false);
        setContactRecords([
          ...res.map((item: any) => ({
            Id: item.Id,
            ContactTypeId: item.ContactType,
            itemSelected: false,
            ContactGUID: item.ContactGUID,
            ContactUserName: item.ContactGUIDUserName,
            ContactType: item.ContactTypeName

          }))
        ]);


      }).catch(res => {
        setIsLoaded(false);
      });
  }
  return <div>
    {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <CommandBar
        items={[]}
        farItems={generateContactButton()}
        ariaLabel="Add Contact"
        primaryGroupAriaLabel="Add Contact"
        farItemsGroupAriaLabel="Add Contact"
        style={{ display: 'flex', justifyContent: 'start' }}
      />
    </div>
    <div style={{ minHeight: 255, height: 255, maxHeight: 255, overflowX: 'auto' }}>
      {(!isLoaded) ? <CoherenceDataGrid
        listConfig={getGridColumns()}
        data={contactRecords}
        ariaLabelForSelectAllCheckbox={'select all checkbox'}
        isScrollable={true}
        isSortable={false}
        sortByDefault={false}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        ariaLabel={"Contact List"}
      ></CoherenceDataGrid>
        : <CoherenceLoading primaryText={""}></CoherenceLoading>}
    </div>

    <CoherencePanel
      title={"Contact"}
      titleText={"Contact"}
      isOpen={showAddGridPanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.small}
      closeButtonAriaLabel="Close"
    >
      {props.ServiceLineError ?<><svg width="16" height="20" viewBox="0 -9 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.0001 1.99805C17.5238 1.99805 22.0016 6.47589 22.0016 11.9996C22.0016 17.5233 17.5238 22.0011 12.0001 22.0011C6.47638 22.0011 1.99854 17.5233 1.99854 11.9996C1.99854 6.47589 6.47638 1.99805 12.0001 1.99805ZM11.9963 10.2486C11.4834 10.249 11.061 10.6353 11.0036 11.1327L10.9969 11.2493L11.0005 16.7509L11.0073 16.8675C11.0654 17.3648 11.4884 17.7506 12.0012 17.7502C12.514 17.7499 12.9364 17.3636 12.9939 16.8662L13.0005 16.7496L12.9969 11.248L12.9901 11.1314C12.932 10.6341 12.5091 10.2483 11.9963 10.2486ZM12.0005 6.49908C11.3093 6.49908 10.749 7.0594 10.749 7.7506C10.749 8.4418 11.3093 9.00212 12.0005 9.00212C12.6917 9.00212 13.2521 8.4418 13.2521 7.7506C13.2521 7.0594 12.6917 6.49908 12.0005 6.49908Z" fill="#212121" />
</svg> <span style={{color:"#a4262c"}}>A service line selection is required to proceed.</span> </> : null }
      {showAddGridPanel && generateAddControls()}
    </CoherencePanel>

    <CoherencePanel
      title={"Edit Contact"}
      titleText={"Edit Contact"}
      isOpen={showEditGridPanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.small}
      closeButtonAriaLabel="Close"
    >
      {showEditGridPanel && generateEditControls()}
    </CoherencePanel>
  </div>

};
export default Contacts;


