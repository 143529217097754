import * as React from 'react'; 
import { 
  IBasePickerSuggestionsProps, 
  NormalPeoplePicker, 
  ValidationState,
  PeoplePickerItemSuggestion,
} from '@fluentui/react/lib/Pickers'; 
import { DOMEApiHelper } from '../../utils/DOMEApiHelper';
import { IPersonaProps, Label, Persona, PersonaSize } from '@fluentui/react';  
 
interface prop {
  onSelectPrimaryContact: any;
  defaultPrimaryContact:IPersonaProps[];
  onSaveDataValid:any;
  label: any;
  required: any;
  EnableGroupSearch?:boolean;
}

export const PrimaryContactPicker  = (props: prop) => {  

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText:props.EnableGroupSearch? 'Suggested Groups/Users' : 'Suggested Users',
  mostRecentlyUsedHeaderText: props.EnableGroupSearch? 'Suggested Groups/Users' : 'Suggested Users',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText:props.EnableGroupSearch? 'Users/Groups Suggestions available': 'Users Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested Users',
};
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);
  const picker = React.useRef(null);  
  const [defaultPrimaryContact, setDefaultPrimaryContact] = React.useState<IPersonaProps[]>([]);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);
  const [showRequiredMesssage, setShowRequiredMesssage] = React.useState<boolean>(false); 
  React.useEffect(() => {  
      setDefaultPrimaryContact(props.defaultPrimaryContact);  
      setShowPicker(true);  
  }, [props.defaultPrimaryContact]);


  React.useEffect(() => {  
      setShowRequiredMesssage(props.defaultPrimaryContact.length==0?true:false);
}, [props.onSaveDataValid]);

  const getPersonMail = async (personName: any): Promise<IPersonaProps[]> => { 
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({SearchValue:personName,"EnableGroupSearch":props.EnableGroupSearch==true?true:false}),
    mode: 'cors'
}
  
    return DOMEApiHelper('PORDetails/GetUsersByDisplayNameOrMailId', requestOptions)
      .then(res => { 
        const filteredPersonas: IPersonaProps[] = res.map((item: any) => {
          return {
            text: item.DisplayName + (item.Mail ? ' (' + item.Mail + ')' : ''),
            secondaryText: item.UserPrincipalName,
            objectId:item.Id,
            emailId: item.Mail?item.Mail:'',
            DisplayName: item.DisplayName
          };
        });
        return filteredPersonas;
      });
  };
 
  const handlePeoplePickerChange = (items: any) => {  
    if(items.length>0){ 
      props.onSelectPrimaryContact(items[0]);
      if(showRequiredMesssage){
        setShowRequiredMesssage(false); 
      }
    } 
    else{
      props.onSelectPrimaryContact();
      if(!showRequiredMesssage){
        setShowRequiredMesssage(true); 
      }
    } 
  };
 
  const onFilter = async (filterText: string, selectedItems?: IPersonaProps[] | undefined): Promise<IPersonaProps[]> => {
    try {
      var data ;
      if (filterText) { 
         data = await getPersonMail(filterText); 
         setPeopleList([...data]);
         return data; 
            }           
    else{
      return [];
    }
    } catch (error) {
     // console.error(error);
      return [];
    } 
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  }; 

  const generatePickerControl = (): any => {
    let tmpRenderObj: any[] = [];
    tmpRenderObj.push(
<NormalPeoplePicker  
              itemLimit={1}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={suggestionProps}
              className={'ms-PeoplePicker'}
              key={'normal'} 
              onRemoveSuggestion={onRemoveSuggestion} 
              onValidateInput={validateInput}
              selectionAriaLabel={'Please search by user Email Id, Display Name or Alias Name'} 
              inputProps={{ 
                 'aria-label': 'Primary Contact',
                  placeholder:"Please search by user Email Id, Display Name or Alias Name"
              }}
              componentRef={picker} 
              resolveDelay={300} 
              onResolveSuggestions={onFilter} 
              onChange={handlePeoplePickerChange}
              defaultSelectedItems={defaultPrimaryContact}  
              pickerCalloutProps={{ calloutWidth: 500}}
              onRenderSuggestionsItem={(persona: IPersonaProps) => {
                return (
                  <div>
                    <Persona
                      primaryText={persona.text}
                      // secondaryText={persona.secondaryText}
                      // tertiaryText={persona.tertiaryText}
                      // imageUrl={persona.imageUrl}
                      size={PersonaSize.size32}
                    />
                  </div>
                );
              }}
              />  
              )
              return tmpRenderObj;
            }

  return (
    <div>
       {showPicker && <Label>{ props.label !== null ? props.label : "Primary Contact"}<span style={{paddingLeft:4, color:'#b74c41'}}>{props.required ? '*' : ''}</span></Label>}
       {showPicker &&  generatePickerControl()}     
       {/* {showRequiredMesssage && props.required && <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5}}>Please select {props.label}.</span>}          */}
    </div>
  );
};

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
} 
