import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading,  CoherenceNotificationType, CoherencePanel, CoherencePanelSize, panelPadding } from "@coherence-design-system/controls";
import { Checkbox, DatePicker, DefaultButton, DetailsListLayoutMode, Dropdown, FontIcon, ICommandBarItemProps, IDropdownOption, Label, Link, Pivot, PivotItem, PrimaryButton,  Stack, TextField, TooltipHost, Callout, styled, ActivityItem, IActivityItemProps, mergeStyleSets, IStackStyles, IStackProps } from "@fluentui/react";
import dayjs from "dayjs"; 
import React, { useEffect,  useState } from "react"; 
import { DOMEApiHelper, convertToCsv, isEmpty, onFormatDate } from "../../../utils/DOMEApiHelper";
import { GetUserRole } from "../../../utils/GetUserRole" 
import ToastMessage from "../../../shared/ToastMessage/ToastMessage";
import  UtilDta from "../../../utils/UtilCommon.json";
import { NotificationPivotOptions } from "../../../shared/AppHeader/AppHeader.utils";
import CustomPagination from "../../../shared/CutomPagination/CustomPagination";
import CustomGridControls from "../../../shared/CustomGridControls/CustomGridControls";
import { SearchableDropdown } from "../../../shared/CustomSearchDropdown/CustomSearchDropdown";
import { RMApiHelper } from "../../../utils/RMApiHelper";
import { Icon } from '@fluentui/react/lib/Icon';
import { PrimaryContactPicker } from "../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { redirect } from "react-router";
import NonManagedDiscussions from "../NonManagedDiscussions/NonManagedDiscussions"; 

const DataMonitoringIssues: React.FunctionComponent = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isLoaded, setIsLoaded] = useState(false);
  const [LoadText, setLoadText] = useState<string>("");
  const [dataMonitoringIssueData, setDataMonitoringIssueData] = useState<any>([]); 
  const [FuncScenario, setFuncScenario] = useState<any>([]);
  const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
  const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [refColumnsDefaultValues, setRefColumnsDefaultValues] = useState<any>(null);
  const [Resolution, setResolution] = useState<any>([]);
  const [ActionRequired, setActionRequired] = useState<any>([]);
  const [DataMismatchAttributesTitle, setDataMismatchAttributesTitle] = useState<any>(); 
  const [ResolutionErrMsg, setResolutionErrMsg] = useState<string>("");
  const [FuctionalSceErrMsg, setFuctionalSceErrMsg] = useState<string>("");
  const [InProgressStatus, setInProgressStatus] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
  const [FunctionalScenariosList, setFunctionalScenariosList] = useState<any>([]);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [upcomingReleases, setUpcomingRelease] = useState<any>([]);
  const MyIcon = () => <Icon iconName="Info" />;
  const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [assignToErr, setAssignToErr] = React.useState<boolean>(false);
  const [AssignedToRequired, setAssignedToRequired] = React.useState<boolean>(false);
  
 
  const [AssignedTo, setAssignedTo] = useState<any>(  []); 
  
    //toaster code Start
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false); 
     //toaster code end

    
      const onCalloutClick = () => {
        if(isCalloutVisible === true)
          setIsCalloutVisible(false);
        else 
        setIsCalloutVisible(true);
      };

  const handleValueChange = (newValue: boolean | ((prevState: boolean) => boolean)) => {
    setshowEditGridPanel(newValue);
  };

  let IdVal: number;

  const onEditPanelChange = (item: any): void => {
    
    for (let key in editColumnsDefaultValues) {     
        if (key === "Id")
        {
            IdVal = editColumnsDefaultValues[key];
        }      
    }
      SaveAPI(IdVal);
  };
  // eslint-disable-next-line
  const [editRowColumns, seteditRowColumns] = useState<any>(
    [
      { "isEditable": true, "type": "ReadOnly", "label": "Data Type", "key": "DataType" },
      { "isEditable": true, "type": "ReadOnly", "label": "Data Value", "key": "DataValue" },
      { "isEditable": true, "type": "Recommendation", "label": "ML Recommendation", "key": "MLRecommendation" },
       { "isEditable": true, "type": "Contact", "label": "AssignedTo", "key": "AssignedTo" },
      { "isEditable": true, "type": "DropDown", "label": "Resolution", "key": "Resolution"},
      { "isEditable": true, "type": "DropDown", "label": "Functional Scenario", "key": "FunctionalScenario" },
      { "isEditable": true, "type": "Date", "label": "Target Completion Date", "key": "TargetCompletionDate" },
      { "isEditable": true, "type": "ReadOnly", "label": "Comments", "key": "Comments" }     
    ]
  );

  // eslint-disable-next-line 
  const hideModal = () => {
    setmodalOpen(false);
  }; 

  const openModal = (item: any) => { 
    getPopUpdata(item);
  }; 

   const actionInProgress = UtilDta.filter((data: any) => data.id.includes("InProgress"));
   const ActionInProgressKeyId = ActionRequired.filter((data: any) => data.text.includes(actionInProgress[0].key));
  const getColumns = (): CoherenceDataGridColumn<any>[] => {

    var advancedColumns: CoherenceDataGridColumn<any>[] = [
        {
          key: 'selectAll',
          name: 'Select All',
          fieldName: 'SelectAll',
          type: 'string',
          isResizable: false,
          minColumnWidth: 20,
          maxWidth: 20,
          ariaLabel: 'Select All',
          data: [],
          onRender: (item: any) => {
              return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} disabled={item.IsLocked || item.ActionRequired === ActionInProgressKeyId[0].key || item.Resolution === "System Resolved"} checked={item.isSelected} />;
          },
          onRenderHeader: (item: any) => {
              return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event, item)} checked={isSelectedAll} />;
          },
        },
      {
        key: 'Actions',
        name: 'Edit',
        type: 'actions',
        menuActions: [],
        isResizable: false,
        minColumnWidth: 50,
        maxWidth:50,
        data: [],
        visibleActions: [
          {
            onClick: (item) => {
              fetchFunctionalScenarioByAffectedDataTypeId(item.DataType, item.AffectedDataTypeId)
              setBulkEditButtonClicked(false);
              setAssignToErr(false);
              setIsDisabledSaveButton(true);
              setAssignedToRequired(true);
              let value:any =[];
              if(!isEmpty(item.AssignedTo) && !isEmpty(item.AssignedToUserName)){
                  value=[{ "text": item.AssignedToUserName, "objectId": item.AssignedTo }];
              }
              else if(!isEmpty(item.AssignedTo) && isEmpty(item.AssignedToUserName)){ 
                  value=[{ "text": item.AssignedTo, "objectId": item.AssignedTo }];
              }
              setAssignedTo(value);
              if(item.ActionRequiredName === 'In Progress')
                setInProgressStatus(true);
                else
                setInProgressStatus(false);
              item.isEditMode = 'true';
              seteditColumnsDefaultValues(item); 
              setResolutionErrMsg("");
              setFuctionalSceErrMsg("");
              setshowEditGridPanel(true); 
            },
            key: 'edit',
            text: 'Action',
            ariaLabel: 'action',
            iconProps: { iconName: 'Edit', style: { color: "#0078d4" } }
          }
        ]
      },
      {
        key: 'DataType',
        name: 'Data Type',
        fieldName: 'DataType',
        type: 'string',
        isResizable: true,
        minColumnWidth: 90,
        maxWidth:90,
        ariaLabel: 'Data Type', 
        data: [],
        onRender: (item: any) => {
          if (item.IsLocked === true) {
              return <TooltipHost content={item.DataType}>
                  <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)', marginLeft: '-14px'}}>
                      <FontIcon iconName="LockSolid" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500}}></FontIcon>
                      {item.DataType}
                  </span>
              </TooltipHost>
          }
          else {
              return <TooltipHost content={item.DataType}>
                  <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)'}}>
                      {item.DataType}
                  </span>
              </TooltipHost>
          }
      },
        iconName:appendSortIcon('DataType'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'DataValue',
        name: 'Data Value',
        fieldName: 'DataValue',
        type: 'link',
        url: 'url',
        isResizable: true,
        minColumnWidth: 110,
        maxWidth:110,
        ariaLabel: 'Data Value',
        data: [],
        onRender: (item: any) => {
          return <Link onClick={(event) => { openModal(item) }} >
            {item.DataValue}
          </Link>
        },
        iconName:appendSortIcon('DataValue'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'DisplayName',
        name: 'Display Name',
        fieldName: 'DisplayName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth:200,
        ariaLabel: 'Display Name',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.DisplayName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.DisplayName}</span>
          </TooltipHost>
        },
        iconName:appendSortIcon('DisplayName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'FunctionalScenario',
        name: 'Functional Scenario',
        fieldName: 'FunctionalScenario',
        type: 'string',
        isResizable: true,
        minColumnWidth: 170,
        maxWidth:170,
        ariaLabel: 'Functional Scenario',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.FunctionalScenario}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.FunctionalScenario}</span>
          </TooltipHost>
        },
        iconName:appendSortIcon('FunctionalScenario'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'MonitoringIssue',
        name: 'Monitoring Issue',
        fieldName: 'MonitoringIssue',
        type: 'string',
        isResizable: true,
        minColumnWidth: 170,
        maxWidth:170,
        ariaLabel: 'Monitoring Issue',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.MonitoringIssue}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.MonitoringIssue}</span>
          </TooltipHost>
        }
      },
      {
        key: 'AssignedToUserName',
        name: 'Assigned To',
        fieldName: 'AssignedToUserName',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 220,
        maxWidth: 220,
        ariaLabel: 'Assigned To',
        imageUrl: 'imageUrl',
        personaAlt: (item) => `Profile image for ${item.fullName}`,
        data: [],
        iconName: appendSortIcon('AssignedToUserName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ActionRequired',
        name: 'Action Required',
        fieldName: 'ActionRequired',
        type: 'number',
        isResizable: true,
        minColumnWidth: 100,
        maxWidth:100,
        ariaLabel: 'Action Required',
        className: 'cellLeftAligned customGridCell',
        onRender: (item: any) => {
          switch (item.ActionRequiredName) {
            case UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key:
              {
              return <span>
                <FontIcon iconName="Recent" style={{ color: 'rgba(215, 59, 2, 1)', fontWeight: 500 }}></FontIcon>
                {' '+UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key}</span>
              }
            case UtilDta.filter((data:any) => data.id.includes("Resolved"))[0].key:
              {
              return <span>
                <FontIcon iconName="Completed" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                {' '+UtilDta.filter((data:any) => data.id.includes("Resolved"))[0].key}</span>
              }
            case UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key:
              {
              return <span>
                <FontIcon iconName="ProgressRingDots" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                {' '+UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key}</span>
              }
          }
        },
        data: [],
        iconName:appendSortIcon('ActionRequired'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ResolvedBy',
        name: 'Resolved By',
        fieldName: 'ResolvedName',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth:200,
        ariaLabel: 'Resolved By',
        imageUrl: 'imageUrl', 
        personaAlt: (item) => `Profile image for ${item.fullName}`,
        data: [],
        iconName:appendSortIcon('ResolvedName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'Resolution',
        name: 'Resolution',
        fieldName: 'Resolution',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth:120,
        ariaLabel: 'Resolution', 
        data: [],
        iconName:appendSortIcon('DataValue'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'TargetCompletionDate',
        name: 'Target Completion Date',
        fieldName: 'TargetCompletionDate',
        type: 'date',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth:120,
        ariaLabel:'Target Completion Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left',fontWeight:500 }}>
            {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
        },
        iconName:appendSortIcon('TargetCompletionDate'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ResolvedDate',
        name: 'Resolved Date',
        fieldName: 'ResolvedDate',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth:120,
        ariaLabel:'Resolved Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left',fontWeight:500 }}>
            {isEmpty(item.ResolvedDate)? '': dayjs(item.ResolvedDate).format('MM/DD/YYYY')}</span>
        },
        iconName:appendSortIcon('ResolvedDate'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'IssueType',
        name: 'Issue Type',
        fieldName: 'IssueType',
        type: 'string',
        isResizable: true,
        minColumnWidth: 95,
        maxWidth:95,
        ariaLabel: 'Issue Type',
        data: []
      }
    ]; 
     return advancedColumns;
  };

  const handleDateSelection = (date: any, _item: any) => {
    const sDate = new Date(date);
    const newEditColumnsDefaultValues = { ...editColumnsDefaultValues };
    let vsDate = dayjs(sDate).startOf("day").toDate();
    const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss");
    for (let key in newEditColumnsDefaultValues) {
        if (key === "TargetCompletionDate") { 
            newEditColumnsDefaultValues[key] = formattedDate; 
            break; 
        }
    }
    seteditColumnsDefaultValues(newEditColumnsDefaultValues);
  }

  const onChangeText = (e: any, text: any, _item: any) => {
    SetObjValues(_item.key, text.value);
  }


  //AssignedTo - Datamonitoringissues
  const onSelectContact = (contact: any) => {
    if(!isEmpty(contact) && !isEmpty(contact.objectId)){
      
      setAssignToErr(false);
      setIsDisabledSaveButton(false);
      let editedcontact = [{
        text: contact.text,
        objectId: contact.objectId  
      }]
      setAssignedTo(editedcontact)
    }
    else{
      setAssignedTo([]);
      setAssignToErr(true);
      setIsDisabledSaveButton(true);
    }
 }

  const requestOptions = {
    method: 'Get',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
  };
  const fetchFunctionalScenarioByAffectedDataTypeId = (datatype: any, affecteddatatypeid: any) =>{
    if ((FunctionalScenariosList.length >0 ? FunctionalScenariosList.filter((item: any) => item.DataType === datatype).length : 0 ) === 0){
    DOMEApiHelper('DataMonitoringIssues/GetAllFunctionalSCenarioById?affecteddatatypeid=' + affecteddatatypeid, requestOptions)
      .then(res => {
        let functionalScenariosList = res.map((item: any) => {
          return {
            DataType : datatype,
            key: item.Id,
            text: item.Title
          }
        })
        setFunctionalScenariosList([...FunctionalScenariosList, ...functionalScenariosList]);
        setFuncScenario(functionalScenariosList.filter((item: any) => item.DataType === datatype))
      }).catch(error => {
        // setIsLoaded(false); 
      });
    }
    else{
      setFuncScenario(FunctionalScenariosList.filter((item: any) => item.DataType === datatype))
    }
   
    


  }


  const GetUpcomingReleases =()=>
  {
    
    RMApiHelper('ReleaseCalendar/GetUpcomingReleases', requestOptions)
    .then((response) => {
      let releases = response.map((item: any) => {
        return {
          ReleaseId : item.ReleaseId,
          StartDate: item.StartDate.substring(0,10),
          EndDate: item.EndDate.substring(0,10)
        }
      })
      setUpcomingRelease(releases);
      
     }).catch(error => {
     
    });

  }

  const SetObjValues = (key: string, value: any, _isChanged: boolean = true, _errorMessage: string | null = null): void => {
    seteditColumnsDefaultValues({ ...editColumnsDefaultValues, [key]: value })
  }

  const onDropDownChange = (_event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
    setResolutionErrMsg("");
    setFuctionalSceErrMsg("");    
    if(bulkEditButtonClicked === true && bulkEditSelectedData.filter((item: any)=> item.DataType === bulkEditSelectedData[0].DataType).length < bulkEditSelectedData.length && selectedDropdownItem?.key === 'Configuration'){
      //Error msg for bulk edit when records with more than 1 datatype is selected
      //SetObjValues(item.key, null);
      if(selectedDropdownItem?.key === 'Configuration'){
        SetObjValues(item.key, undefined);
      }
      
      setToastTitle("Bulk edit Error");
      setToastMessage("Please select the records with same datatype to set resolution as configuration.");
      setToastType(CoherenceNotificationType.ERROR);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);  
        SetObjValues(item.key, null);
        }, 5000); 
                
    }
    else if(bulkEditButtonClicked === true && selectedDropdownItem?.key === 'Configuration'){
      //bulk edit for records with same datatype  
      fetchFunctionalScenarioByAffectedDataTypeId(bulkEditSelectedData[0].DataType, bulkEditSelectedData[0].AffectedDataTypeId);
      SetObjValues(item.key, selectedDropdownItem?.key);
    }
    else {
      SetObjValues(item.key, selectedDropdownItem?.key);
    }
    
  }

  const onDropdownChangeFun = (event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
    setFuctionalSceErrMsg("");    
    let FunctionalScenarioId: any;
    let FunctionalScenario: any;
    FuncScenario.forEach((_elemen: any, _index: any) => {
      if (_elemen.key === selectedDropdownItem?.key) {
        //setFunctionScrCtrlValid(selectedDropdownItem?.key);
        FunctionalScenarioId = _elemen.key;
        FunctionalScenario = _elemen.text;
      }
    });

    const newEditColumnsDefaultValues = { ...editColumnsDefaultValues };
    for (let key in newEditColumnsDefaultValues) {
        if (key === "FunctionalScenarioId") { newEditColumnsDefaultValues[key] = FunctionalScenarioId; }
        if (key === "FunctionalScenario") { newEditColumnsDefaultValues[key] = FunctionalScenario; }
    }
    seteditColumnsDefaultValues(newEditColumnsDefaultValues);
  }

  const requestOptionsGETALL = {
    method: 'GET'
  };

  
  
  const LoadData = (text: string) => {
    setIsLoaded(true);
    setLoadText(text); 
    setDataMonitoringIssueFilteredData([]);
    resetSelection();
    setActiveTab(NotificationPivotOptions.ACTIONREQUIRED);
    GetUpcomingReleases();
    DOMEApiHelper('DataMonitoringIssues?userId=' + sessionStorage.getItem("localAccountId"), requestOptionsGETALL)
      .then(res => {
      
        if (res !== undefined && res.length > 0 && res[0]["DataMonitoringIssuesList"].length > 0) {
          //to add isSelected for all the rows to use for bulk edit checkbox selection
          res[0]["DataMonitoringIssuesList"].forEach((item: any, index: any) => {
             item.isSelected = false;
              if(isEmpty(item.AssignedToUserName)){
                item.AssignedToUserName= item.AssignedTo;
              }
          });
          //bydefault show action required data.
          let dataMonitoringIssueActionRequiredData=res[0]["DataMonitoringIssuesList"].filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key);
          setDataMonitoringIssueData(dataMonitoringIssueActionRequiredData);       
          setDataMonitoringIssueBeforeFilterData(res[0]["DataMonitoringIssuesList"]); //store data to store before filter  
          
        let resolutionList = res[0]["ResolutionList"].filter((item:any) => item.Title !=='In Progress' && item.Title !=='Known Exception' && item.Title !== 'System Resolved' && item.Title !== 'Configuration In Progress');
        setResolution(resolutionList.map((item:any) => {
          return {
            key: item.Title,
            text: item.Title
      }}));      
          setActionRequired(res[0]["ActionRequiredList"].map((item:any) => {
            return {
              key: item.Id,
              text: item.Title
        }}));
          setRefColumnsDefaultValues(editColumnsDefaultValues);   

         //sort bydefault 
          if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]!==undefined){
            sortDefault(dataMonitoringIssueActionRequiredData) 
          }
          else{
            resetPagination();       
          }
        }
        setIsLoaded(false);
        setLoadText("");     

      }).catch(error => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching data moniotoring issues data");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);           
        }, 5000);   
    });
  }

  useEffect(() => {
    setIsLoaded(true);
    LoadData("Loading data");
    // eslint-disable-next-line 
  }, [setDataMonitoringIssueData]);

    interface UpdateDataMonitoringIssuesModel {
        Id: number;
        Resolution?: string | null;
        FunctionalScenarioId?: string | null;
        TargetCompletionDate?: string | null;
        ActionRequired?: number | null;
        Comments?: string | null;
        ResolvedBy?: string | null;
        ModifiedBy?: string | null;
        AssignedTo?: string|null
    }

    const SaveAPI = (Id: number) => {

        var userID = sessionStorage.getItem("localAccountId");
        const item = dataMonitoringIssueData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        const OriginalData = item;
   
        const actionResolved = UtilDta.filter((data: any) => data.id.includes("Resolved"));
        const ActionResolvedKeyId = ActionRequired.filter((data: any) => data.text.includes(actionResolved[0].key));

        const updateModifiedBy = (data: any) => {
            data['ModifiedBy'] = userID;
            return data;
        }
        let inputList: any = [];
        let data: UpdateDataMonitoringIssuesModel = {
            Id: editColumnsDefaultValues['Id'], 
            Resolution: null          
        };
        if(!AssignedToEditButtonClicked){
          data["Resolution"] = editColumnsDefaultValues['Resolution']
        // if save call is through bulk edit then check if respective property is not null and bulkeditbuttonclick is true and if bulkeditbuttonclick is false then perform single edit
        if ((editColumnsDefaultValues['FunctionalScenarioId'] != null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['FunctionalScenarioId'] !== OriginalData['FunctionalScenarioId'])) {
            data['FunctionalScenarioId'] = editColumnsDefaultValues['FunctionalScenarioId'];
            data = updateModifiedBy(data);
        }
        if ((editColumnsDefaultValues['Resolution'] != null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['Resolution'] !== OriginalData['Resolution'])) {
          
            data['ResolvedBy'] = userID;
            data = updateModifiedBy(data);
        }
        if ((bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['TargetCompletionDate'] !== OriginalData['TargetCompletionDate'])) {
          if(bulkEditButtonClicked && editColumnsDefaultValues['TargetCompletionDate'] === null)  
          {
            const sDate = new Date();
            let vsDate = dayjs(sDate).startOf("day").toDate();
            const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
            data['TargetCompletionDate'] = formattedDate;
          }
          else{
            data['TargetCompletionDate'] = editColumnsDefaultValues['TargetCompletionDate'];
            data = updateModifiedBy(data);
          }
          
        }

        if ((editColumnsDefaultValues['Comments'] != null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['Comments'] !== OriginalData['Comments'])) {
            data['Comments'] = editColumnsDefaultValues['Comments'];
            data = updateModifiedBy(data);
        }

        if(bulkEditButtonClicked && AssignedTo.length>0){
          bulkEditSelectedData.forEach((obj: any) => {
            data["Id"] = obj.Id;
            data["AssignedTo"] = AssignedTo.length>0? AssignedTo[0].objectId: ""
            data = updateModifiedBy(data);
          })

        }
        else if (AssignedTo.length >0 && AssignedTo[0].objectId !== "" && AssignedTo[0].objectId !== editColumnsDefaultValues.AssignedTo)
        {
          data['AssignedTo'] = AssignedTo[0].objectId
          data = updateModifiedBy(data);
        }
      }


        if (bulkEditButtonClicked) {
            bulkEditSelectedData.forEach((obj: any) => {
                data["Id"] = obj.Id;
                if (data['Resolution'] === 'False Positive') {
                    data["TargetCompletionDate"] = obj.TargetCompletionDate;
                }
                inputList.push(JSON.parse(JSON.stringify(data)));
            })
        }
        else {
            inputList.push(data);
        }

        if (inputList.length > 0 && Object.keys(inputList[0]).length > 2) {
         
            setIsLoaded(true);
            setToastTitle("Data Monitoring Issues");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true);

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputList),
                mode: 'cors'
            }

            DOMEApiHelper('DataMonitoringIssues', requestOptions)
                .then(res => {
                   //if both datamonitoringissue and discusion changes then calling disucusion after save call
                  if(!isDisabledDiscussion()){
                    handleValueChange(false);
                    onSaveDiscussion(false); // API Call for discussions 
                  }
              
                    setIsLoaded(false);
                    setIsFilterApplied(false);//reset filter
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage("Record is updated successfully.");
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                    LoadData("Loading data");
                  }).catch(res => {
                    setToastMessage(res.toString());
                    setToastType(CoherenceNotificationType.ERROR);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                    setIsLoaded(false);
                });
        }
        else {
            setToastTitle("Data Monitoring Issues");
            setToastMessage("No changes have been made");
            setToastType(CoherenceNotificationType.NONE);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false);
            }, 1000);
        }
    }

  const getPopUpdata = (item: any) => { 
    seteditColumnsDefaultValues(item);
    setDataAttributes(JSON.parse(item.DataIssueAttributes)); 
    setDataMismatchAttributesTitle("Data Mismatch Attributes for "+item.DataType+ " "+item.DataValue);   
    setmodalOpen(true);           
  }
  
const [dataAttributes, setDataAttributes] = useState<any>();
const getDataIssueAttributes= (): any => {
  let tmpRenderObj: any[] = [];   
  for (let data in dataAttributes) { 
      tmpRenderObj.push(
        <><div style={{ display: "flex" }} >
          <div style={{ width: "40%", textAlign: "left" }}>
            <Label>{data}</Label>
          </div>
          <div style={{width:"70%",padding: "5px"}}>: {String(dataAttributes[data])}</div>
        </div>
        </>
      );   
  } 
  if(!isEmpty(editColumnsDefaultValues) && !isEmpty(editColumnsDefaultValues["MLRecommendation"])){  
    tmpRenderObj.push(
      <><div style={{ display: "flex" }} >
        <div style={{ width: "40%", textAlign: "left" }}>
          <Label>ML Recommendation</Label>
        </div>
        <div style={{width:"70%",padding: "5px"}}>: {editColumnsDefaultValues["MLRecommendation"]}</div>
      </div>
      </>
    );    
  }
    return tmpRenderObj;
}


  const generateControls = (): any => {
    let tmpRenderObj: any[] = [];

    let userObj = JSON.parse(JSON.stringify(editColumnsDefaultValues), (_key, value) => {
      return value;
    });
    // eslint-disable-next-line
    for (let editRowColumn of editRowColumns) {
      switch (editRowColumn.type) {
        case 'DropDown':
          {
            if (editRowColumn.label === 'Resolution' && editColumnsDefaultValues[editRowColumn.key] !== 'System Resolved') {
              tmpRenderObj.push(
                <Dropdown key={editColumnsDefaultValues[editRowColumn.key]} 
                  label={editRowColumn.label}
                  options={Resolution ?? []}
                  defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                  onChange={(ev, selected) => {
                    setIsDisabledSaveButton(false);
                    onDropDownChange(ev, selected, editRowColumn)}}
                  placeholder={"Select " + editRowColumn.label}
                  defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                />
              );
            }
            else if (editRowColumn.label === 'Resolution' && editColumnsDefaultValues[editRowColumn.key] === 'System Resolved') {
              tmpRenderObj.push(
                <Label>{editRowColumn.label}
                <Label className="readonyLabel" style={{height: '35px'}} disabled>
                      {editColumnsDefaultValues[editRowColumn.key]} 
                            </Label>
                </Label>
                
              );
            }
            else if (editRowColumn.label === 'Functional Scenario') {
              tmpRenderObj.push(
                (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data:any) => data.id.includes("Configuration"))[0].key) ?
                  <SearchableDropdown key={editColumnsDefaultValues[editRowColumn.key]} 
                    label={editRowColumn.label}
                    options={FuncScenario ?? []}
                    selectedKey={editColumnsDefaultValues["FunctionalScenarioId"]}
                    onChange={(ev, selected) => {
                      setIsDisabledSaveButton(false);
                      onDropdownChangeFun(ev, selected, editRowColumn)}}
                    placeholder={"Select " + editRowColumn.label}
                    required={true}
                    errorMessage={FuctionalSceErrMsg}
                    defaultValue={editColumnsDefaultValues["FunctionalScenarioId"]} 
                  /> : ''
              );
            }
          }
          break;
        case 'ReadOnly': 
                // Check if the bulk edit button has not been clicked
                if (!bulkEditButtonClicked) {
                    // Destructure the label and key from editRowColumn for easier access
                    const { label, key } = editRowColumn;
                
                    // Retrieve the value associated with the current column key
                    const value = editColumnsDefaultValues[key];
                
                    // Determine if the current field is for 'Comments' and is empty
                    const isCommentsEmpty = label === 'Comments' && key === 'Comments' && isEmpty(value);
                
                    // Set display label to 'Annotations' if the label is 'Comments', otherwise use the column's label
                    const displayLabel = label === 'Comments' ? 'Annotations' : label;
                
                    // Apply a specific height style only when dealing with an empty 'Comments' field
                    const heightStyle = isCommentsEmpty ? { height: '30px' } : undefined;
                
                    // Push the Label component into the tmpRenderObj array
                    tmpRenderObj.push(
                        <Label>
                            {/* Display either 'Annotations' or the original label */}
                            {displayLabel}
                            
                            {/* Render the value within a disabled Label, with optional styling for empty 'Comments' */}
                            <Label className="readonyLabel" style={heightStyle} disabled>
                                {value}
                            </Label>
                        </Label>
                    );
                }
            break;
          case 'Recommendation':
            if(!bulkEditButtonClicked && !isEmpty(editColumnsDefaultValues[editRowColumn.key])){  
              tmpRenderObj.push(
                <>
                     <Label >{editRowColumn.label}
                                    <Label className="readonyLabel" disabled>{editColumnsDefaultValues[editRowColumn.key]}</Label>
                          </Label>
                 </>
            );
            }
          break;
        case 'Date':
              tmpRenderObj.push(
                (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data:any) => data.id.includes("Configuration"))[0].key) ?
                  <span><Label>{editRowColumn.label } <span id="releaseDates" onClick={onCalloutClick}><MyIcon/></span></Label>
                  <DatePicker
                    value={userObj.TargetCompletionDate ? new Date(userObj.TargetCompletionDate) : new Date()}                    
                    minDate={new Date()}      
                    placeholder={"Select " + editRowColumn.label}
                    onSelectDate={newDate => { 
                      setIsDisabledSaveButton(false);
                      handleDateSelection(newDate, editRowColumn); }}
                    formatDate={onFormatDate} 
                  textField={{ errorMessage: "" }}
                  ></DatePicker>
                
          {isCalloutVisible && (
            <Callout
              role="dialog"
              target={`#${"releaseDates"}`}
              onDismiss={onCalloutClick}
              style={{padding:"20px"}}
              setInitialFocus
            >
              <div>
              {"Leverage below upcoming release timelines: "}   
                <ul>
                  {upcomingReleases.map((item: any)=> 
                  <li>{dayjs(item.StartDate).format("MM/DD/YYYY")} </li>)}
                </ul>
                {"Please refer "} 
                <Link  target="_blank" onClick={(event) => {  
                  window.open(process.env.REACT_APP_ReleaseCalendar_URL); 
                }} >
                Release Calendar 
              </Link>
              {" for more information."}  
              </div>
            </Callout>
          )}</span>: '' 
              );
        
          break;
        case 'TextField':
            tmpRenderObj.push(
              (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data:any) => data.id.includes("Configuration"))[0].key) ?
                <TextField label={editRowColumn.label} multiline resizable={false}
                  onChange={(event) => { setIsDisabledSaveButton(false); 
                    onChangeText(event, event.target, editRowColumn); }}
                  defaultValue ={editColumnsDefaultValues["Comments"]}
                ></TextField> 
                : (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data:any) => data.id.includes("FalsePositive"))[0].key) ?
                <TextField label={editRowColumn.label} multiline resizable={false}
                  onChange={(event) => { setIsDisabledSaveButton(false);
                    onChangeText(event, event.target, editRowColumn); }}
                  defaultValue ={editColumnsDefaultValues["Comments"]}
                ></TextField> 
                :''
            );              
          break;    
          case 'Contact':
            tmpRenderObj.push(
              <span>
              <PrimaryContactPicker EnableGroupSearch={true} label={"Assigned To Group/User"} defaultPrimaryContact={AssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={AssignedToRequired}></PrimaryContactPicker>
             {/* { assignToErr  && <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5}}>Please select Assigned to Group/User</span>} */}
              </span>
            );
          
            break;     
        default:
      }
    }
    return tmpRenderObj;
  }

  const Validate = () => { 
    let IsFunctional = false; 
      if((editColumnsDefaultValues["Resolution"] !=='' && editColumnsDefaultValues["Resolution"] !== null && editColumnsDefaultValues["Resolution"] !== "False Positive") &&  (editColumnsDefaultValues["FunctionalScenario"] === null || editColumnsDefaultValues["FunctionalScenario"]===undefined || editColumnsDefaultValues["FunctionalScenario"] ===""))
      {
        setFuctionalSceErrMsg("Please select functional scenario");
        IsFunctional = true;
      } 
    if( IsFunctional) 
    {
      setshowEditGridPanel(true);
      return true;
    }
    else
    { 
      return false;
    }
  }

  const validateAssignedTo=() =>{ 
    if ((!bulkEditButtonClicked && AssignedTo.length === 0) ){
      setAssignToErr(true);
      return false;
    }
    else{
      setAssignToErr(false);
      return true;
    } 
  }
 
  const handleOnPanelClose = () => {      
      let Status = Validate();
      let assigntostatus = validateAssignedTo()
      
      if(!Status && assigntostatus && !isDisableDataMonitoring())
      {
        handleValueChange(false);
        onEditPanelChange(refColumnsDefaultValues);
      } 
       else if(!isDisabledDiscussion()){//if only disucusion changes 
        handleValueChange(false);
        onSaveDiscussion(true); // API Call for discussions 
      }
    
  };

  const handleOnPanelXClose = () => {  
    setshowEditGridPanel(false);
    setDisussionData([]);
    setDiscussionRecords([]);
  }

  const handleOnPanelJSONXClose = () => {
    setmodalOpen(false);
  }  
  

  //Pagination code start   
  const [dataMonitoringPaginatedData, setDataMonitoringPaginatedData] = useState<any>([]);  
  const [refreshPagination, setRefreshPagination] = useState<boolean>(false);  
    const updatePaginationData = (data:any) => {   
      if(activeTab===NotificationPivotOptions.ALLITEMS){
        setDataMonitoringPaginatedData(data);
       } 
      else if(activeTab===NotificationPivotOptions.ACTIONREQUIRED){
        setDataMonitoringPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key));      
      }
      else if(activeTab===NotificationPivotOptions.ACTIONRESOLVED){
        setDataMonitoringPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Resolved"))[0].key));               
      }
  }
  

   const getDataCountByActionType=(inputType:any) => {   
    if(inputType===NotificationPivotOptions.ALLITEMS){
      return isFilterApplied?dataMonitoringIssueFilteredData.length:dataMonitoringIssueBeforeFilterData.length;
     } 
    else if(inputType===NotificationPivotOptions.ACTIONREQUIRED){
     return (isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) =>  data.ActionRequiredName    === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key  || data.ActionRequiredName ===UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key).length
    }
    else if(inputType===NotificationPivotOptions.ACTIONRESOLVED){
      return (isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Resolved"))[0].key).length
    } 
   } 
   const resetPagination=() => {    
      setRefreshPagination(refreshPagination?false:true);
   }
 //Pagination code end 

   //Pivot related code start
   const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ACTIONREQUIRED);   
   const handlePivotChange = (item: any) => {
    resetSelection();    
    if(item.props.itemKey===NotificationPivotOptions.ALLITEMS){
      setDataMonitoringIssueData(isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData);
      if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]!==undefined)
      sortDefault(isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData)  //sort bydefault     
      else  resetPagination();  
    } 
    else if(item.props.itemKey===NotificationPivotOptions.ACTIONREQUIRED){    
      setDataMonitoringIssueData((isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key));      
      if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]!==undefined) 
      sortDefault((isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key));      
      else  resetPagination();
    }
    else if(item.props.itemKey===NotificationPivotOptions.ACTIONRESOLVED){   
      setDataMonitoringIssueData((isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));              
      if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]!==undefined) 
      sortDefault((isFilterApplied?dataMonitoringIssueFilteredData:dataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));               
      else  resetPagination();
    }
    setActiveTab(item.props.itemKey);    
   }; 
     //Pivot related code end
       

  //Filter code start  
     const [dataMonitoringIssueBeforeFilterData, setDataMonitoringIssueBeforeFilterData] = useState<any>([]);   
     const [dataMonitoringIssueFilteredData, setDataMonitoringIssueFilteredData] = useState<any>([]);          
     const [isFilterApplied,setIsFilterApplied] =  useState<boolean>(false);
    const onApplyFilter = (filteredArray: any) => {
        resetSelection();
    setIsFilterApplied(true); 
    setDataMonitoringIssueFilteredData(JSON.parse(JSON.stringify(filteredArray))); 
    if(activeTab===NotificationPivotOptions.ALLITEMS){
      setDataMonitoringIssueData(filteredArray);
     } 
    else if(activeTab===NotificationPivotOptions.ACTIONREQUIRED){
      setDataMonitoringIssueData(filteredArray.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key));      
    }
    else if(activeTab===NotificationPivotOptions.ACTIONRESOLVED){ 
      setDataMonitoringIssueData(filteredArray.filter((data: any) => data.ActionRequiredName === 'Resolved'));               
    }
    resetPagination(); 
    };

const onClearFilter = () => { 
  setIsFilterApplied(false); 
  setDataMonitoringIssueFilteredData([]);
  if(activeTab===NotificationPivotOptions.ALLITEMS){
    setDataMonitoringIssueData(dataMonitoringIssueBeforeFilterData);
   } 
  else if(activeTab===NotificationPivotOptions.ACTIONREQUIRED){
    setDataMonitoringIssueData(dataMonitoringIssueBeforeFilterData.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key));      
  }
  else if(activeTab===NotificationPivotOptions.ACTIONRESOLVED){ 
    setDataMonitoringIssueData(dataMonitoringIssueBeforeFilterData.filter((data: any) => data.ActionRequiredName === 'Resolved'));               
  }
  resetPagination();
} 

const createFilterColumns  = () => { 
  return [
    {"fieldName":"DataType","label":"Data Type","type":"dropdown","options":[]},
    {"fieldName": "DataValue", "label": "Data Value", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
    {"fieldName":"DisplayName","label":"Display Name","type":"searchTextBox","options":[]},
    {"fieldName":"FunctionalScenario","label":"Functional Scenario","type":"searchDropdown","options":[]},
    {"fieldName":"MonitoringIssue","label":"Monitoring Issue","type":"searchDropdown","options":[]},   
    {"fieldName":"AssignedToUserName","label":"Assigned To","type":"searchDropdown","options":[],"Default":sessionStorage.getItem("localAccountName")},  
    {"fieldName":"ResolvedName","label":"Resolved By","type":"searchDropdown","options":[]}, 
    {"fieldName":"TargetCompletionDate","label":"Target Completion Date","type":"date","fromTargetCompletionDate": null,"toTargetCompletionDate":null },
    {"fieldName":"CreatedDate","label":"Created Date","type":"date", "fromCreatedDate": null,"toCreatedDate":null },
    {"fieldName":"ResolvedDate","label":"Resolved Date","type":"date", "fromResolvedDate": null,"toResolvedDate":null },
    {"fieldName":"Resolution","label":"Resolution","type":"multiselectDropdown", "options": [{ key: 'Configuration', text: 'Configuration' }, { key: 'False Positive', text: 'False Positive' }]}
  ]; 
   }
  //Filter code end




     //Sort code start
     const [sortColumnDetails,setSortColumnDetails] =  useState<any>();  

     const createSortColumns  = () => { 
      return [
        {"fieldName":"DataType","label":"Data Type"},
        {"fieldName":"DataValue","label":"Data Value"},
        {"fieldName":"DisplayName","label":"Display Name"},
        {"fieldName":"FunctionalScenario","label":"Functional Scenario"},
        {"fieldName":"ResolvedName","label":"Resolved By"},         
        {"fieldName":"Resolution","label":"Resolution"},
        {"fieldName":"TargetCompletionDate","label":"Target Completion Date"},
        {"fieldName":"CreatedDate","label":"Created Date"},
        {"fieldName":"ResolvedDate","label":"Resolved Date"}
        ] 
     };

     const sortDefault = (data:any) => { 
      if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]!==undefined){ 
          onSorted(sortColumnDetails["columnName"],sortColumnDetails["sortType"],data);     
      } 
     }
     const appendSortIcon =(columnName:string):any=>{
      if(sortColumnDetails!==undefined && sortColumnDetails["columnName"]===columnName){
        return sortColumnDetails["sortType"]==="asc"?"Ascending":"Descending"; 
      }
     }

    const onSorted = (columnName: string, sortType: string, dataMonitoringIssue?: any): void => {
     resetSelection();
     setSortColumnDetails({"columnName":columnName,"sortType":sortType});
     let sortedData=(dataMonitoringIssue!==undefined?dataMonitoringIssue: dataMonitoringIssueData).sort((a:any, b:any) => {
        if (a[columnName] === null) {
          return sortType === 'asc' ? 1 : -1;
        }
        if (b[columnName] === null) {
            return sortType === 'asc' ? -1 : 1;
        }
        if (a[columnName] > b[columnName]) {
          return sortType === 'asc' ? 1 : -1;
        }
        if (a[columnName] < b[columnName]) {
          return sortType === 'asc' ? -1 : 1;
        }
        return 0;
      }); 
  
      setDataMonitoringIssueData(JSON.parse(JSON.stringify(sortedData))); 
      resetPagination();     
     }

     //Sort code end

    //  bulk edit code start 
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = useState<boolean>(false);
    const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
    const [AssignedToEditButtonClicked, setAssignedToEditButtonClicked] = useState<boolean>(false);

    // onChange function to update isSelect state
    const handleSelectionCheckbox = (event: any, item: any) => {
        if (event.target.checked) {
            item.isSelected = true;
            setBulkEditSelectedData([...bulkEditSelectedData, item]);
        } else {
            if (isSelectedAll)
                setIsSelectedAll(false);

            item.isSelected = false;
            setBulkEditSelectedData(
                bulkEditSelectedData.filter((selectedItem: any) => selectedItem.Id !== item.Id)
            );
        }

        const updatedSelectedData = dataMonitoringPaginatedData.map((item: any) => ({
            ...item,
            isSelected: item.isSelected
        }));

        setDataMonitoringPaginatedData([...updatedSelectedData]);

        dataMonitoringIssueData.forEach((element: any) => {
            if (element.Id === item.Id) {
                element.isSelected = item.isSelected
            }
        });
        setDataMonitoringIssueData([...dataMonitoringIssueData]);
    };

    // onChange function to update select all checkbox
    const handleSelectAllCheckbox = (event: any, item: any) => {
        setIsSelectedAll(event.target.checked);

        //update paginated records
        const updatedSelectedData = dataMonitoringPaginatedData.map((item: any) => ({
            ...item,
            isSelected: (item.Resolution === "System Resolved" || item.IsLocked || item.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key) ? false : event.target.checked,
        }));

        setDataMonitoringPaginatedData([...updatedSelectedData]);

        //update all recrods
        const updatedSelectedData1 = dataMonitoringIssueData.map((item: any) => ({
            ...item,
            isSelected: (item.Resolution === "System Resolved" || item.IsLocked || item.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key)  ? false : event.target.checked,
        }));

        setDataMonitoringIssueData([...updatedSelectedData1]);

        const updateBulkUpdateData = event.target.checked ? updatedSelectedData1.filter((x: any) => x.isSelected===true) : [];
        setBulkEditSelectedData([...updateBulkUpdateData])
    };

    const resetSelection = (): any => {
        setIsSelectedAll(false);
        setBulkEditSelectedData([]);

        dataMonitoringIssueData.forEach((element:any) => {
          element.isSelected=false;
        });
        setDataMonitoringIssueData([...dataMonitoringIssueData]); 
    }

    const onClickCommandBarsButtonsEvents = (source: any) => {
      if(source=='Bulk Edit'){
        setAssignedTo([]);
        setBulkEditButtonClicked(true);
        setIsDisabledSaveButton(true);
        setAssignToErr(false);
        setAssignedToRequired(false);
        if (bulkEditSelectedData.length > 0) {
            let obj = JSON.parse(JSON.stringify(bulkEditSelectedData[0]));
            Object.keys(obj).forEach(key => { 
                obj[key] = null; 
            });
            seteditColumnsDefaultValues(obj); 
        } 
        setshowEditGridPanel(true);
      } else if (source === 'Selected Item(s)') { 
        exportToCSV(bulkEditSelectedData);
         
      } else if (source === 'All Items') {
        exportToCSV(dataMonitoringIssueBeforeFilterData);        
      }
    }

    const exportToCSV=(data:any):any =>{
      let exportData:any=[];
      data.forEach((obj:any) => {
        let dataIssueAttributes= JSON.parse(obj.DataIssueAttributes);         
        let createObj={ Id: obj.Id,DataType:obj.DataType,DataValue:obj.DataValue,DisplayName:obj.DisplayName, ActionRequired: obj.ActionRequiredName,
          FunctionalScenario:obj.FunctionalScenario ,IssueType:obj.IssueType, MLRecommendation:obj.MLRecommendation ,MonitoringIssue:obj.MonitoringIssue,ModifiedBy:obj.ModifiedBy,
          ResolvedBy:obj.ResolvedBy,ResolvedDate:isEmpty(obj.ResolvedDate)?"":dayjs(obj.ResolvedDate).format('MM/DD/YYYY'),ResolvedName:obj.ResolvedName,TargetCompletionDate:isEmpty(obj.TargetCompletionDate)?"":dayjs(obj.TargetCompletionDate).format('MM/DD/YYYY')  };
          exportData.push( Object.assign({}, createObj,dataIssueAttributes));
      });       
      convertToCsv(exportData,"Data Monitoring Issues");
    }

    const generateButtons = (): any => {
        const _items: ICommandBarItemProps[] = [
          {
                key: 'Bulk Edit',
                text: 'Bulk Edit',
                ariaLabel: 'Bulk Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                 
              },
                disabled: bulkEditSelectedData.length > 1 ? false : true,
          },
          { 
            key: 'Export',
            text: 'Export',
            ariaLabel: 'Export',
            disabled: false,
            iconProps: { iconName: 'Download' },
            onClick: () => {

            },
            subMenuProps: {
              items: [
                {
                  key: 'SelectedItems',
                  text: 'Selected Item(s)',
                  disabled: bulkEditSelectedData.length>0 ? false : true,
                  onClick: () => {}
                },
                {
                  key: 'AllItems',
                  text: 'All Items',
                  disabled: false,
                  onClick: () => {}
                }
              ],
            }
          }
            ];
        return _items;
    }
    //  bulk edit code end


    //discussion code start

    const [disussionData, setDisussionData] = useState<any>();
    const [discussionRecords, setDiscussionRecords] = useState<any>([]); 

    const isDisableDataMonitoring=() =>{
      if(!bulkEditButtonClicked && assignToErr){
        return true;
      }
     
      return ((!bulkEditButtonClicked && (InProgressStatus || editColumnsDefaultValues.IsLocked)
                )  ||(bulkEditButtonClicked  && (editColumnsDefaultValues.Resolution==="Configuration" && editColumnsDefaultValues.FunctionalScenarioId===null))
                ||(bulkEditButtonClicked &&((AssignedTo.length===0) &&  isEmpty(editColumnsDefaultValues.Resolution)))
                || (!bulkEditButtonClicked &&  isDisabledSaveButton)
                || (!bulkEditButtonClicked && !isDataChange())) ? true : false;
    }
    const isDisabledDiscussion=() =>{
      if(assignToErr){
        return true;
      }
      return (isEmpty(disussionData) || isEmpty(disussionData.Discussion)) ? true : false;
    }
  

    const onValidDiscussionData = (disussionData: any, discussion: any) => {
        setDisussionData(disussionData);
    }

  

  const onSaveDiscussion = (showToast:any) => {
       let discussionDataList : any =[];
       discussionDataList.push(disussionData);


        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(discussionDataList),
          mode: "cors",
        };  
        if(showToast){
          setIsLoaded(true);
          setToastTitle("Data Monitoring Issues");
          setToastMessage("Record is being updated.");
          setToastType(CoherenceNotificationType.PENDING);
          setShowToast(true);  
        }
        DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
          .then(res => {
            if(showToast){
            setIsLoaded(false);
            setIsFilterApplied(false);//reset filter
            setToastType(CoherenceNotificationType.SUCCESS);
            setToastMessage("Record is updated successfully.");
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
            LoadData("Loading data"); 
          }
          }).catch(res => {
            setToastMessage(res.toString());
            setToastType(CoherenceNotificationType.ERROR);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });
   } 
    //discussion code end

    const isDataChange =()=>{
      const item = dataMonitoringIssueBeforeFilterData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
   
      const OriginalData = item;
   
        if(!bulkEditButtonClicked &&  AssignedTo.length>0  && !isEmpty(AssignedTo[0].objectId) ){    
          if( editColumnsDefaultValues['FunctionalScenarioId'] !== OriginalData['FunctionalScenarioId']
          || editColumnsDefaultValues['Resolution'] !== OriginalData['Resolution']
          || editColumnsDefaultValues['TargetCompletionDate'] !== OriginalData['TargetCompletionDate']
          || AssignedTo[0].objectId !== OriginalData['AssignedTo']
          )
              {
                  return true;
              }
          else{   
                return false;
          }       
       }
    }

  return (
    <>
         <CustomGridControls  defaultFilterSessionKey="DataMonitoringIssueDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()}  filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={dataMonitoringIssueBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted}  onClearFilter={onClearFilter}/>    
          {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
      <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
        <PivotItem
          headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS)  + ")"}
          itemKey={NotificationPivotOptions.ALLITEMS}
          ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS)  + ")"}
        >
          <div style={{minHeight:425,height:425,  maxHeight: 425 }}>
            {!isLoaded ? <CoherenceDataGrid
              listConfig={getColumns()}
              data={dataMonitoringPaginatedData}
              isScrollable={true} 
              isSortable={false} 
              sortByDefault={false} 
              layoutMode={DetailsListLayoutMode.fixedColumns}
              ariaLabel={"Data Monitoring Issues All Items List"}
            ></CoherenceDataGrid>
              : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}

          </div>
        </PivotItem>
        <PivotItem
          headerText={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED)+ ")"
        }
          itemKey={NotificationPivotOptions.ACTIONREQUIRED}
          ariaLabel={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED)+ ")"}
        >
          <div style={{minHeight:425,height:425,  maxHeight: 425 }}>
            {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()} 
              data={dataMonitoringPaginatedData}
              isScrollable={true}  
              ariaLabelForSelectAllCheckbox={'select all checkbox'}  
              isSortable={false} 
              sortByDefault={false}
              selectionPreservedOnEmptyClick={true} 
              layoutMode={DetailsListLayoutMode.fixedColumns}
              ariaLabel={"Data Monitoring Issues Action Required List"}
            ></CoherenceDataGrid>
              : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
          </div>
        </PivotItem>
        <PivotItem
          headerText={"Action Resolved (" +getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED)+ ")"}
          itemKey={NotificationPivotOptions.ACTIONRESOLVED}
          ariaLabel={"Action Resolved (" +getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED)+ ")"}
        >
          <div style={{minHeight:425,height:425,  maxHeight: 425 }}>
            {!isLoaded ?
              <CoherenceDataGrid
                listConfig={getColumns()}
                data={dataMonitoringPaginatedData} 
                isScrollable={true} 
                ariaLabelForSelectAllCheckbox={'select all checkbox'} 
                isSortable={false}   
                sortByDefault={false}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                ariaLabel={"Data Monitoring Issues Action Resolved List"}
              ></CoherenceDataGrid>
              : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
          </div>
        </PivotItem>
      </Pivot>
      <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={dataMonitoringIssueData} pageSize={8}></CustomPagination>
      <CoherencePanel  
        titleText={bulkEditButtonClicked ? "Bulk Edit for " + bulkEditSelectedData.length + " Selected Rows" : "Edit Row Details"}
        isOpen={showEditGridPanel}
        onDismiss={handleOnPanelXClose}
        panelSize={CoherencePanelSize.medium}
        closeButtonAriaLabel="Close"
        onRenderFooter={() => (
          <Stack horizontal >
            <PrimaryButton text="Save" 
              disabled={isDisableDataMonitoring() && isDisabledDiscussion()}
            onClick={handleOnPanelClose} />
            <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
          </Stack>
        )}
      >
        <>
          <div  style={bulkEditButtonClicked  ? {} : { display: "grid", gridTemplateColumns: "50% 50%",gridGap: "1rem" }} >
          {showEditGridPanel && generateControls()}
          </div>
          {bulkEditButtonClicked ?  <span></span>:
         <NonManagedDiscussions discussionId={editColumnsDefaultValues!=undefined? editColumnsDefaultValues.Id:0} discussionType="DataMonitoringIssue" onValidDiscussionData={onValidDiscussionData} isExpand={false} ></NonManagedDiscussions>
          }
        </> 
      </CoherencePanel>
      <CoherencePanel
      title={DataMismatchAttributesTitle}
      titleText={DataMismatchAttributesTitle}
        isOpen={modalOpen}
        onDismiss={handleOnPanelJSONXClose}
        panelSize={CoherencePanelSize.medium}
         >
        {modalOpen && getDataIssueAttributes()}
      </CoherencePanel>
    </>
  );
};

export default DataMonitoringIssues; 

