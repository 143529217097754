import { CoherenceNotificationType, CoherenceDataGridColumn, CoherenceDataGrid, CoherenceLoading, CoherencePanel } from "@coherence-design-system/controls";
import { CoherencePanelSize } from "@coherence-design-system/utilities";
import { Checkbox, TooltipHost, FontIcon, IDropdownOption, Dropdown, ChoiceGroup, Label, DatePicker, TextField, ICommandBarItemProps, CommandBar, Pivot, PivotItem, DetailsListLayoutMode, Stack, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from "@fluentui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react"; 
import { NotificationPivotOptions } from "../../../shared/AppHeader/AppHeader.utils";
import CustomGridControls from "../../../shared/CustomGridControls/CustomGridControls";
import { SearchableDropdown } from "../../../shared/CustomSearchDropdown/CustomSearchDropdown";
import CustomPagination from "../../../shared/CutomPagination/CustomPagination";
import { PrimaryContactPicker } from "../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import ToastMessage from "../../../shared/ToastMessage/ToastMessage";
import { isEmpty, DOMEApiHelper, onFormatDate } from "../../../utils/DOMEApiHelper";
import { GetUserRole } from "../../../utils/GetUserRole";
import UtilDta from "../../../utils/UtilCommon.json";
import { useLocation } from "react-router-dom";
import NonManagedDiscussions from "../../NonManaged/NonManagedDiscussions/NonManagedDiscussions";

interface prop {
    reloadSection: any;
  }
export const ManagedCatalogAlignment  = (props:prop) => { 
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false);
    const [userRole, setUserRole] = useState(GetUserRole());
    const [isLoaded, setIsLoaded] = useState(false);
    const [LoadText, setLoadText] = useState<string>("");
    const [catalogAlignmentData, setCatalogAlignmentData] = useState<any>([]);
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const [showEditGridPanel, setshowEditGridPanel] = React.useState<boolean>(false);
    const [refColumnsDefaultValues, setRefColumnsDefaultValues] = useState<any>(null); 
    const [showComments, setShowComments] = useState<any>();
    const [selectedComment, setSelectedComment] = useState<string>("");
    const [FuncScenario, setFuncScenario] = useState<any>([]);
    const [ActionRequired, setActionRequired] = useState<any>([]);
    const [ConfidenceLevel, setConfidenceLevel] = useState<any>([]);
    const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
    const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false); 
    const [PORId, setPORId] = useState<any>();
    const [UniqueCapabilityCode, setUniqueCapabilityCode] = useState<any>();
    const location = useLocation();
    const [LockButtonCatalogAlignmentManaged, setLockButtonCatalogAlignmentManaged] = useState<boolean>(true); 

    //methods
    const handleComments = () => {
        setShowComments(true);
    }
    const handleHideComment = () => {
        setShowComments(false);
    }  
    const handleValueChange = (newValue: boolean | ((prevState: boolean) => boolean)) => {
        setshowEditGridPanel(newValue);
    };
    let IdVal: number;
    const onEditPanelChange = (item: any): void => {
        for (let key in editColumnsDefaultValues) {
            if (key === "Id") {
                IdVal = editColumnsDefaultValues[key];
            }
        }
        updateCatalogAlignment(IdVal);
    };
    // eslint-disable-next-line
    const [editRowColumns, seteditRowColumns] = useState<any>(
        [
            { "isEditable": true, "type": "ReadOnly", "label": "Data Type", "key": "DataType" },
            { "isEditable": true, "type": "ReadOnly", "label": "Data Value", "key": "DataValue" }, 
            { "isEditable": true, "type": "ReadOnly", "label": "Functional Scenario", "key": "FunctionalScenario" },
            { "isEditable": true, "type": "Date", "label": "Target Completion Date", "key": "TargetCompletionDate" },
            { "isEditable": true, "type": "DropDown", "label": "Action Required", "key": "Action" },
            { "isEditable": true, "type": "DropDown", "label": "Confidence Level", "key": "ConfidenceLevelId" }, 
            { "isEditable": true, "type": "ChoiceGroup", "label": "Is Mapped", "key": "IsMapped", "choiceGroupOptions": [{ key: true, text: 'Yes' }, { key: false, text: 'No' },] },    
            { "isEditable": true, "type": "ReadOnly", "label": "Comments", "key": "Comments" },

        ]
    );
    // eslint-disable-next-line 
   
    const getColumns = (): CoherenceDataGridColumn<any>[] => {
        var advancedColumns: CoherenceDataGridColumn<any>[] = [
            {
                key: 'selectAll',
                name: 'Select All',
                fieldName: 'SelectAll',
                type: 'string',
                isResizable: false,
                minColumnWidth: 20,
                maxWidth: 20,
                ariaLabel: 'Select All',
                data: [],
                onRender: (item: any) => {
                    return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} disabled={item.IsLocked==true} checked={item.isSelected} />;
                },
                onRenderHeader: (item: any) => {
                    return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event)} checked={isSelectedAll} />;
                },
            },
            {
                key: 'Actions',
                name: 'Edit',
                type: 'actions',
                menuActions: [],
                isResizable: false,
                minColumnWidth: 40,
                maxWidth: 40,
                data: [],
                visibleActions: [
                    {
                        onClick: (item) => {
                            setBulkEditButtonClicked(false);   
                            item.isEditMode = 'true';
                            seteditColumnsDefaultValues(item);
                            setshowEditGridPanel(true);
                        },
                        key: 'edit',
                        text: 'Action',
                        ariaLabel: 'action',
                        iconProps: { iconName: 'Edit', style: { color: "#0078d4" } }
                    }
                ]
            },
            {
                key: 'DataType',
                name: 'Data Type',
                fieldName: 'DataType',
                type: 'string',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'Data Type',
                data: [],
                onRender: (item: any) => {
                    if (item.IsLocked === true) {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)', marginLeft: '-14px' }}>
                                <FontIcon iconName="LockSolid" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                    else {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                },
                iconName: appendSortIcon('DataType'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'DataValue',
                name: 'Data Value',
                fieldName: 'DataValue',
                type: 'string',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'Data Value',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.DataValue}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.DataValue}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('DataValue'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'FunctionalScenario',
                name: 'Functional Scenario',
                fieldName: 'FunctionalScenario',
                type: 'string',
                isResizable: true,
                minColumnWidth: 220,
                maxWidth: 220,
                ariaLabel: 'Functional Scenario',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.FunctionalScenario}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.FunctionalScenario}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('FunctionalScenario'),
                iconClassName: "customSortIcon"
            }, 
            {
                key: 'Action',
                name: 'Action Required',
                fieldName: 'Action',
                type: 'number',
                isResizable: true,
                minColumnWidth: 100,
                maxWidth: 100,
                ariaLabel: 'Action Required',
                className: 'cellLeftAligned customGridCell',
                onRender: (item: any) => {
                    switch (item.ActionRequiredName) {
                        case UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Recent" style={{ color: 'rgba(215, 59, 2, 1)', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Completed" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="ProgressRingDots" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key}</span>
                            }
                    }
                },
                data: []
            },
            {
                key: 'IsMapped',
                name: 'Is Mapped',
                fieldName: 'IsMapped',
                type: 'boolean',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'Is Mapped',
                onRender: (item: any) => {
                    switch (item.IsMapped) {
                        case true:
                            return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left' }} className="customGridCell">
                                <FontIcon iconName="RadioBtnOn" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                            </span>
                        case false:
                            return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left' }} className="customGridCell">
                                <FontIcon iconName="RadioBtnOff" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                            </span>
                    }
                },
                data: [],
                iconName: appendSortIcon('IsMapped'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ConfidenceLevelName',
                name: 'Confidence',
                fieldName: 'ConfidenceLevelName',
                type: 'string',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'Confidence',
                data: [],
                iconName: appendSortIcon('ConfidenceLevelName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'Comments',
                name: 'Annotations',
                type: 'actions',
                menuActions: [],
                isResizable: false,
                minColumnWidth: 90,
                maxWidth: 90,
                data: [],
                visibleActions: [
                    {
                        onClick: (item) => {
                            setSelectedComment(item.Comments);
                            setShowComments(true);
                            handleComments();
                        },
                        key: 'comment',
                        text: 'Annotations',
                        ariaLabel: 'Annotations',
                        iconProps: { iconName: 'Comment', style: { color: "#0078d4" } }
                    }
                ]
            },
            {
                key: 'ResolvedBy',
                name: 'Resolved By',
                fieldName: 'ResolvedBy',
                type: 'persona',
                isResizable: true,
                minColumnWidth: 220,
                maxWidth: 220,
                ariaLabel: 'Resolved By',
                imageUrl: 'imageUrl',
                personaAlt: (item) => `Profile image for ${item.fullName}`,
                data: [],
                iconName: appendSortIcon('ResolvedBy'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'TargetCompletionDate',
                name: 'Target Completion Date',
                fieldName: 'TargetCompletionDate',
                type: 'date',
                isResizable: true,
                minColumnWidth: 140,
                maxWidth: 140,
                ariaLabel: 'Target Completion Date',
                data: [],
                onRender: (item: any) => {
                    return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                        {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
                },
                iconName: appendSortIcon('TargetCompletionDate'),
                iconClassName: "customSortIcon"
            }
        ]; 
        return advancedColumns;
    };
    const onChangeText = (e: any, text: any, _item: any) => {
        SetObjValues(_item.key, text.value);
    }
    const SetObjValues = (key: string, value: any, isChanged: boolean = true, errorMessage: string | null = null): void => {
        seteditColumnsDefaultValues({ ...editColumnsDefaultValues, [key]: value })
    }
    const onDropDownChange = (event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
        SetObjValues(item.key, selectedDropdownItem?.key);
    }
    const onDropdownChangeFun = (event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
        let FunctionalScenarioId: any;
        let FunctionalScenario: any;
        FuncScenario.forEach((_elemen: any, _index: any) => {
            if (_elemen.key === selectedDropdownItem?.key) {
                FunctionalScenarioId = _elemen.key;
                FunctionalScenario = _elemen.text;
            }
        });
        const newEditColumnsDefaultValues = { ...editColumnsDefaultValues };
        for (let key in newEditColumnsDefaultValues) {
            if (key === "FunctionalScenarioId") { newEditColumnsDefaultValues[key] = FunctionalScenarioId; }
            if (key === "FunctionalScenario") { newEditColumnsDefaultValues[key] = FunctionalScenario; }
        }
        seteditColumnsDefaultValues(newEditColumnsDefaultValues);
    }
    const handleDateSelection = (date: any, _item: any) => {
        const sDate = new Date(date);
        const newEditColumnsDefaultValues = { ...editColumnsDefaultValues };
        let vsDate = dayjs(sDate).startOf("day").toDate();
        const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
       
        for (let key in newEditColumnsDefaultValues) {
            if (key === "TargetCompletionDate") {
                newEditColumnsDefaultValues[key] = formattedDate;
                break;
            }
        }
        seteditColumnsDefaultValues(newEditColumnsDefaultValues);
    } 
 
  

    const requestOptions = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
    };
   
    const LoadCatalogAlignmentData = (text: string,PORId :any) => {
        setLoadText(text);
        setIsLoaded(true);
        setCatalogAlignmentBeforeFilterData([]);
        resetSelection();
        setActiveTab(NotificationPivotOptions.ACTIONREQUIRED);
        DOMEApiHelper('PORDetails/GetManagedCatalogAlignment?PORId=' + PORId, requestOptions)
            .then(res => {
                if (res !== undefined && res.length > 0 && res[0]["CatalogAlignmentList"].length > 0) {
                                //to add isSelected for all the rows to use for bulk edit checkbox selection
                                res[0]["CatalogAlignmentList"].forEach((item: any, index: any) => {
                                    item.isSelected = false;
                                });
                                let UniqueCapabilityCode= res[0]["CatalogAlignmentList"][0].UniqueCapabilityCode;
                                setUniqueCapabilityCode(UniqueCapabilityCode);
                                //bydefault show action required data.
                                let catalogAlignmentActionRequiredData = res[0]["CatalogAlignmentList"].filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key);
                                setCatalogAlignmentData(catalogAlignmentActionRequiredData);
                                setCatalogAlignmentBeforeFilterData(res[0]["CatalogAlignmentList"]); //store data to store before filter  
                                                              
                                setFuncScenario(
                                    res[0]["FunctionalScenariosList"].map((item: any) => {
                                        return {
                                            key: item.Id,
                                            text: item.Title
                                        }
                                    }));
                                setActionRequired(
                                    res[0]["ActionRequiredList"].map((item: any) => {
                                        return {
                                            key: item.Id,
                                            text: item.Title
                                        }
                                    }));
                                setConfidenceLevel(
                                    res[0]["ConfidenceList"].map((item: any) => {
                                        return {
                                            key: item.Id,
                                            text: item.Title
                                        }
                                    }));
                   
                    //set default values for edit panel 
                    setRefColumnsDefaultValues(editColumnsDefaultValues);
                    //sort bydefault 
                    if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
                        sortDefault(catalogAlignmentActionRequiredData)
                    }
                    else {
                        resetPagination();
                    }
                }
                setIsLoaded(false);
                setLoadText("");
            }).catch(error => {
                setLoadText("");
                setIsLoaded(false);
                setToastTitle("Error while fetching catalog alignment data");
                setToastMessage(error.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            });
    }
  
    useEffect(() => { 
        setIsLoaded(true);
        const routerName = location.pathname.split('/')[1];
        if (routerName === 'ScopeDefinition') {
          const idStartIndex = window.location.href.lastIndexOf('/');
          const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
          if (id) { 
            setPORId(parseInt(id));
            LoadCatalogAlignmentData("Loading data",id);
            // onSorted('IsMapped', 'desc');
          }
        }
       
        // eslint-disable-next-line
    }, [setCatalogAlignmentData,location]);
    
    interface UpdateCatalogAlignmentModel {
        Id: number;
        FunctionalScenarioId?: number | null;
        Action?: any | null;
        IsMapped?: number | null;
        ConfidenceLevelId?: number | null;
        TargetCompletionDate?: string | null;
        Comments?: string | null;
        ResolvedBy?: string | null;
        ModifiedBy?: string | null; 
        PORId?: number | null;
        UniqueCapabilityCode?:number | null; 
        DataValue?:string | null;
        DataType?:string | null
        
    }
    const updateCatalogAlignment = (Id: number) => {
        var userID = sessionStorage.getItem("localAccountId");
        const item = catalogAlignmentData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        const actionResolved = ActionRequired.filter((option: { text: string; }) => option.text === "Resolved");
        const OriginalData = item;
        
        const updateModifiedBy = (data: any) => {
            data['ModifiedBy'] = userID;
            return data;
        }
        let inputList: any = [];
        let data: UpdateCatalogAlignmentModel = {
            Id: editColumnsDefaultValues['Id'],
            PORId: PORId,
            UniqueCapabilityCode:UniqueCapabilityCode,
            FunctionalScenarioId: editColumnsDefaultValues['FunctionalScenarioId'],
            DataType:editColumnsDefaultValues['DataType'],
            DataValue:editColumnsDefaultValues['DataValue']

        };
        
        if ((bulkEditButtonClicked && editColumnsDefaultValues['Action'] !==null) || (!bulkEditButtonClicked && editColumnsDefaultValues['Action'] !== OriginalData['Action'])) {
            data['Action'] = editColumnsDefaultValues['Action'];
            data['ResolvedBy'] = userID;
            data = updateModifiedBy(data);
        }
        if ((bulkEditButtonClicked && editColumnsDefaultValues['IsMapped'] !==null) || (!bulkEditButtonClicked && editColumnsDefaultValues['IsMapped'] !== OriginalData['IsMapped'])) {
            data['IsMapped'] = editColumnsDefaultValues['IsMapped'];
            data = updateModifiedBy(data);
        }
        if ((bulkEditButtonClicked && editColumnsDefaultValues['ConfidenceLevelId'] !==null) || (!bulkEditButtonClicked && editColumnsDefaultValues['ConfidenceLevelId'] !== OriginalData['ConfidenceLevelId'])) {
            data['ConfidenceLevelId'] = editColumnsDefaultValues['ConfidenceLevelId'];
            data = updateModifiedBy(data);
        }
        
        if ((bulkEditButtonClicked && editColumnsDefaultValues['Comments']) || (!bulkEditButtonClicked && editColumnsDefaultValues['Comments'] !== OriginalData['Comments'])) {
            data['Comments'] = editColumnsDefaultValues['Comments'];
            data = updateModifiedBy(data);
        } 
   
        if (bulkEditButtonClicked) {
            bulkEditSelectedData.forEach((obj: any) => {
                data["Id"] = obj.Id;
                data["DataType"]=obj.DataType;
                data["DataValue"]=obj.DataValue;
                data["FunctionalScenarioId"] = obj.FunctionalScenarioId;
                inputList.push(JSON.parse(JSON.stringify(data)));
            })
        }
        else {
            inputList.push(data);
        }
        if (inputList.length > 0 && Object.keys(inputList[0]).length > 2) {
        
            setIsLoaded(true);
            setToastTitle("Catalog Alignment");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true);

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputList),
                mode: 'cors'
            };
            DOMEApiHelper('CatalogAlignment', requestOptions)
                .then(res => {
                    if(disussionData!==undefined && !isEmpty(disussionData.Discussion) )
                    {    
                    handleValueChange(false);
                    onSaveDiscussion(false);            
                    }
                    setIsFilterApplied(false);//reset filter
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage("Record is updated successfully.");
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    // to reload the other Components
                    props.reloadSection();
                                          
                    LoadCatalogAlignmentData("Loading data",PORId);
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    setIsLoaded(false);
                });
        }
        else {
            setToastTitle("Catalog Alignment");
            setToastMessage("No changes have been made");
            setToastType(CoherenceNotificationType.NONE);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false);
            }, 1000);
        }
    }
    const generateControls = (): any => {
        let tmpRenderObj: any[] = [];
        let userObj = JSON.parse(JSON.stringify(editColumnsDefaultValues), (key, value) => {
            return value;
        });
        // eslint-disable-next-line
        for (let editRowColumn of editRowColumns) {
            switch (editRowColumn.type) {
                case 'DropDown':
                    {
                        if (editRowColumn.label === 'Action Required') {
                            let options = ActionRequired ?? [];
                            if (editColumnsDefaultValues.ActionRequiredName === "Pending") {
                                options = options.filter((option: { text: string; }) => option.text === "Resolved" || option.text === "Pending");
                            }
                            else if (editColumnsDefaultValues.ActionRequiredName === "Resolved") {
                                options = options.filter((option: { text: string; }) => option.text === "Resolved");
                            }
                            else if (isEmpty(editColumnsDefaultValues.ActionRequiredName)) {
                                options = ActionRequired.filter((option: { text: string; }) => option.text === "Resolved") ?? [];
                            }
                            tmpRenderObj.push(
                                <Dropdown
                                    label={editRowColumn.label}
                                    options={options}
                                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                    onChange={(ev, selected) => onDropDownChange(ev, selected, editRowColumn)}
                                    placeholder={"Select " + editRowColumn.label}
                                    required={false}
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                />
                            );
                        }
                        
                        else if (editRowColumn.label === 'Confidence Level') {
                            tmpRenderObj.push(
                                <Dropdown
                                    label={editRowColumn.label}
                                    options={ConfidenceLevel ?? []}
                                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                    onChange={(ev, selected) => onDropDownChange(ev, selected, editRowColumn)}
                                    placeholder={"Select " + editRowColumn.label}
                                    required={false}
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                />
                            );
                        }
                    }
                    break;
                case 'ChoiceGroup':
                    const options = editRowColumn.choiceGroupOptions ?? [];
                    const selectedKey = editColumnsDefaultValues[editRowColumn.key];
                        tmpRenderObj.push(
                            <ChoiceGroup
                                label={editRowColumn.label}
                                options={options}
                                selectedKey={selectedKey}
                                onChange={(ev, option) => SetObjValues(editRowColumn.key, option?.key)}
                                required={false}
                                styles={{
                                    flexContainer: {
                                        display: "flex",
                                        flexDirection: 'row'
                                    }
                                }}
                            />
                        );
                    
                    
                    break;
                case 'ReadOnly': 
                    // Check if the bulk edit button has not been clicked
                    if (!bulkEditButtonClicked) {
                        // Destructure the label and key from editRowColumn for easier access
                        const { label, key } = editRowColumn;
                    
                        // Retrieve the value associated with the current column key
                        const value = editColumnsDefaultValues[key];
                    
                        // Determine if the current field is for 'Comments' and is empty
                        const isCommentsEmpty = label === 'Comments' && key === 'Comments' && isEmpty(value);
                    
                        // Set display label to 'Annotations' if the label is 'Comments', otherwise use the column's label
                        const displayLabel = label === 'Comments' ? 'Annotations' : label;
                    
                        // Apply a specific height style only when dealing with an empty 'Comments' field
                        const heightStyle = isCommentsEmpty ? { height: '30px' } : undefined;
                    
                        // Push the Label component into the tmpRenderObj array
                        tmpRenderObj.push(
                            <Label>
                                {/* Display either 'Annotations' or the original label */}
                                {displayLabel}
                                
                                {/* Render the value within a disabled Label, with optional styling for empty 'Comments' */}
                                <Label className="readonyLabel" style={heightStyle} disabled>
                                    {value}
                                </Label>
                            </Label>
                        );
                    }
                    break;
               
                case 'TextArea':
                    tmpRenderObj.push(
                        <div style={{ gridColumn: "span 2" }}>
                        <TextField
                            label={editRowColumn.label}
                            defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                            multiline
                            resizable={false}
                            onChange={(event) => { onChangeText(event, event.target, editRowColumn); }}
                        /></div>
                    );
                    
                    break; 
                default:
            }
        }
        return tmpRenderObj;
    } 

    const disucussionDataSave =()=>
    {
        if(disussionData!==undefined && !isEmpty(disussionData.Discussion) )
        {
            return true;
        }
        return false;
    }
    const isDataChange = () => {
        const item = catalogAlignmentData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        const OriginalData = item;
       
        if(!bulkEditButtonClicked){
            if(editColumnsDefaultValues['Action'] !== OriginalData['Action']
            || editColumnsDefaultValues['IsMapped'] !== OriginalData['IsMapped']
            || editColumnsDefaultValues['ConfidenceLevelId'] !== OriginalData['ConfidenceLevelId']
            || editColumnsDefaultValues['Comments'] !== OriginalData['Comments']
            )
            {
                //when there is a change of data
                return true;
            }
            else{
                
                return false;
            }
        }
    }
   
    const isBulkSaveButton = ()=>{
        
        if(bulkEditButtonClicked &&  editColumnsDefaultValues['Action']===null && editColumnsDefaultValues['IsMapped']===null
             && editColumnsDefaultValues['ConfidenceLevelId']=== null && editColumnsDefaultValues['Comments']=== null 
            )
        {   
            return true;
        }
        else{
            return false;
        }
    }

    const handleEdit = () => { 
            if(editColumnsDefaultValues.IsLocked!=true && !bulkEditButtonClicked && (isDataChange())  ){
                handleValueChange(false);
                onEditPanelChange(refColumnsDefaultValues);
                }
                else if(bulkEditButtonClicked){
                    handleValueChange(false);
                    onEditPanelChange(refColumnsDefaultValues);
                }
                else if(disussionData!=undefined && !isEmpty(disussionData.Discussion) )
                {    
                handleValueChange(false);
                onSaveDiscussion(true); // API Call for discussions            
                }
    };
    const handleOnPanelXClose = () => {
        setshowEditGridPanel(false);
        setDisussionData([]);
    }
    //Pagination code start   
    const [catalogAlignmentPaginatedData, setCatalogAlignmentPaginatedData] = useState<any>([]);
    const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
    const updatePaginationData = (data: any) => {
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setCatalogAlignmentPaginatedData(data);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setCatalogAlignmentPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setCatalogAlignmentPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key));
        }
    }

    const getDataCountByActionType = (inputType: any) => {
        if (inputType === NotificationPivotOptions.ALLITEMS) {
            return isFilterApplied ? catalogAlignmentFilteredData.length : catalogAlignmentBeforeFilterData.length;
        }
        else if (inputType === NotificationPivotOptions.ACTIONREQUIRED) {
            return (isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key).length
        }
        else if (inputType === NotificationPivotOptions.ACTIONRESOLVED) {
            return (isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key).length
        }
    }
    const resetPagination = () => {
        setRefreshPagination(refreshPagination ? false : true);
    }
    //Pagination code end 
    //Pivot related code start
    const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ACTIONREQUIRED);
    const handlePivotChange = (item: any) => {
        resetSelection();
        if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
            setCatalogAlignmentData(isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData);
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)//sort bydefault   
                sortDefault(isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData);
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONREQUIRED) {
            setCatalogAlignmentData((isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)//sort bydefault   
                sortDefault((isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONRESOLVED) {
            setCatalogAlignmentData((isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)  //sort bydefault   
                sortDefault((isFilterApplied ? catalogAlignmentFilteredData : catalogAlignmentBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));
            else resetPagination();
        }
        setActiveTab(item.props.itemKey);
    };
    //Pivot related code end

    //Filter code start  
    const [catalogAlignmentBeforeFilterData, setCatalogAlignmentBeforeFilterData] = useState<any>([]);
    const [catalogAlignmentFilteredData, setCatalogAlignmentFilteredData] = useState<any>([]);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const onApplyFilter = (filteredArray: any) => {
        resetSelection();
        setIsFilterApplied(true);
        setCatalogAlignmentFilteredData(JSON.parse(JSON.stringify(filteredArray)));
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setCatalogAlignmentData(filteredArray);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setCatalogAlignmentData(filteredArray.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setCatalogAlignmentData(filteredArray.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        resetPagination();
    };
    const onClearFilter = () => {
        setIsFilterApplied(false);
        setCatalogAlignmentFilteredData([]);
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setCatalogAlignmentData(catalogAlignmentBeforeFilterData);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setCatalogAlignmentData(catalogAlignmentBeforeFilterData.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setCatalogAlignmentData(catalogAlignmentBeforeFilterData.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        resetPagination();
    }
    const createFilterColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type", "type": "dropdown", "options": [] },
            { "fieldName": "DataValue", "label": "Data Value", "type": "searchDropdown", "options": [] },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario", "type": "searchDropdown", "options": [] },
            { "fieldName": "ResolvedBy", "label": "Resolved By", "type": "searchDropdown", "options": [] },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date", "type": "date", "fromTargetCompletionDate": null, "toTargetCompletionDate": null },
            { "fieldName": "ConfidenceLevelName", "label": "Confidence", "type": "dropdown", "options": [] }
        ];
    }
    //Filter code end



    //Sort code start
    const [sortColumnDetails, setSortColumnDetails] = useState<any>({ "columnName": 'IsMapped', "sortType": 'desc' });
    const createSortColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type" },
            { "fieldName": "DataValue", "label": "Data Value" },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario" },
            { "fieldName": "IsMapped", "label": "Is Mapped" },
            { "fieldName": "ConfidenceLevelName", "label": "Confidence" },
            { "fieldName": "ResolvedBy", "label": "Resolved By" },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date" },
        ]
    };
    const sortDefault = (data: any) => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
            onSorted(sortColumnDetails["columnName"], sortColumnDetails["sortType"], data);
        }
    }
    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] === columnName) {
            return sortColumnDetails["sortType"] === "asc" ? "Ascending" : "Descending";
        }
    }
    const onSorted = (columnName: string, sortType: string, catalogAlignment?: any): void => {
        resetSelection();
        setSortColumnDetails({ "columnName": columnName, "sortType": sortType });
        let sortedData = (catalogAlignment !== undefined ? catalogAlignment : catalogAlignmentData).sort((a: any, b: any) => {
            if (a[columnName] > b[columnName]) {
                return sortType === 'asc' ? 1 : -1;
            }
            if (a[columnName] < b[columnName]) {
                return sortType === 'asc' ? -1 : 1;
            }
            return 0;
        });
        setCatalogAlignmentData(JSON.parse(JSON.stringify(sortedData)));
        resetPagination();
    }
    //Sort code end
    //  bulk edit code start 
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = useState<boolean>(false);
    const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]); 
    // onChange function to update isSelect state
    const handleSelectionCheckbox = (event: any, item: any) => {
        if (event.target.checked) {
            item.isSelected = true;
            setBulkEditSelectedData([...bulkEditSelectedData, item]);
        } else {
            if (isSelectedAll)
                setIsSelectedAll(false);
            item.isSelected = false;
            setBulkEditSelectedData(
                bulkEditSelectedData.filter((selectedItem: any) => selectedItem.Id !== item.Id)
            );
        }
        const updatedSelectedData = catalogAlignmentPaginatedData.map((item: any) => ({
            ...item,
            isSelected: item.isSelected
        }));
        setCatalogAlignmentPaginatedData([...updatedSelectedData]);
        catalogAlignmentData.forEach((element: any) => {
            if (element.Id === item.Id) {
                element.isSelected = item.isSelected
            }
        });
        setCatalogAlignmentData([...catalogAlignmentData]);
    };
    // onChange function to update select all checkbox
    const handleSelectAllCheckbox = (event: any) => {
        setIsSelectedAll(event.target.checked);
        //update paginated records
        const updatedSelectedData = catalogAlignmentPaginatedData.map((item: any) => ({
            ...item,
            isSelected: (item.IsLocked==true) ? false : event.target.checked,
        }));
        setCatalogAlignmentPaginatedData([...updatedSelectedData]);
        //update all recrods
        const updatedSelectedData1 = catalogAlignmentData.map((item: any) => ({
            ...item,
            isSelected: (item.IsLocked==true) ? false : event.target.checked,
        }));
        setCatalogAlignmentData([...updatedSelectedData1]);
        const updateBulkUpdateData = event.target.checked ? updatedSelectedData1.filter((x: any) => x.isSelected === true) : [];
        setBulkEditSelectedData([...updateBulkUpdateData])
    };
    const resetSelection = (): any => {
        setIsSelectedAll(false);
        setBulkEditSelectedData([]);
        catalogAlignmentData.forEach((element: any) => {
            element.isSelected = false;
        });
        setCatalogAlignmentData([...catalogAlignmentData]);
    }
   
    const onClickCommandBarsButtonsEvents = (source: any) => {
        if(source=='Bulk Edit'){
            setBulkEditButtonClicked(true);  
            if (bulkEditSelectedData.length > 0) {
                let obj = JSON.parse(JSON.stringify(bulkEditSelectedData[0]));
                Object.keys(obj).forEach(key => {
                    obj[key] = null;
                });
                seteditColumnsDefaultValues(obj);
              
            }
            setshowEditGridPanel(true);
        }

        else if(source==='Lock'){
            setToastTitle("Catalog Alignment");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true); 
            setTimeout(() => {
                setShowToast(false);
            }, 8000);
            
            setLockButtonCatalogAlignmentManaged(false);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bulkEditSelectedData),
                mode: 'cors'
            };
            DOMEApiHelper('CatalogAlignment/LockCatalogAlignmentDataUI', requestOptions)
                .then(res => {            
                    if (res.Message === "Success") {      
                        LoadCatalogAlignmentData("Loading data", PORId);           
                        setToastType(CoherenceNotificationType.SUCCESS);
                        setToastMessage("Record(s) are locked successfully.");
                        setShowToast(true);
                        setTimeout(() => {
                            setShowToast(false);
                            
                        }, 4000);
                        
                    }
                    setLockButtonCatalogAlignmentManaged(true);
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    setIsLoaded(false);
                    setLockButtonCatalogAlignmentManaged(true);
                }
            );
            
        }

        }
    
        const generateButtons = (): any => {
            const _items: ICommandBarItemProps[] = [{
                    key: 'Bulk Edit',
                    text: 'Bulk Edit',
                    ariaLabel: 'Bulk Edit',
                    iconProps: { iconName: 'Edit' },
                    onClick: () => { 
                        
                    },
                    disabled: bulkEditSelectedData.length > 1 ? false : true,
                }];

                if (userRole.includes("Admin")) {
                    _items.push({
                        key: 'Lock',
                        text: 'Lock',
                        ariaLabel:"Lock Button",
                        iconProps: { iconName: 'LockSolid' },
                        onClick: () => {
                            
                        },
                        disabled: (LockButtonCatalogAlignmentManaged &&  bulkEditSelectedData.length>0 ) ? ( bulkEditSelectedData.every((item: any) => item.IsMapped === true && item.ActionRequiredName !== 'Pending' && (item.ConfidenceLevelName !== '' &&  item.ConfidenceLevelName !== 'Low')) ? false : true) : true  ,    
                    });
                }

            return _items;
        }
    //  bulk edit code end


    
    //discussion code start 
    const [disussionData, setDisussionData] = useState<any>();  
    const isDisabledDiscussion=() =>{
        return (isEmpty(disussionData) || isEmpty(disussionData.Discussion) ) ? true : false;
    }
  

    const onValidDiscussionData = (disussionData: any, discussion: any) => {
        setDisussionData(disussionData);
    }

  

    const onSaveDiscussion = (showToast:any) => {  
        let discussionDataList : any =[];  
        discussionDataList.push(disussionData);
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(discussionDataList),
          mode: "cors",
        };
         if(showToast){
            setIsLoaded(true);
            setToastTitle("Catalog Alignment");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true); 
        } 
        DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
          .then(res => {
            if(showToast)  {
                setIsFilterApplied(false);//reset filter
                setToastType(CoherenceNotificationType.SUCCESS);
                setToastMessage("Record is updated successfully.");
                setTimeout(() => {
                    setShowToast(false);
                }, 1000);
                LoadCatalogAlignmentData("Loading data",PORId);
            }  
            if (res.Message === "Success") { 
              setDisussionData([]); 
            }
          }).catch(res => {
            setToastMessage(res.toString());
            setToastType(CoherenceNotificationType.STANDARD);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });
   } 
    //discussion code end

    return (
        <>
            
          <CustomGridControls  defaultFilterSessionKey="ManagedCatalogAlignmentDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()} filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={catalogAlignmentBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
             
            {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
            <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
                <PivotItem
                    headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                    itemKey={NotificationPivotOptions.ALLITEMS}
                    ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid
                            listConfig={getColumns()}
                            data={catalogAlignmentPaginatedData}
                            isScrollable={true}
                            isSortable={false}
                            sortByDefault={false}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Catalog Alignment All Items List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"
                    }
                    itemKey={NotificationPivotOptions.ACTIONREQUIRED}
                    ariaLabel={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()}
                            data={catalogAlignmentPaginatedData}
                            isScrollable={true}
                            ariaLabelForSelectAllCheckbox={'select all checkbox'}
                            isSortable={false}
                            sortByDefault={false}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Catalog Alignment Action Required List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                    itemKey={NotificationPivotOptions.ACTIONRESOLVED}
                    ariaLabel={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ?
                            <CoherenceDataGrid
                                listConfig={getColumns()}
                                data={catalogAlignmentPaginatedData}
                                isScrollable={true}
                                ariaLabelForSelectAllCheckbox={'select all checkbox'}
                                isSortable={false}
                                sortByDefault={false}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                ariaLabel={"Catalog Alignment Action Resolved List"}
                            ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
            </Pivot>
            <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={catalogAlignmentData} pageSize={8}></CustomPagination>
            <CoherencePanel
                title={bulkEditButtonClicked ? "Bulk Edit for " + bulkEditSelectedData.length + " Selected Rows" : "Edit Row Details"}
                titleText={bulkEditButtonClicked ? "Bulk Edit for " + bulkEditSelectedData.length + " Selected Rows" : "Edit Row Details"}
                isOpen={showEditGridPanel}
                onDismiss={handleOnPanelXClose}
                panelSize={bulkEditButtonClicked ? CoherencePanelSize.small : CoherencePanelSize.medium}
                closeButtonAriaLabel="Close"
                onRenderFooter={() => (
                    <Stack horizontal >
                        <PrimaryButton text="Save" onClick={handleEdit} disabled={editColumnsDefaultValues.IsLocked==true || isBulkSaveButton() ||(!bulkEditButtonClicked && !isDataChange()&& isDisabledDiscussion())  }  />
                        <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
                    </Stack>
                )}>
                <div  style={bulkEditButtonClicked ? {} : { display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem" }} >
                    {showEditGridPanel && generateControls()}
                </div>
                {bulkEditButtonClicked ?  <span></span>:
                <NonManagedDiscussions discussionId={editColumnsDefaultValues!=undefined ? editColumnsDefaultValues.Id:0} discussionType="CatalogAlignment" onValidDiscussionData={onValidDiscussionData} isExpand={false} ></NonManagedDiscussions>
                }
            </CoherencePanel> 
            <Dialog
                hidden={!showComments}
                onDismiss={handleHideComment}
                dialogContentProps={{
                    type: DialogType.close,
                    title: 'Annotations',
                    closeButtonAriaLabel: 'Close',
                }}>
                <div>{selectedComment}</div>
            </Dialog>
        </>
    );
};
export default ManagedCatalogAlignment;
