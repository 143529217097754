import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType,  CoherencePanel, CoherencePanelSize} from "@coherence-design-system/controls";
import {  DefaultButton, Dialog, DialogType,  Dropdown, FontIcon, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, Stack, TextField, TooltipHost, Checkbox, ChoiceGroup,    Link,  ICommandBarItemProps, CommandBar,DetailsListLayoutMode ,MessageBar, MessageBarType, DatePicker } from "@fluentui/react";
import dayjs from "dayjs"; 
import React, { useEffect,  useState } from "react";
import { DOMEApiHelper, convertToCsv, isEmpty, onFormatDate } from "../../../utils/DOMEApiHelper";
import { GetUserRole } from "../../../utils/GetUserRole"
import ToastMessage from "../../../shared/ToastMessage/ToastMessage";
import UtilDta from "../../../utils/UtilCommon.json";
import { NotificationPivotOptions } from "../../../shared/AppHeader/AppHeader.utils"; 
import CustomGridControls from "../../../shared/CustomGridControls/CustomGridControls";
import CustomPagination from "../../../shared/CutomPagination/CustomPagination";
import type { EditorPlugin } from 'roosterjs';
import { CustomRTEPlugin } from "../../../shared/CustomRTEPlugin/CustomRTEPlugin"; 
import { ManagedRequirementDefinitionStyles } from "../ManagedRequirementDefinition/ManagedRequirementDefinition.styles";
import { PrimaryContactPicker } from "../../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { Persona } from '@fluentui/react/lib/Persona';
import { useLocation } from "react-router-dom";
import NonManagedDiscussions from "../../NonManaged/NonManagedDiscussions/NonManagedDiscussions";
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";

const ManagedRequirementDefinition: React.FunctionComponent = () => {
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false);
    const [userRole, setUserRole] = useState(GetUserRole());
    const [isLoaded, setIsLoaded] = useState(false);
    const [LoadText, setLoadText] = useState<string>("");
    const [requirementDefinitionData, setRequirementDefinitionData] = useState<any>([]);
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const [showEditGridPanel, setshowEditGridPanel] = React.useState<boolean>(false);
    const [refColumnsDefaultValues, setRefColumnsDefaultValues] = useState<any>(null);
    const [showComments, setShowComments] = useState<any>();
    const [selectedComment, setSelectedComment] = useState<string>("");
    const [FuncScenario, setFuncScenario] = useState<any>([]);
    const [ActionRequired, setActionRequired] = useState<any>([]);
    const [ConfidenceLevel, setConfidenceLevel] = useState<any>([]);
    const [AffectedAttributesTypes, setAffectedAttributeTypes] = useState<any>([]); 
    const [showRichText, setshowRichText] = useState<any>();
    const [selectedRichText, setSelectedRichText] = useState<string>("");
    const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
    const [attributeValueErrorMessage, setAttributeValueErrorMessage] = useState<string>("");
    const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
    const [assignToErr, setAssignToErr] = React.useState<boolean>(false);
    const [AssignedTo, setAssignedTo] = useState<any>([]); 
    const [AssignedToRequired, setAssignedToRequired] = React.useState<boolean>(false);
    const [modalOpen, setmodalOpen] = useState<boolean>(false);
    const [PipelineFuncScenario, setPipelineFuncScenario] = useState<any>([]);
    const [AffectedDataTypeId, setAffectedDataTypeId] = useState<any>([]);
    const [AffectedAttributeId, setAffectedAttributeId] = useState<any>([]);    
    const [PipelineStatus, SetPipelineStatus] = useState<any>({"PipelineName":"", "RunStart":"", "RunEnd":"","DurationInMs":"","InvokedBy":"", "Status": "", "RunId":""});
    const [DisableRunPipelineButton, setDisableRunPipelineButton] = useState<boolean>(true);
    const [PORId, setPORId] = useState<any>();
    const location = useLocation();
    const [LockButtonRequirementDefinition, setLockButtonRequirementDefinition] = useState<boolean>(true);
    const [importErrors, setImportErrors] = useState<any>([]);


    //methods
    const handleComments = () => {
        setShowComments(true);
    }
    const handleHideComment = () => {
        setShowComments(false);
    }
    const handleInfo = () => {
        setshowRichText(true);
    }
    const handleHideInfo = () => {
        setshowRichText(false);
    }

   
    const handleValueChange = (newValue: boolean | ((prevState: boolean) => boolean)) => {
        setshowEditGridPanel(newValue);
    };

    let IdVal: number;
    const onEditPanelChange = (item: any): void => {

        for (let key in editColumnsDefaultValues) {
            if (key === "Id") {
                IdVal = editColumnsDefaultValues[key];
            }
        }
        updateRequirementDefinition(IdVal); // api call
    };

    const resetPipeline = (pipelineName:any) => {
        let resetStatus = { PipelineName:pipelineName ,"RunStart":"", "RunEnd":"",  "DurationInMs":"", "InvokedBy":"", "Status": "", "RunId":""}
        SetPipelineStatus(resetStatus);
        setDisableRunPipelineButton(false);
    };
    const fetchPipelineRunDetails = (functioalscenarioid: any, affecteddatatypeid:any,affectedAttributeId:any) : any =>{
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
        };
        DOMEApiHelper('DataPlatform?functionalScenarioId='+ functioalscenarioid +'&affectedDataTypeId=' + affecteddatatypeid+'&affectedAttributeId=' + affectedAttributeId, requestOptions)
                 .then(res => {
                    if(!isEmpty(res) && !isEmpty(res.Value)){ 
                        //Duration time conversion from ms to mins
                        var duration = "--"
                        if(res.Value[0].RunId!=null){
                            if(res.Value[0].DurationInMs){
                                var ms = res.Value[0].DurationInMs % 1000;
                                res.Value[0].DurationInMs = (res.Value[0].DurationInMs - ms) / 1000;
                                var secs = res.Value[0].DurationInMs % 60;
                                res.Value[0].DurationInMs = (res.Value[0].DurationInMs - secs) / 60;
                                var mins = res.Value[0].DurationInMs % 60;
                                var hrs = (res.Value[0].DurationInMs - mins) / 60;
                              
                                duration = hrs > 0 ? hrs + 'h ' + mins + 'm ' + secs + 's ' : mins + 'm ' + secs + 's ' 
             
                                }
                                let laststatus = {
                                    "PipelineName":res.Value[0].PipelineName, 
                                    "RunStart":res.Value[0].RunStart,
                                     "RunEnd":res.Value[0].RunEnd,
                                     "DurationInMs":duration,
                                     "InvokedBy": res.Value[0].InvokedBy.Name === "Manual"  ? (!isEmpty(res.Value[0].Parameters.TriggeredBy) ? res.Value[0].Parameters.TriggeredBy : res.Value[0].InvokedBy.Name)
                                      : res.Value[0].InvokedBy.Name,                          
                                     "Status": res.Value[0].Status == "InProgress" ? "In Progress" :  res.Value[0].Status,
                                     "RunId":res.Value[0].RunId
                                    }  
                                    setDisableRunPipelineButton(laststatus.Status==='In Progress'?true:false)
                                    SetPipelineStatus(laststatus);
                        
                    } else{
                        resetPipeline(res.Value[0].PipelineName);
                    } 
                    } else{
                        resetPipeline("");
                    }

                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    setIsLoaded(false);
                }
            );
    }
    
    const openPipelineModal = (item: any) => { 
        setPipelineFuncScenario(item.FunctionalScenarioId.toString()) 
        setAffectedDataTypeId(item.AffectedDataTypeId.toString())
        setAffectedAttributeId(item.AffectedAttributeId.toString())
        fetchPipelineRunDetails(item.FunctionalScenarioId, item.AffectedDataTypeId,item.AffectedAttributeId)
        setmodalOpen(true);  
      };
      
    // eslint-disable-next-line
    const [editRowColumns, seteditRowColumns] = useState<any>(
        [
            { "isEditable": true, "type": "ReadOnly", "label": "Data Type", "key": "DataType" },
            { "isEditable": true, "type": "ReadOnly", "label": "Data Value", "key": "DataValue" },
            { "isEditable": true, "type": "ReadOnly", "label": "Functional Scenario", "key": "FunctionalScenario" },
            { "isEditable": true, "type": "ReadOnly", "label": "Attribute Name", "key": "AttributeName" },
            { "isEditable": true, "type": "DropDown", "label": "Attribute Value", "key": "AttributeValue" },
            { "isEditable": true, "type": "DropDown", "label": "Action Required", "key": "ActionRequired" },
            { "isEditable": true, "type": "DropDown", "label": "Confidence Level", "key": "ConfidenceLevelId" },
            { "isEditable": true, "type": "ChoiceGroup", "label": "Input Required", "key": "IsNoInputRequired", "choiceGroupOptions": [{ key: false, text: 'Yes' }, { key: true, text: 'No' },] },
            { "isEditable": true, "type": "ReadOnly", "label": "Comments", "key": "Comments" },
           
        ]
    );

    // eslint-disable-next-line 
    
    const getColumns = (): CoherenceDataGridColumn<any>[] => {

        var advancedColumns: CoherenceDataGridColumn<any>[] = [
            {
                key: 'selectAll',
                name: 'Select All',
                fieldName: 'SelectAll',
                type: 'string',
                isResizable: false, 
                minColumnWidth: 20,
                maxWidth:20,
                ariaLabel: 'Select All', 
                data: [],
                onRender: (item: any) => { 
                        return   <Checkbox  ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} disabled={item.IsLocked==true && item.ActionRequiredName!==UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key}  checked={item.isSelected}/>;
                },
                onRenderHeader: (item: any) => { 
                    return   <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }}  onChange={(event) => handleSelectAllCheckbox(event)}  checked={isSelectedAll}/>;
            },
            },
            {
                key: 'Actions',
                name: 'Edit',
                type: 'actions',
                isResizable: false,
                menuActions: [],
                minColumnWidth: 40,
                maxWidth:40,
                data: [],
                visibleActions: [
                    {
                        onClick: (item) => {
                            setAssignToErr(false);
                            setAssignedToRequired(true);
                            let value = item.AssignedTo !== ""? [{ "text": item.AssignedToUserName, "objectId": item.AssignedTo }] : []
                            setAssignedTo(value);
                            setBulkEditButtonClicked(false); 
                            seteditColumnsDefaultValues(item);
                            setshowEditGridPanel(true);
                            setAttributeValueErrorMessage("");
                        },
                        key: 'edit',
                        text: 'Action',
                        ariaLabel: 'Edit',
                        iconProps: {
                            iconName: 'Edit',
                            style: { color: "#0078d4" }
                        }
                    }
                ]
            },
            {
                key: 'DataType',
                name: 'Data Type',
                fieldName: 'DataType',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Data Type',
                minColumnWidth: 90,
                maxWidth:90,
                data: [],
                onRender: (item: any) => {
                    if (item.IsLocked === true && item.ActionRequiredName!==UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key) {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)', marginLeft: '-14px'}}>
                                <FontIcon iconName="LockSolid" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500}}></FontIcon>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                    else {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)'}}>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                },
                iconName: appendSortIcon('DataType'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'DataValue',
                name: 'Data Value',
                fieldName: 'DataValue',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Data Value',
                minColumnWidth: 90,
                maxWidth:90,
                data: [],
                iconName: appendSortIcon('DataValue'),
                iconClassName: "customSortIcon"

            }, 
            {
                key: 'FunctionalScenario',
                name: 'Functional Scenario',
                fieldName: 'FunctionalScenario',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Functional Scenario',
                minColumnWidth: 170,
                maxWidth:170,
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.FunctionalScenario}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.FunctionalScenario}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('FunctionalScenario'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'Rule Execution',
                name: 'Rule Execution',
                fieldName: 'Rule Execution',
                type: 'link',
                url: 'url',
                isResizable: true,
                minColumnWidth: 120,
                maxWidth:120,
                ariaLabel: 'Rule Execution',
                data: [],
                onRender: (item: any) => { 

                    return  <TooltipHost  content={"Click here to generate attribute values."}><span style={{display: 'flex', justifyContent: 'space-evenly', cursor: "pointer"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" onClick={(event) => { 
                    setDisableRunPipelineButton(true);
                    SetPipelineStatus({"RunStart":"", "Status": ""});
                    openPipelineModal(item) }} fill="#0078d4" width={"20px"} >
                    <path d="M2048 1024q0 52-4 108t-14 112-25 111-37 102q-17 36-39 73t-53 67-66 48-82 19h-352v-3q-17 3-32 3-32 0-60-10t-52-29-46-41-37-48H901q-17 26-37 49t-46 40-53 28-61 11H320q-45 0-81-18t-67-49-53-67-39-73q-22-48-37-102t-24-110-14-113-5-108q0-52 4-108t14-112 25-111 37-102q16-36 39-73t53-67 66-48 82-19h384q32 0 60 10t54 28 45 41 38 49h248q16-25 37-48t45-41 53-28 60-11h384q45 0 81 18t67 49 52 67 40 73q22 48 37 102t24 110 14 113 5 108zM320 1536q21 0 40-14t35-35 28-43 20-39q19-42 32-89t21-97 12-100 4-95q0-45-3-95t-12-99-22-97-32-90q-7-16-19-38t-29-43-35-35-40-15q-21 0-40 14t-35 35-28 43-20 39q-19 42-32 89t-21 97-12 100-4 95q0 45 3 95t12 99 22 97 32 90q7 16 19 38t29 43 35 35 40 15zm576-512q0-45-3-95t-12-99-22-97-32-90q-7-16-19-38t-29-43-35-35-40-15H517q34 53 58 116t38 130 20 136 7 130q0 62-6 130t-21 135-38 131-58 116h187q21 0 40-14t35-35 28-43 20-39q19-42 32-89t21-97 12-100 4-95zm448 512q21 0 40-14t35-35 28-43 20-39q19-42 32-89t21-97 12-100 4-95q0-45-3-95t-12-99-22-97-32-90q-7-16-19-38t-29-43-35-35-40-15q-20 0-38 14t-34 34-28 43-21 37H963q32 91 46 189t15 195q0 97-14 195t-47 189h260q8 15 20 37t28 42 34 35 39 14zm576-512q0-45-3-95t-12-99-22-97-32-90q-7-16-19-38t-29-43-35-35-40-15h-187q34 53 58 116t38 130 20 136 7 130q0 62-6 130t-21 135-38 131-58 116h187q21 0 40-14t35-35 28-43 20-39q19-42 32-89t21-97 12-100 4-95z" />
                            </svg></span></TooltipHost> 
                    }
                }, 
            {
                key: 'ActionRequired',
                name: 'Action Required',
                fieldName: 'ActionRequired',
                type: 'number',
                isResizable: true,
                ariaLabel: 'Action Required',
                className: 'cellLeftAligned customGridCell',
                minColumnWidth: 100,
                maxWidth:100,
                onRender: (item: any) => {
                    switch (item.ActionRequiredName) {
                        case UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Recent" style={{ color: 'rgba(215, 59, 2, 1)', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Completed" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="ProgressRingDots" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key}</span>
                            }
                    }
                },
                data: []
            },
            {
                key: 'IsNoInputRequired',
                name: 'Input Required',
                fieldName: 'IsNoInputRequired',
                type: 'boolean',
                isResizable: true,
                ariaLabel: 'Input Required',
                minColumnWidth: 110,
                maxWidth: 110,
                onRender: (item: any) => {
                    switch (item.IsNoInputRequired) {
                        case false:
                            return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left' }} className="customGridCell">
                                <FontIcon iconName="RadioBtnOn" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                            </span>
                        case true  || null :
                            return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left' }} className="customGridCell">
                                <FontIcon iconName="RadioBtnOff" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                            </span>
                    }
                }, 
                data: []
            },
            {
                key: 'AttributeName',
                name: 'Attribute Name',
                fieldName: 'AttributeName',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Attribute Name',
                minColumnWidth: 120,
                maxWidth: 120,
                data: [],
                iconName: appendSortIcon('AttributeName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'AttributeValue',
                name: 'Attribute Value',
                fieldName: 'AttributeValue',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Attribute Value',
                minColumnWidth: 150,
                maxWidth: 150,
                data: [],
                onRender: (item: any) => {
                    if (item.AffectedAttributeInputType === "multiline") {
                        return <Link
                            aria-label="Click to view value"
                            onClick={() => {
                                setSelectedRichText(item.AttributeValue);
                                setshowRichText(true);
                                handleInfo();
                            }}
                            style={{ color: "#0078d4", height: '22px' }}
                        >{"Click to View Value"}</Link>
                    }
                    else if(item.AffectedAttributeInputType==="DATE" && !isEmpty(item.AttributeValue) && dayjs(item.AttributeValue).isValid()){
                        return  <TooltipHost content={item.AttributeValue}>
                              <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                              {dayjs(item.AttributeValue).format('MM/DD/YYYY')} 
                              </span>
                          </TooltipHost>
                      } 
                    else {
                        return <TooltipHost content={item.AttributeValue}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                                {item.AttributeValue}
                            </span>
                        </TooltipHost>
                    }
                },
            },
            {
                key: 'ConfidenceLevelName',
                name: 'Confidence',
                fieldName: 'ConfidenceLevelName',
                type: 'string',
                isResizable: true,
                ariaLabel: 'Confidence',
                minColumnWidth: 90,
                maxWidth: 90,
                data: [],
                iconName: appendSortIcon('ConfidenceLevelName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'Comments',
                name: 'Annotations',
                type: 'actions',
                menuActions: [],
                minColumnWidth: 90,
                maxWidth: 90,
                data: [],
                visibleActions: [
                    {
                        onClick: (item) => {
                            setSelectedComment(item.Comments);
                            setShowComments(true);
                            handleComments();
                        },
                        key: 'comment',
                        text: 'Annotations',
                        ariaLabel: 'Annotations',
                        iconProps: { iconName: 'Comment', style: { color: "#0078d4" } }
                    }
                ]
            },
            {
                key: 'ModifiedBy',
                name: 'Modified By',
                fieldName: 'ModifiedBy',
                type: 'persona',
                isResizable: true,
                imageUrl: 'imageUrl',
                minColumnWidth: 200,
                maxWidth: 200,
                ariaLabel:'Resolved By',
                personaAlt: (item) => `Profile image for ${item.fullName}`,
                data: [],
                iconName: appendSortIcon('ModifiedBy'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ResolvedBy',
                name: 'Resolved By',
                fieldName: 'ResolvedBy',
                type: 'persona',
                isResizable: true,
                imageUrl: 'imageUrl',
                minColumnWidth: 200,
                maxWidth: 200,
                ariaLabel:'Resolved By',
                personaAlt: (item) => `Profile image for ${item.fullName}`,
                data: [],
                iconName: appendSortIcon('ResolvedBy'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'TargetCompletionDate',
                name: 'Target Completion Date',
                fieldName: 'TargetCompletionDate',
                type: 'date',
                isResizable: true,
                minColumnWidth: 145,
                maxWidth: 145,
                ariaLabel:'Target Completion Date',
                data: [],
                onRender: (item: any) => {
                    return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                        {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
                },
                iconName: appendSortIcon('TargetCompletionDate'),
                iconClassName: "customSortIcon"
            }
        ];  
        return advancedColumns;
    };

    const onChangeText = (e: any, text: any, _item: any) => {
        setAttributeValueErrorMessage("");
        SetObjValues(_item.key, text.value);
    }
    const SetObjValues = (key: string, value: any, isChanged: boolean = true, errorMessage: string | null = null): void => {
        setAttributeValueErrorMessage("");
        seteditColumnsDefaultValues({ ...editColumnsDefaultValues, [key]: value })
    }
    const onDropDownChange = (event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
        setAttributeValueErrorMessage("");
        SetObjValues(item.key, selectedDropdownItem?.key);
    }
    function handleMultiSelectDropdownChangeFactory(object: any) {
        
        return function handleDropdownChange(event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
            if (!option) return;
            setAttributeValueErrorMessage("");
            const selectedValues = editColumnsDefaultValues[object.key]
                ? editColumnsDefaultValues[object.key].split(',')
                : [];
            

            const optionIndex = selectedValues.indexOf(option.key as string);
            if (optionIndex >= 0) {
                selectedValues.splice(optionIndex, 1);
            } else {
                selectedValues.push(option.key as string);
            }

            const newFilterValues = {
                ...editColumnsDefaultValues,
                [object.key]: selectedValues.join(','),
            };
            seteditColumnsDefaultValues(newFilterValues);

           
        };
    }
    const handleRichTextChange = (value: string | undefined): void => {
        setAttributeValueErrorMessage("");
        seteditColumnsDefaultValues({
            ...editColumnsDefaultValues,
            "AttributeValue": value
        });
    }
    const requestOptionsGETALL = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
    };

    const LoadRequirementDefinitionData = (text: string,PORId :any) => {
        setIsLoaded(true);
        setLoadText(text);
        setRequirementDefinitionFilteredData([]);
        resetSelection();
        setActiveTab(NotificationPivotOptions.ACTIONREQUIRED);
        DOMEApiHelper('PORDetails/GetManagedRequirementDefinition?PORId=' + PORId, requestOptionsGETALL)
            .then(res => {
                
               
                if (res !== undefined && res.length > 0) { 
                    //to add isSelected for all the rows to use for bulk edit checkbox selection
                      res[0]["RequirementDefinitionList"].forEach((item:any, index:any) => {
                        item.isSelected =false; 
                      });
                      //bydefault show action required data.
                    let requirementDefinitionActionRequiredData=res[0]["RequirementDefinitionList"].filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("InProgress"))[0].key);                                   
                    setRequirementDefinitionData(requirementDefinitionActionRequiredData);       
                    setRequirementDefinitionBeforeFilterData(res[0]["RequirementDefinitionList"]); //store data to store before filter  
                    setFuncScenario(
                        res[0]["FunctionalScenariosList"].map((item: any) => {
                            return {
                                key: item.Id,
                                text: item.Title
                            }
                        }));
                    setActionRequired(
                        res[0]["ActionRequiredList"].map((item: any) => {
                            return {
                                key: item.Id,
                                text: item.Title
                            }
                        }));
                    setConfidenceLevel(
                        res[0]["ConfidenceList"].map((item: any) => {
                            return {
                                key: item.Id,
                                text: item.Title
                            }
                        }));
                    setAffectedAttributeTypes(res[0]["AttributeValues"])
                    setRefColumnsDefaultValues(editColumnsDefaultValues);
                       //sort bydefault 
                        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
                            sortDefault(requirementDefinitionActionRequiredData) 
                        }
                        else {
                            resetPagination();       
                        }                        
                }
                setIsLoaded(false);
                setLoadText(""); 
            }).catch(error => {
                setLoadText("");
                setIsLoaded(false);
                setToastTitle("Error while fetching requirement definition data  ");
                setToastMessage(error.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            });
    }

    useEffect(() => {

        const routerName = location.pathname.split('/')[1];
        if (routerName === 'ScopeDefinition') {
          const idStartIndex = window.location.href.lastIndexOf('/');
          const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
          if (id) {
            setIsLoaded(true);
            setPORId(parseInt(id));
            LoadRequirementDefinitionData("Loading data",id)    
          }
        } 
        // eslint-disable-next-line
    }, [setRequirementDefinitionData,location]);

    interface UpdateRequirementDefinitionModel {
        Id: number;
        ActionRequired?: any | null;
        IsNoInputRequired?: number | null;
        ConfidenceLevelId?: number | null;
        AttributeValue?: string | null;
        Comments?: string | null;
        ResolvedBy?: string | null;
        ModifiedBy?: string | null;
        AssignedTo?: string|null
    }

    const lockedStatus = () => {
        if(editColumnsDefaultValues['IsLocked']===true && editColumnsDefaultValues['ActionRequiredName']===UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key)
        {
            return false;
        }
        else if(editColumnsDefaultValues['IsLocked']===true && editColumnsDefaultValues['ActionRequiredName']!==UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const updateRequirementDefinition = (Id: number) => {
        
        if(bulkEditButtonClicked && bulkEditSelectedData.filter((x: any)=>x.IsLocked===true && x.ActionRequiredName===UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key).length>0){      
            setToastTitle("Requirement Definition");
                setToastMessage(`One or more selected record(s) are in lock period, please update the TargetCompletionDate and try again`);
                setToastType(CoherenceNotificationType.ERROR);
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            return;
        }
        else if(editColumnsDefaultValues['IsLocked']===true && editColumnsDefaultValues['ActionRequiredName']===UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key){
            setToastTitle("Requirement Definition");
            setToastMessage("Please update the TargetCompletionDate in Data Monitoring Issues section as the record is in lock period and try again.");
            setToastType(CoherenceNotificationType.ERROR);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
            return;
        }
        handleValueChange(false);

        var userID = sessionStorage.getItem("localAccountId");
        const item = requirementDefinitionData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        const OriginalData = item;
      
        const actionResolved = ActionRequired.filter((option: { text: string; }) => option.text === "Resolved"); //returns {key:'ID', text:'Name'}

        const updateModifiedBy = (data:any) => { 
            data['ModifiedBy'] = userID;  
            return data;
        }
        let inputList:any=[];
        let data: UpdateRequirementDefinitionModel ={
            Id: editColumnsDefaultValues['Id']
        };  

        // if save call is through bulk edit then check if respective property is not null and bulkeditbuttonclick is true and if bulkeditbuttonclick is false then perform single edit
        if ((editColumnsDefaultValues['ActionRequired'] !== null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['ActionRequired'] !== OriginalData['ActionRequired'])) {
            data['ActionRequired'] = editColumnsDefaultValues['ActionRequired'];
            data=updateModifiedBy(data);
        }
        if ((editColumnsDefaultValues['IsNoInputRequired'] !== null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['IsNoInputRequired'] !== OriginalData['IsNoInputRequired'])) {
            data['IsNoInputRequired'] = editColumnsDefaultValues['IsNoInputRequired'];
            data=updateModifiedBy(data);
        }
        if ((editColumnsDefaultValues['ConfidenceLevelId'] !== null && bulkEditButtonClicked) ||(!bulkEditButtonClicked && editColumnsDefaultValues['ConfidenceLevelId'] !== OriginalData['ConfidenceLevelId'])) {
            data['ConfidenceLevelId'] = editColumnsDefaultValues['ConfidenceLevelId'];
            data=updateModifiedBy(data);
        }
        if ((editColumnsDefaultValues['Comments']!== null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['Comments'] !== OriginalData['Comments'])) {
            data['Comments'] = editColumnsDefaultValues['Comments'];
            data=updateModifiedBy(data);
        }
        if (((editColumnsDefaultValues['ActionRequired'] !== null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['ActionRequired'] !== OriginalData['ActionRequired'])) && editColumnsDefaultValues['ActionRequired'] === actionResolved[0].key) {
            data['ResolvedBy'] = userID;
            data=updateModifiedBy(data);
        }   
        if (!bulkEditButtonClicked && editColumnsDefaultValues['AttributeValue']!==undefined && editColumnsDefaultValues['AttributeValue'] !== OriginalData['AttributeValue']) {
            data['AttributeValue'] = editColumnsDefaultValues['AttributeValue'];
            if (data['AttributeValue'] === undefined) {
                data['AttributeValue'] = "";
            }
            data=updateModifiedBy(data);
        }  
        if(bulkEditButtonClicked && AssignedTo.length > 0){
            bulkEditSelectedData.forEach((obj: any) => {
                data["Id"] = obj.Id;
                data["AssignedTo"] = AssignedTo.length>0? AssignedTo[0].objectId: ""
                data = updateModifiedBy(data);
        })

        }
        else if(AssignedTo.length >0 && AssignedTo[0].objectId !== "" && AssignedTo[0].objectId !== editColumnsDefaultValues.AssignedTo)
        {
            data['AssignedTo'] = AssignedTo.length>0? AssignedTo[0].objectId: ""
            data = updateModifiedBy(data);
        }
   
        
        if(bulkEditButtonClicked){ 
            bulkEditSelectedData.forEach((obj:any) => {
                data["Id"]= obj.Id;
                inputList.push(JSON.parse(JSON.stringify(data)));
            })
        }
        else{
            inputList.push(data);
        }    

        if (inputList.length>0 && Object.keys(inputList[0]).length > 1) {
           
            setIsLoaded(true);
            setToastTitle("Requirement Definition");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true); 
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputList),
                mode: 'cors'
            };
            DOMEApiHelper('RequirementDefinition', requestOptions)
                .then(res => {
                    if( discussionData!=undefined && !isEmpty(discussionData.Discussion) )
                    {    
                    handleValueChange(false);
                    onSaveDiscussion(false);            
                    }
                    setIsFilterApplied(false);//reset filter
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage("Record is updated successfully.");
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    LoadRequirementDefinitionData("Loading data",PORId);
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    setIsLoaded(false);
                }
            );
        }    
        else {
            setToastTitle("Requirement Definition");
            setToastMessage("No changes have been made");
            setToastType(CoherenceNotificationType.NONE);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false);
            }, 1000);

        }
    }

    //plugin code start 
    const getPlugins = () => {
        let plugins: EditorPlugin[] = [];
        plugins.push(
            new CustomRTEPlugin((content) => { 

            }) 
          );
        return plugins;
      };
    //plugin code end

    const handleDateSelection = (date: any, _item : any) => {
        let newdate = (dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS")).toString();       
          seteditColumnsDefaultValues({
             ...editColumnsDefaultValues,
             "AttributeValue": newdate
         })
       }

    const generateControls = (): any => {
        let tmpRenderObj: any[] = [];
        // eslint-disable-next-line
        for (let editRowColumn of editRowColumns) {
            switch (editRowColumn.type) {
                case 'DropDown':
                        if (editRowColumn.label === 'Action Required') {
                            let options = ActionRequired ?? [];
                            if (editColumnsDefaultValues[editRowColumn.ActionRequiredName] === "Pending") {
                                options = options.filter((option: { text: string; }) => option.text === "Resolved" || option.text === "Pending");
                            }
                            else if (editColumnsDefaultValues[editRowColumn.ActionRequiredName] === undefined || editColumnsDefaultValues[editRowColumn.ActionRequiredName] === null || editColumnsDefaultValues[editRowColumn.ActionRequiredName] === "") {
                                options = ActionRequired.filter((option: { text: string; }) => option.text === "Resolved") ?? [];
                            }
                            tmpRenderObj.push(
                                <Dropdown
                                    label={editRowColumn.label}
                                    options={options}
                                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                    onChange={(ev, selected) => onDropDownChange(ev, selected, editRowColumn)}
                                    placeholder={"Select " + editRowColumn.label}
                                    required={false}
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                />
                            );

                        }
                        else if (editRowColumn.label === 'Attribute Value' &&  !bulkEditButtonClicked) {
                            let disabledState = false;
                            if (editColumnsDefaultValues["IsNoInputRequired"] === true) {
                                disabledState = true;
                            }
                            if (editColumnsDefaultValues["AffectedAttributeInputType"] === "dropdown") {
                                const defaultSelectedKeys = editColumnsDefaultValues[editRowColumn.key]
                                    ? editColumnsDefaultValues[editRowColumn.key].split(',')
                                    : [];
                                const AffectedAttributeBlobData = editColumnsDefaultValues["AttributeName"];
                                const options = AffectedAttributesTypes[AffectedAttributeBlobData]?.map((option: any) => ({ key: option, text: option })) ?? [];
                                tmpRenderObj.push(
                                    <Dropdown
                                        label={editRowColumn.label}
                                        options={options}
                                        multiSelect={true}
                                        defaultSelectedKeys={defaultSelectedKeys}
                                        onChange={handleMultiSelectDropdownChangeFactory(editRowColumn)}
                                        placeholder={"Select " + editRowColumn.label}
                                        required={!disabledState ? true : false}
                                        defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                        disabled={disabledState}
                                        errorMessage={attributeValueErrorMessage}
                                    />
                                );
                            }
                            else if (editColumnsDefaultValues["AffectedAttributeInputType"] === "multiline"  &&  !bulkEditButtonClicked) { 
                                tmpRenderObj.push(
                                    <>
                                        <div style={{ gridColumn: "span 2", height: '400px' }}>
                                            
                                            {!disabledState && <RichTextEditor
                                                characterMax={4000}
                                                label={editRowColumn.label}
                                                onBlur={function noRefCheck() { }}
                                                onChange={handleRichTextChange}
                                                onFocus={function noRefCheck() { }}
                                                onInvalid={function noRefCheck() { }}
                                                onValid={function noRefCheck() { }}
                                                placeholder="Start typing here"
                                                resizable={true}
                                                value={editColumnsDefaultValues[editRowColumn.key]}
                                                plugins={getPlugins()}
                                            />}                                           
                                            {attributeValueErrorMessage ? <span className={ManagedRequirementDefinitionStyles.warningMessageStyle}>{attributeValueErrorMessage}</span> : undefined}
                                            {disabledState && <RichTextEditor
                                                characterMax={4000}
                                                label={editRowColumn.label}
                                                onBlur={function noRefCheck() { }}
                                                onChange={handleRichTextChange}
                                                onFocus={function noRefCheck() { }}
                                                onInvalid={function noRefCheck() { }}
                                                onValid={function noRefCheck() { }}
                                                placeholder="Start typing here"
                                                resizable={true}
                                                value={editColumnsDefaultValues[editRowColumn.key]}
                                                disabled={true}
                                                readOnly={true}
                                            />
                                            }
                                        </div>
                                    </>
                                );
                            }
                            else if (editColumnsDefaultValues["AffectedAttributeInputType"] === "singledropdown"  &&  !bulkEditButtonClicked) {
                                const AffectedAttributeBlobData = editColumnsDefaultValues["AttributeName"];
                                const options = AffectedAttributesTypes[AffectedAttributeBlobData]?.map((option: any) => ({ key: option, text: option })) ?? [];
                                tmpRenderObj.push(
                                    <Dropdown
                                        label={editRowColumn.label}
                                        options={options}
                                        defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                        onChange={(ev, selected) => onDropDownChange(ev, selected, editRowColumn)}
                                        placeholder={"Select " + editRowColumn.label}
                                        required={!disabledState ? true : false}
                                        defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                        disabled={disabledState}
                                        errorMessage={attributeValueErrorMessage}
                                    />
                                );
                            }
                            else if (editColumnsDefaultValues["AffectedAttributeInputType"] === "TEXT"  &&  !bulkEditButtonClicked) {
                                tmpRenderObj.push(
                                    <TextField
                                        label={editRowColumn.label}
                                        defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                        multiline
                                        resizable={false}
                                        onChange={(event) => { onChangeText(event, event.target, editRowColumn); }}
                                        disabled={disabledState}
                                        required={!disabledState ? true : false}
                                        errorMessage={attributeValueErrorMessage}
                                    />
                                );
                            }
                            else if(editColumnsDefaultValues["AffectedAttributeInputType"] === "DATE"  &&  !bulkEditButtonClicked){
                                tmpRenderObj.push(
                                    <DatePicker
                                    label="Attribute Value"
                                    value={isEmpty(editColumnsDefaultValues[editRowColumn.key])? editColumnsDefaultValues[editRowColumn.key] : new Date(editColumnsDefaultValues[editRowColumn.key])}
                                    minDate={new Date()}
                                    placeholder={"Select Date"}
                                    formatDate={onFormatDate}
                                    onSelectDate={newDate => { handleDateSelection(newDate, editRowColumn); }}
                                    textField={{ errorMessage: "" }}
                                    isRequired={!disabledState ? true : false}
                                    
                                    >

                                    </DatePicker>
                                );
                            }
                        }
                        else if (editRowColumn.label === 'Confidence Level') {
                            tmpRenderObj.push(
                                <Dropdown
                                    label={editRowColumn.label}
                                    options={ConfidenceLevel ?? []}
                                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                    onChange={(ev, selected) => onDropDownChange(ev, selected, editRowColumn)}
                                    placeholder={"Select " + editRowColumn.label}
                                    required={false}
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                />
                            );
                        }                    
                    break;
                case 'ChoiceGroup':
                    const options = editRowColumn.choiceGroupOptions ?? [];
                    const selectedKey = editColumnsDefaultValues[editRowColumn.key];
                        tmpRenderObj.push(
                            <ChoiceGroup
                                label={editRowColumn.label}
                                options={options}
                                selectedKey={selectedKey}
                                onChange={(ev, option) => SetObjValues(editRowColumn.key, option?.key)}
                                required={false}
                                styles={{
                                    flexContainer: {
                                        display: "flex",
                                        flexDirection: 'row'
                                    },
                                }}
                            />
                        );
                    
                    break;
                case 'ReadOnly': 
                // Check if the bulk edit button has not been clicked
                if (!bulkEditButtonClicked) {
                    // Destructure the label and key from editRowColumn for easier access
                    const { label, key } = editRowColumn;
                
                    // Retrieve the value associated with the current column key
                    const value = editColumnsDefaultValues[key];
                
                    // Determine if the current field is for 'Comments' and is empty
                    const isCommentsEmpty = label === 'Comments' && key === 'Comments' && isEmpty(value);
                
                    // Set display label to 'Annotations' if the label is 'Comments', otherwise use the column's label
                    const displayLabel = label === 'Comments' ? 'Annotations' : label;
                
                    // Apply a specific height style only when dealing with an empty 'Comments' field
                    const heightStyle = isCommentsEmpty ? { height: '30px' } : undefined;
                
                    // Push the Label component into the tmpRenderObj array
                    tmpRenderObj.push(
                        <Label>
                            {/* Display either 'Annotations' or the original label */}
                            {displayLabel}
                            
                            {/* Render the value within a disabled Label, with optional styling for empty 'Comments' */}
                            <Label className="readonyLabel" style={heightStyle} disabled>
                                {value}
                            </Label>
                        </Label>
                    );
                }
                break;
      
                case 'TextArea':
                        tmpRenderObj.push(
                            <div style={{ gridColumn: "span 2" }}>
                                <TextField
                                    label={editRowColumn.label}
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                    multiline
                                    resizable={false}
                                    onChange={(event) => { onChangeText(event, event.target, editRowColumn); }}
                                /></div>
                        );
                    
                    break;
                default:
            }
        }
        return tmpRenderObj;
    }


    const Validate = () => {

        let affectedAttributeInputType = editColumnsDefaultValues["AffectedAttributeInputType"];
        let inputRequired = editColumnsDefaultValues["IsNoInputRequired"];
        let attributeValue = editColumnsDefaultValues["AttributeValue"];
        if(!bulkEditButtonClicked)
        {    
            if((affectedAttributeInputType !== "multiline" && inputRequired===false && (isEmpty(attributeValue.trim()) || isEmpty(attributeValue.trim()))))  
            {
                setAttributeValueErrorMessage("Attribute Value is mandatory");
                return true;
            }
            else if (affectedAttributeInputType === "multiline" && inputRequired===false && (attributeValue === undefined || (attributeValue !== undefined &&  isEmpty(attributeValue.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim()))))
            {
                setAttributeValueErrorMessage("Attribute Value is mandatory");
                return true;
            }
            else
            {
                setAttributeValueErrorMessage('');           
            }
        }
    }

    const validateAssignedTo=() =>{
        if (!bulkEditButtonClicked && AssignedTo.length === 0){
          setAssignToErr(true);
          return false;
        }
        else{
          setAssignToErr(false);
          return true;
        }
    
      }

    const handleEdit = () => {    
        
            let Status = Validate();
            let assignedtostatus = validateAssignedTo();
  
            if(!Status && !lockedStatus() && isDataChange())
            {
                onEditPanelChange(refColumnsDefaultValues);
            }
            else if(bulkEditButtonClicked){
                onEditPanelChange(refColumnsDefaultValues);
            }
            else if(discussionData!==undefined && !isEmpty(discussionData.Discussion) )
            {    
            handleValueChange(false);
            onSaveDiscussion(true); // API Call for discussions            
            }   
    };

    
    const isDataChange =()=>{
        const item = requirementDefinitionData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        if(item)
        {
        const OriginalData = item; 
        const actionResolved = ActionRequired.filter((option: { text: string; }) => option.text === "Resolved"); //returns {key:'ID', text:'Name'}
        if(!bulkEditButtonClicked  ){
            if(editColumnsDefaultValues['ActionRequired'] !== OriginalData['ActionRequired']
            || editColumnsDefaultValues['IsNoInputRequired'] !== OriginalData['IsNoInputRequired']
            || editColumnsDefaultValues['ConfidenceLevelId'] !== OriginalData['ConfidenceLevelId']
            || editColumnsDefaultValues['Comments'] !== OriginalData['Comments']
            || (editColumnsDefaultValues['ActionRequired'] !== OriginalData['ActionRequired'] && editColumnsDefaultValues['ActionRequired'] === actionResolved[0].key)
            || editColumnsDefaultValues['AttributeValue'] !== OriginalData['AttributeValue']
            )
            {       
                return true;
            }
            else{
                return false;
            }  
        }
    }
    else{
        return false;
    }
    }
    const isBulkSaveButton = ()=>{
        
        if(bulkEditButtonClicked &&  editColumnsDefaultValues['ActionRequired']===null && editColumnsDefaultValues['IsNoInputRequired']===null
             && editColumnsDefaultValues['ConfidenceLevelId']=== null && editColumnsDefaultValues['Comments']=== null 
              && editColumnsDefaultValues.AssignedTo===null && AssignedTo.length===0)
        {   
            return true;
        }
        else{
            return false;
        }
    }
    const handleOnPanelXClose = () => {
        setshowEditGridPanel(false);
        setDiscussionData([]);
    }
    
    const handleOnPanelJSONXClose = () => {
        setmodalOpen(false);
      } 

    
  //AssignedTo - Datamonitoringissues
  const onSelectContact = (contact: any) => {
    if (isEmpty(contact)) {
        setAssignedTo([]);
        setAssignToErr(true);
    }
    else {
        setAssignToErr(false);
        let editedcontact = [{
           text: contact.text,
           objectId: contact.objectId  
         }]
         setAssignedTo(editedcontact)
    }  
  }
 


    //Pagination code start   
    const [requirmentDefinitionPaginatedData, setRequirmentDefinitionPaginatedData] = useState<any>([]);
    const [refreshPagination, setRefreshPagination] = useState<boolean>(false);

    const updatePaginationData = (data: any) => {
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setRequirmentDefinitionPaginatedData(data);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setRequirmentDefinitionPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setRequirmentDefinitionPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key));
        }
    }


    const getDataCountByActionType = (inputType: any) => {
        if (inputType === NotificationPivotOptions.ALLITEMS) {
            return isFilterApplied ? requirementDefinitionFilteredData.length : requirementDefinitionBeforeFilterData.length;
        }
        else if (inputType === NotificationPivotOptions.ACTIONREQUIRED) {
            return (isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key).length
        }
        else if (inputType === NotificationPivotOptions.ACTIONRESOLVED) {
            return (isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key).length
        }
    }
    const resetPagination = () => { 
            setRefreshPagination(refreshPagination ? false : true); 
    }
    //Pagination code end 

    //Pivot related code start
    const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ACTIONREQUIRED);
    const handlePivotChange = (item: any) => { 
        resetSelection();
        if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
            setRequirementDefinitionData(isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData);
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
                sortDefault(isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData)  //sort bydefault     
            else resetPagination();  
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONREQUIRED) {
            setRequirementDefinitionData((isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) 
            sortDefault((isFilterApplied ? requirementDefinitionFilteredData:requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data:any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));      
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONRESOLVED) {
            setRequirementDefinitionData((isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));
            if(sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) 
            sortDefault((isFilterApplied ? requirementDefinitionFilteredData : requirementDefinitionBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));               
            else resetPagination();
        }
        setActiveTab(item.props.itemKey); 
    };
    //Pivot related code end


    //Filter code start  
    const [requirementDefinitionBeforeFilterData, setRequirementDefinitionBeforeFilterData] = useState<any>([]);
    const [requirementDefinitionFilteredData, setRequirementDefinitionFilteredData] = useState<any>([]);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const onApplyFilter = (filteredArray: any) => {
        resetSelection();
        setIsFilterApplied(true);
        setRequirementDefinitionFilteredData(JSON.parse(JSON.stringify(filteredArray)));
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setRequirementDefinitionData(filteredArray);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setRequirementDefinitionData(filteredArray.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setRequirementDefinitionData(filteredArray.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        resetPagination();
    };

    const onClearFilter = () => {
        setIsFilterApplied(false);
        setRequirementDefinitionFilteredData([]);
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setRequirementDefinitionData(requirementDefinitionBeforeFilterData);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setRequirementDefinitionData(requirementDefinitionBeforeFilterData.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setRequirementDefinitionData(requirementDefinitionBeforeFilterData.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        resetPagination();
    }

    const createFilterColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type", "type": "dropdown", "options": [] },
            { "fieldName": "DataValue", "label": "Data Value", "type": "searchDropdown", "options": [] },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario", "type": "searchDropdown", "options": [] },
            { "fieldName": "AttributeName", "label": "Attribute Name", "type": "searchDropdown", "options": [] },
            { "fieldName": "ResolvedBy", "label": "Resolved By", "type": "searchDropdown", "options": [] },
            { "fieldName": "ConfidenceLevelName", "label": "Confidence", "type": "dropdown", "options": [] },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date", "type": "date", "fromTargetCompletionDate": null, "toTargetCompletionDate": null }
        ];

    }
    //Filter code end




    //Sort code start
    const [sortColumnDetails, setSortColumnDetails] = useState<any>();
    const createSortColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type" },
            { "fieldName": "DataValue", "label": "Data Value" },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario" },
            { "fieldName": "AttributeName", "label": "Attribute Name" },
            { "fieldName": "ConfidenceLevelName", "label": "Confidence" }, 
            { "fieldName": "ResolvedBy", "label": "Resolved By" },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date" }
        ]
    };

    const sortDefault = (data: any) => { 
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) { 
            onSorted(sortColumnDetails["columnName"], sortColumnDetails["sortType"], data);     
        } 
       }

    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] === columnName) {
            return sortColumnDetails["sortType"] === "asc" ? "Ascending" : "Descending";
        }
    } 
        
    const onSorted = (columnName: string, sortType: string, requirementDefinition?: any): void => {
        resetSelection(); 
        setSortColumnDetails({ "columnName": columnName, "sortType": sortType });
        let sortedData = (requirementDefinition !== undefined ? requirementDefinition : requirementDefinitionData).sort((a: any, b: any) => {
            if (a[columnName] > b[columnName]) {
                return sortType === 'asc' ? 1 : -1;
            }
            if (a[columnName] < b[columnName]) {
                return sortType === 'asc' ? -1 : 1;
            }
            return 0;
        });

        setRequirementDefinitionData(JSON.parse(JSON.stringify(sortedData)));
        resetPagination();
    }
    
 
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
    };

    const triggerpipeline = (): any => {
        setDisableRunPipelineButton(true); 
        DOMEApiHelper('DataPlatform/CreatePipelineRunAsync?functionalScenarioId='+ PipelineFuncScenario +'&affectedDataTypeId=' + AffectedDataTypeId +'&affectedAttributeId=' + AffectedAttributeId, requestOptions)
       .then(res => {
           setToastType(CoherenceNotificationType.SUCCESS);
           setToastTitle("Pipeline Execution");
           setToastMessage("Pipeline triggered successfully.");
           setTimeout(() => {
            fetchPipelineRunDetails(PipelineFuncScenario, AffectedDataTypeId,AffectedAttributeId);             
            }, 2000);
           setShowToast(true);
           setTimeout(() => {
               setShowToast(false); 
           }, 1500);
       }).catch(error => {
           setToastType(CoherenceNotificationType.ERROR);
           setToastMessage(error.toString());
           setShowToast(true);
           setTimeout(() => {
               setShowToast(false);
               setDisableRunPipelineButton(false);
           }, 1000);
           setIsLoaded(false);
       }
     );
       
    }
    const generatePipelineStatusPanel= (): any => { 
        let tmpRenderObj: any[] = [];   
        tmpRenderObj.push(
        <>
        <div style={{ display: "flex" }} >
                <div style={{ width: "30%", textAlign: "left" }}><Label>Pipeline Name:</Label></div>
                <div style={{width:"70%",padding: "5px"}}><span typeof="persona">{ PipelineStatus.PipelineName}</span>
                    </div>
        </div>

        <div style={{ display: "flex" }} >
            <div style={{ width: "30%", textAlign: "left" }}><Label>Run Pipeline:</Label></div>
                <div style={{width:"70%",padding: "5px"}}>                
                    <TooltipHost content={DisableRunPipelineButton ?"":"Click here to Run pipeline."}>                 
                       {isEmpty(PipelineStatus.PipelineName) ? undefined :
                        <button aria-label="run pipeline" style={{border:"none", background:"white", cursor: DisableRunPipelineButton? "not-allowed": "pointer"}} disabled={DisableRunPipelineButton } onClick={() => triggerpipeline()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048"
                    fill={DisableRunPipelineButton ? "#808080" : "#0078d4"} width={"25px"}>
                    <path d="M1408 1280l640 384-640 384v-768zm128 226v316l263-158-263-158zm-14-307q7-44 10-87t4-88q0-52-6-117t-20-131-39-125-62-101q-12-14-28-26t-37-12q-20 0-38 14t-35 34-28 43-19 37H962q33 94 47 189t15 195q0 100-14 195t-48 189h262q22 50 56 90v153q-41-18-74-47t-57-68H899q-14 25-35 48t-46 41-55 28-59 11H320q-44 0-81-18t-67-49-54-70-42-81-30-84-20-77q-14-64-20-129t-6-132q0-66 6-131t20-130q7-35 19-76t31-84 42-82 54-69 67-49 81-19h384q30 0 59 10t54 29 47 41 35 48h250q13-25 34-48t47-41 55-28 59-11h384q46 0 84 20t70 53 56 77 41 88 30 90 19 81q11 57 15 114t5 117v21q0 109-21 214t-64 205l-110-66q34-86 50-176t17-182v-16q-1-49-4-97t-12-97q-3-18-10-48t-18-66-27-71-33-66-41-48-47-19h-190q63 108 95 228t32 246q0 72-6 142t-20 141l-117-70zM320 1536q19 0 35-11t28-25q37-40 62-100t40-126 21-131 6-119q0-52-6-118t-21-132-40-125-62-101q-12-13-28-24t-35-12q-19 0-35 11t-28 25q-37 41-62 100t-40 126-21 132-6 118q0 53 6 118t21 132 40 126 62 100q12 13 28 24t35 12zm384 0q21 0 39-15t32-30q35-42 58-100t37-123 20-127 6-117q0-53-6-116t-20-128-37-122-58-101q-13-15-31-30t-40-15H515q70 123 97 247t28 265q0 140-27 264t-98 248h189z" />
                    </svg></button>
                    }
                    
                    </TooltipHost>
            </div>
            </div>            

            <div style={{ display: "flex" }} >
                <div style={{ width: "30%", textAlign: "left" }}><Label>Last Run Start:</Label></div>
                <div style={{width:"70%",padding: "5px"}}>
                    {!isEmpty(PipelineStatus.RunStart) ? dayjs(PipelineStatus.RunStart).format('L LTS'): "--"}</div>
            </div>

            <div style={{ display: "flex" }} >
                <div style={{ width: "30%", textAlign: "left" }}><Label>Last Run End:</Label></div>
                <div style={{width:"70%",padding: "5px"}}>
                    { !isEmpty(PipelineStatus.RunEnd) ? dayjs(PipelineStatus.RunEnd).format('L LTS'): "--"}</div>
            </div>

            <div style={{ display: "flex" }} >
                <div style={{ width: "30%", textAlign: "left" }}><Label>Duration:</Label></div>
                <div style={{width:"70%",padding: "5px"}}>
                    {PipelineStatus.DurationInMs}</div>
            </div>

            <div style={{ display: "flex" }} >
                <div style={{ width: "30%", textAlign: "left" }}><Label>Last Run Status:</Label></div>
                <div style={{width:"70%",padding: "5px"}}>{PipelineStatus.Status}</div>
            </div> 
         </>
        );                
    return tmpRenderObj;
    }

    //Sort code end

    //  bulk edit code start 
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = useState<boolean>(false); 
    const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]); 
    const [AssignedToEditButtonClicked, setAssignedToEditButtonClicked] = useState<boolean>(false);

    // onChange function to update isSelect state
    const handleSelectionCheckbox = (event:any,item:any) => {
        if (event.target.checked) {
        item.isSelected=true;
        setBulkEditSelectedData([...bulkEditSelectedData, item]);
        } else {
            
            if(isSelectedAll)
            setIsSelectedAll(false);
            
            item.isSelected=false;
            setBulkEditSelectedData(
                bulkEditSelectedData.filter((selectedItem:any) => selectedItem.Id !== item.Id)
            );
        }  

        const updatedSelectedData = requirmentDefinitionPaginatedData.map((item:any) => ({
            ...item,
            isSelected:item.isSelected
        }));

        setRequirmentDefinitionPaginatedData([...updatedSelectedData]);   

        requirementDefinitionData.forEach((element:any) => {
            if(element.Id===item.Id){
                element.isSelected=item.isSelected
            }
        });  
        setRequirementDefinitionData([...requirementDefinitionData]);    
    };

    // onChange function to update select all checkbox
    const handleSelectAllCheckbox= (event:any) => { 
        setIsSelectedAll(event.target.checked); 
        //update paginated records
        const updatedSelectedData = requirmentDefinitionPaginatedData.map((item:any) => ({
            ...item,
            isSelected:(item.IsLocked===true && item.ActionRequiredName!==UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key)?false:event.target.checked,
        }));

        setRequirmentDefinitionPaginatedData([...updatedSelectedData]);  

        //update all recrods
        const updatedSelectedData1 = requirementDefinitionData.map((item:any) => ({
            ...item,
            isSelected:(item.IsLocked===true && item.ActionRequiredName!==UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key)?false:event.target.checked,
        }));

        setRequirementDefinitionData([...updatedSelectedData1]);

        const updateBulkUpdateData=event.target.checked? updatedSelectedData1.filter((x:any)=>x.IsLocked!==true || (x.IsLocked===true && x.ActionRequiredName===UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key )):[]; 
        setBulkEditSelectedData([...updateBulkUpdateData]) 
    };
 
    const resetSelection = (): any => {
        setIsSelectedAll(false);
        setBulkEditSelectedData([]);  

        requirementDefinitionData.forEach((element:any) => {
          element.isSelected=false;
        });
        setRequirementDefinitionData([...requirementDefinitionData]); 
    } 

    const onClickCommandBarsButtonsEvents = (source: any) => {
        /**
         * Sets the state variables for bulk edit mode and resets some of the state variables.
         * If there is data selected for bulk edit, it sets the default values for the edit columns to null.
         * @param {string} source - The source of the event that triggered the function.
         */
        if(source=='Bulk Edit'){
            setAssignedTo([]);
            setAssignedToEditButtonClicked(false);
            setBulkEditButtonClicked(true);
            setAssignToErr(false);
            setAssignedToRequired(false);
            if(bulkEditSelectedData.length>0)
            {
                let obj=JSON.parse(JSON.stringify(bulkEditSelectedData[0]));
                Object.keys(obj).forEach(key => {                 
                        obj[key] = null; 
                  });     
                  seteditColumnsDefaultValues(obj);
                 
            }
         
            setshowEditGridPanel(true);
        }
        else if(source==='Import'){
            // File input click logic
            document.getElementById('managedRDFileInput')?.click();
         }

         else if (source === 'Selected Item(s)') { 
            exportToCSV(bulkEditSelectedData);
             
          } else if (source === 'All Items') {
            exportToCSV(requirementDefinitionBeforeFilterData);        
          }  
          
          else if(source==='Lock'){
            setToastTitle("Requirement Definition");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true); 
            setTimeout(() => {
                setShowToast(false);
            }, 8000);
            
            setLockButtonRequirementDefinition(false);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bulkEditSelectedData),
                mode: 'cors'
            };
            DOMEApiHelper('RequirementDefinition/LockRequirementDefinitionDataUI', requestOptions)
                .then(res => {
                    if(res.length != undefined && res.length>0){
                        let error= "Action Required is pending or Confidence Level is low for following associated Catalog Alignment Data Value:  " + res.map((x:any)=>x).join(",");                
                        setToastType(CoherenceNotificationType.ERROR);
                        setToastMessage(error.toString());
                        setShowToast(true);
                        setTimeout(() => {
                            setShowToast(false);
                        }, 5000);
                    }
                    else if (res.Message === "Success") {      
                        LoadRequirementDefinitionData("Loading data",PORId);           
                        setToastType(CoherenceNotificationType.SUCCESS);
                        setToastMessage("Record(s) are locked successfully.");
                        setShowToast(true);
                        setTimeout(() => {
                            setShowToast(false);
                            
                        }, 4000);
                        
                    }
                    setLockButtonRequirementDefinition(true);
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 1000);
                    setIsLoaded(false);
                    setLockButtonRequirementDefinition(true);
                }
            );
            
        }    
        }

        const exportToCSV=(data:any):any =>{
            let exportData:any=[];
            data.forEach((obj:any) => {  
               
                let attributeValue = obj.AttributeValue;
                if (obj.AffectedAttributeInputType.toUpperCase() === 'DATE'  ) {
                        if(!isEmpty(attributeValue)){
                            attributeValue = dayjs(obj.AttributeValue).format('MM/DD/YYYY');
                        }
                }    
                exportData.push({ Id: obj.Id,DataType:obj.DataType,DataValue:obj.DataValue,FunctionalScenario:obj.FunctionalScenario,ActionRequiredName: obj.ActionRequiredName,
                  AttributeName:obj.AttributeName ,AttributeType:obj.AffectedAttributeInputType,AttributeValue:attributeValue, ConfidenceLevelName:obj.ConfidenceLevelName,Comments:obj.Comments, 
                  ResolvedBy:obj.ResolvedBy,TargetCompletionDate:dayjs(obj.TargetCompletionDate).format('MM/DD/YYYY')}); 
              });   
              const currentDate = new Date();
              const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth()+1}-${currentDate.getFullYear()}_${currentDate.getHours()}_${currentDate.getMinutes()}_${currentDate.getSeconds()}`;  
              convertToCsv(exportData, `RD_Managed_${formattedDate}`);       

          }

        const generateButtons = ():any => { 
            const _items: ICommandBarItemProps[] = [{
                key: 'Bulk Edit',
                text: 'Bulk Edit',
                ariaLabel:"Bulk Edit Button",
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                   
                },
                disabled: bulkEditSelectedData.length>1 ? false : true,
              }, 
              { 
                key: 'Export',
                text: 'Export',
                ariaLabel: 'Export',
                disabled: false,
                iconProps: { iconName: 'Download' },
                onClick: () => {
        
                }, subMenuProps: {
                    items: [
                        {
                            key: 'Selected Item(s)',
                            text: 'Selected Item(s)',
                            disabled: bulkEditSelectedData.length>0 ? false : true,
                            onClick: () => {}
                        },
                        {
                            key: 'AllItems',
                            text: 'All Items',
                            disabled: false,
                            onClick: () => {}
                        }
                    ],
                  }
            },
            { 
                key: 'Import',
                text: 'Import',
                ariaLabel: 'Import',
                disabled: false,
                iconProps: { iconName: 'Import' },
                onClick: () => {
                
                } 
            }
        
            ];

            if (userRole.includes("Admin")) {
                _items.push({
                    key: 'Lock',
                    text: 'Lock',
                    ariaLabel: "Lock Button",
                    iconProps: { iconName: 'LockSolid' },
                    onClick: () => {

                    }, 
                    disabled: (LockButtonRequirementDefinition &&  bulkEditSelectedData.length>0 ) ? ( bulkEditSelectedData.every((item: any) => (!isEmpty(item.AttributeValue) || item.IsNoInputRequired==true) && item.ConfidenceLevelName === 'High' && item.ActionRequiredName !='Pending') ? false : true) : true  ,    
               });
            }
            return _items;
        }   
          //  bulk edit code end

          //discussion code start 
    const [discussionData, setDiscussionData] = useState<any>();  
    const isDisabledDiscussion=() =>{
      return (isEmpty(discussionData) || isEmpty(discussionData.Discussion)) ? true : false;
    }
  

    const onValidDiscussionData = (discussionData: any, discussion: any) => {
        setDiscussionData(discussionData);
    }

  

    const onSaveDiscussion = (showToast:any) => {  
        let discussionDataList : any =[];
        discussionDataList.push(discussionData);       
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(discussionDataList),
          mode: "cors",
        };
         if(showToast){
            setIsLoaded(true);
            setToastTitle("Requirement Definition");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true); 
        } 
        DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
          .then(res => {
            if(showToast)  {
                setIsFilterApplied(false);//reset filter
                setToastType(CoherenceNotificationType.SUCCESS);
                setToastMessage("Record is updated successfully.");
                setTimeout(() => {
                    setShowToast(false);
                }, 1000);
                LoadRequirementDefinitionData("Loading data",PORId);
            }  
            if (res.Message === "Success") { 
              setDiscussionData([]); 
            }
          }).catch(res => {
            setToastMessage(res.toString());
            setToastType(CoherenceNotificationType.ERROR);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });
   } 
    //discussion code end

   // Import Excel functionality  Code Start

    //reset file selection 											
    const resetFileSelection = () => {
        const fileInput = document.getElementById('managedRDFileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    } 
    const validateFileDate=(object:any)=>{
         // Retrieve the keys of the first element (assuming all elements have the same keys)
         const keys = Object.keys(object[0]);
         // Define the required keys
        const requiredKeys = ['Id', 'AttributeName', 'AttributeType', 'AttributeValue'];

        // Check if all required keys exist
        const missingKeys = requiredKeys.filter(key => !keys.includes(key));

        // If any required keys are missing, show console error
        if (missingKeys.length > 0) {
        setShowToast(true);
        setToastType(CoherenceNotificationType.ERROR);
        setToastTitle("Requirement Definition");
        setToastMessage(`Please select a valid file. The file should contain the following column(s): ${missingKeys.join(', ')}.`);
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
        } 
        // Now 'keys' will contain the column key names of the first row
        return missingKeys.length === 0?true:false;
    }
    
    const formattedDate = (date: any) => {
        if (date) {
            const formattedDate = dayjs(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS");
            return formattedDate;
        }
    }
    // Function to handle file selection
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      setImportErrors([]);
       
        const files = event.target.files;
      
        let userId=sessionStorage.getItem("localAccountId")
        if (files && files.length > 0) {
            const file = files[0];
            const fileExtension = file.name.split('.').pop(); // Get the last part after splitting by '.'
            if(fileExtension?.toLowerCase() != 'csv'){
                setShowToast(true);
                setToastType(CoherenceNotificationType.ERROR);
                setToastTitle("Requirement Definition");
                setToastMessage("Please select a valid file format. Only file with the extensions .csv is allowed.");
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
                resetFileSelection();
             return; 
            }
            const reader = new FileReader();
    
            reader.onload = (e) => {
                try {
                    let importData:any=[];
                    const csvData = e.target?.result as string;
                    const inputjsonData = parseCSV(csvData);
                    const jsonData = inputjsonData.filter((item: any) => isEmpty(item.Id) ===false);
                
                    if(!isEmpty(jsonData) && jsonData.length>0){
                     if(!validateFileDate(jsonData)){
                        resetFileSelection();
                        return;
                     }
                    
                    resetFileSelection();
                    let InValidInputValues:any=[];
                    let validInputValues:any=[];
                    let dataNotAvailableInRDGrid:any=[];
                    let isLockedInputValues:any =[];
                    let unTouchedValidInputValues:any=[];
                    jsonData.forEach((obj:any) => { 
                    let findData=requirementDefinitionBeforeFilterData.filter((dataItem: any) => dataItem.Id.toString() === obj.Id)
                            if(findData.length>0 ){
                                if(findData[0].IsLocked===true){
                                    obj.IsLocked=true;
                                    obj.ExistingAttributeValue=findData[0].AttributeValue; //storing existing value to display in error message
                                    isLockedInputValues.push(obj);                               
                                }
                                else{
                                    // if there is change in AttributeValue then only update and call API
                                    let checkDataValue = obj.AttributeValue;

                                    if(findData[0].AffectedAttributeInputType.toUpperCase()==='DATE'){
                                        if(isEmpty(obj.AttributeValue) && isEmpty(findData[0].AttributeValue)){
                                            checkDataValue = obj.AttributeValue;
                                        }
                                        else{
                                        checkDataValue = formattedDate(obj.AttributeValue);
                                        //to check DATE format type values
                                        }
                                    }
                                    

                                    if(checkDataValue!==findData[0].AttributeValue){
                                    //switch case for different types of inputs 
                                        switch (findData[0].AffectedAttributeInputType?.toUpperCase()) {
                                            case 'TEXT': importData.push({ Id: obj.Id, AttributeValue: obj.AttributeValue, ModifiedBy: userId });
                                                    break;
                                             case 'DATE':  
                                                    let date = new Date(obj.AttributeValue);
                                                    if(isEmpty(obj.AttributeValue)){
                                                        importData.push({Id:obj.Id,AttributeValue:obj.AttributeValue,ModifiedBy:userId});
                                                    }
                                                    else{
                                                            if (date.toString() === 'Invalid Date') {
                                                                obj.AttributeType = findData[0].AffectedAttributeInputType ;
                                                                InValidInputValues.push(obj);
                                                            
                                                            } 
                                                            else {
                                                                importData.push({ Id: obj.Id, AttributeValue: formattedDate(obj.AttributeValue), ModifiedBy: userId });
                                                            }
                                                    }   
                                             break; 
                                            case 'MULTILINE': 
                                                    importData.push({ Id: obj.Id, AttributeValue: obj.AttributeValue, ModifiedBy: userId });                                                 
                                                break;
                                            case 'SINGLEDROPDOWN': 
                                            if(isEmpty(obj.AttributeValue))
                                            {
                                                importData.push({Id:obj.Id,AttributeValue:obj.AttributeValue,ModifiedBy:userId});
                                            }else{
                                                      const singleDropDownoptions = AffectedAttributesTypes[findData[0].AttributeName]?.map((option: any) => ({ key: option, text: option })) ?? [];
                                                     if(singleDropDownoptions.filter((option: any) => option.text === obj.AttributeValue.trim()).length > 0){
                                                        importData.push({ Id: obj.Id, AttributeValue:  obj.AttributeValue.trim(), ModifiedBy: userId }); 
                                                      }
                                                      else{
                                                            //get the commaseparated values that is not present in dropdownOptions
                                                            obj.AttributeType = findData[0].AffectedAttributeInputType ;
                                                            InValidInputValues.push(obj);     
                                                       
                                                      }
                                                    }
                                                 break;    
                                            case 'DROPDOWN': 
                                                    if(isEmpty(obj.AttributeValue))
                                                    {
                                                        importData.push({Id:obj.Id,AttributeValue:obj.AttributeValue,ModifiedBy:userId});
                                                    }
                                              
                                                    else{
                                                        const dropdownOptions = AffectedAttributesTypes[findData[0].AttributeName]?.map((option: any) => ({ key: option, text: option })) ?? [];
                                                           
                                                             
                                                        const commaseparated = obj.AttributeValue.split(',');     
                                                       
                                                        if(commaseparated.every((value: any) => dropdownOptions.some((option: any) => option.text === value.trim()))){
                                                           
                                                            importData.push({ Id: obj.Id, AttributeValue: obj.AttributeValue, ModifiedBy: userId });
                                                        }
                                                        else{
                                                           
                                                            //get the commaseparated values that is not present in dropdownOptions
                                                            const invalidValues = commaseparated.filter((value: any) => !dropdownOptions.some((option: any) => option.text === value.trim()));
                                                          
                                                            let invalidItem: any = {};
                                                            invalidItem.Id = obj.Id;
                                                            invalidItem.AttributeName = findData[0].AttributeName;
                                                            invalidItem.AttributeValue = invalidValues.join(',');
                                                            invalidItem.AttributeType = findData[0].AffectedAttributeInputType;

                                                           
                                                            InValidInputValues.push(invalidItem);
                                                        }
                                                       
                                                    }

                                                 break;
                                            // Add more cases for other input types if needed
                                            default:
                                                break;
                                        }  
                                    }
                                    else{
                                        // Valid Records , but no changes in Excel import data
                                        unTouchedValidInputValues.push(obj);
                                    }
                                }
                            }
                            else{
                                dataNotAvailableInRDGrid.push(obj.Id);
                            }
                    }); 
                   
                    let importErrors:any=[];

                    if (dataNotAvailableInRDGrid.length > 0) {
                        dataNotAvailableInRDGrid.forEach((item: any,index:any) => {
                        // show invalid row id's					
                        importErrors.push(`${importErrors.length + 1}) Row Id: ${item} is not available. Please check the Id.`); 
                        })
						
                     }
         
                     if(isLockedInputValues.length>0){
                        isLockedInputValues.forEach((item: any,index:any) => {
                        if(item.IsLocked=== true &&  item.ExistingAttributeValue!==item.AttributeValue){
                            importErrors.push(`${importErrors.length + 1}) Row Id: ${item.Id} Given attribute value: "${item.AttributeValue}" for Attribute: ${item.AttributeName} is Locked.You can not update locked record.`);                                
                        }
                         });
                     }
                    if (InValidInputValues.length > 0) {
                        // check for InValid AttributeValues and give break next line after each error
                        InValidInputValues.forEach((item: any,index:any) => {    
                                if(item.AttributeType.toUpperCase() === 'DATE'){
                                    importErrors.push(`${importErrors.length + 1}) Row Id: ${item.Id} Given attribute value: "${item.AttributeValue}" for Attribute: ${item.AttributeName} is not valid. Acceptable Value: Input Date must be in [MM/DD/YYYY] format .`);
                                
                                }
                                else{
                                importErrors.push(`${importErrors.length + 1}) Row Id: ${item.Id} Given attribute value: "${item.AttributeValue}" for Attribute: ${item.AttributeName} is not valid. Acceptable Values: [${AffectedAttributesTypes[item.AttributeName as keyof typeof AffectedAttributesTypes]}].`);
                                }                           
                        }) 
                     }
                    setImportErrors(importErrors);
               
                    // if input Excel and Validated Excel are same , trigger API

                    //jsonData has input from Excel and importData has valid data to be imported
                    if(jsonData.length ===importData.length + isLockedInputValues.length +unTouchedValidInputValues.length && importErrors.length === 0){
                            setIsLoaded(true);
                            setToastTitle("Requirement Definition");
                            setToastMessage("Record is being imported.");
                            setToastType(CoherenceNotificationType.PENDING);
                            setShowToast(true); 
                            const requestOptions = {
                                method: 'PUT',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(importData),
                                mode: 'cors'
                            };
                            DOMEApiHelper('RequirementDefinition', requestOptions)
                                .then(res => {  
                                    setIsFilterApplied(false);//reset filter
                                    setToastType(CoherenceNotificationType.SUCCESS);
                                    setToastMessage("Record is imported successfully.");
                                    setTimeout(() => {
                                        setShowToast(false);
                                    }, 1000);
                                    LoadRequirementDefinitionData("Loading data",PORId);
                                }).catch(error => {
                                    setToastType(CoherenceNotificationType.ERROR);
                                    setToastMessage(error.toString());
                                    setTimeout(() => {
                                        setShowToast(false);
                                    }, 1000);
                                    setIsLoaded(false);
                                }
                            );     
                    }
                }
                } catch (error) {
                   // console.error('Error parsing CSV:', error);
                }
            };
    
            reader.readAsText(file);
        }
    };

    const parseCSV = (csvData: string): any[] => {
        const lines = csvData.split(/\r\n|\n/);
        const headers = lines[0].split(',');
    
        const jsonData: any[] = [];
    
        for (let i = 1; i < lines.length; i++) {
            const fields = parseCSVLine(lines[i]);
            const obj: any = {};
    
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = fields[j];
            }
    
            jsonData.push(obj);
        }
    
        return jsonData;
    };
    
    const parseCSVLine = (line: string): string[] => {
        const fields: string[] = [];
        let currentField = '';
        let withinQuotes = false;
    
        for (let i = 0; i < line.length; i++) {
            const char = line[i];
    
            if (char === ',' && !withinQuotes) {
                fields.push(currentField.trim());
                currentField = '';
            } else if (char === '"') {
                withinQuotes = !withinQuotes;
            } else {
                currentField += char;
            }
        }
    
        fields.push(currentField.trim());
    
        return fields;
    };  
    //Import Excel functionality code end


    return (
        <> 

        {importErrors.length>0 &&   <MessageBar messageBarType={MessageBarType.error}>
                    <div>Invalid Records:</div>
                    {importErrors.map((item:any, index:any) => (
                        <div key={index}>
                        {item}
                        </div>
                    ))}
                </MessageBar> }
            
            <input type="file" id="managedRDFileInput" onChange={handleFileSelect} accept=".csv"  style={{ display: 'none' }} />


           <CustomGridControls  defaultFilterSessionKey="ManagedRDDefaultFilter"  onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()} filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={requirementDefinitionBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
       
            {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
            <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
                <PivotItem
                    headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                    itemKey={NotificationPivotOptions.ALLITEMS}
                    ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid
                            listConfig={getColumns()}
                            data={requirmentDefinitionPaginatedData}
                            isScrollable={true}
                            isSortable={false}
                            sortByDefault={false} 
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Requirement Definition All Items List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}

                    </div>
                </PivotItem>
                {/* Action Required Tab Grid Code Start */}
                <PivotItem
                    headerText={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"
                    }
                    itemKey={NotificationPivotOptions.ACTIONREQUIRED}
                    ariaLabel={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()}
                            data={requirmentDefinitionPaginatedData}
                            isScrollable={true}
                            ariaLabelForSelectAllCheckbox={'select all checkbox'}
                            isSortable={false}
                            sortByDefault={false} 
                            selectionPreservedOnEmptyClick={true} 
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabelForGrid={"Requirement Definition Action Required List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                   {/* Action Required Tab Grid Code End */}
                <PivotItem
                    headerText={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                    itemKey={NotificationPivotOptions.ACTIONRESOLVED}
                    ariaLabel={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ?
                            <CoherenceDataGrid
                                listConfig={getColumns()}
                                data={requirmentDefinitionPaginatedData}
                                isScrollable={true}
                                ariaLabelForSelectAllCheckbox={'select all checkbox'}
                                isSortable={false}
                                sortByDefault={false} 
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                ariaLabel={"Requirement Definition Action Resolved List"}
                            ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
            </Pivot>
            <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={requirementDefinitionData} pageSize={8}></CustomPagination>

            <CoherencePanel
                title={bulkEditButtonClicked?"Bulk Edit for " + bulkEditSelectedData.length +" Selected Rows"  : "Edit Row Details"}
                titleText={ bulkEditButtonClicked?"Bulk Edit for " + bulkEditSelectedData.length +" Selected Rows"  : "Edit Row Details"}
                isOpen={showEditGridPanel}
                onDismiss={handleOnPanelXClose}
                panelSize={bulkEditButtonClicked || AssignedToEditButtonClicked ? CoherencePanelSize.small : CoherencePanelSize.medium}
                closeButtonAriaLabel="Close"
                onRenderFooter={() => (
                    <Stack horizontal >
                        <PrimaryButton  disabled={(lockedStatus() )   || isBulkSaveButton() || Validate()  ||  (!bulkEditButtonClicked && !isDataChange() && isDisabledDiscussion())} 
                         text="Save" onClick={handleEdit} />
                        <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
                    </Stack>
                )}>
                <div style={bulkEditButtonClicked || AssignedToEditButtonClicked ? {} : { display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem" }}>
                    {showEditGridPanel && generateControls()}
                </div>
                {bulkEditButtonClicked ?  <span></span>:
<NonManagedDiscussions discussionId={editColumnsDefaultValues!=undefined? editColumnsDefaultValues.Id:0} discussionType="RequirementDefinitions" onValidDiscussionData={onValidDiscussionData} isExpand={false}  ></NonManagedDiscussions>
 }
            </CoherencePanel>
            <Dialog
                hidden={!showComments}
                onDismiss={handleHideComment}
                dialogContentProps={{
                    type: DialogType.close,
                    title: 'Annotations',
                    closeButtonAriaLabel: 'Close',
                }}>
                <div>{selectedComment}</div>
            </Dialog>
            <Dialog
                hidden={!showRichText}
                onDismiss={handleHideInfo}
                dialogContentProps={{
                    type: DialogType.close,
                    title: 'Attribute Value',
                    closeButtonAriaLabel: 'Close',
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: selectedRichText }}></div>
            </Dialog>
            <CoherencePanel 
            titleText={"Pipeline Status"}
            isOpen={modalOpen}
            onDismiss={handleOnPanelJSONXClose}
            panelSize={CoherencePanelSize.medium}
            >
            
            {modalOpen && generatePipelineStatusPanel()}
        </CoherencePanel>
        </>
    );
};

export default ManagedRequirementDefinition;
